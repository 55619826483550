import config from '@/config';
import { OrderStatus, Payment } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { Card, Icon, IconSource, InlineGrid, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import { CreditCardMajor, DiamondAlertMinor, FraudProtectMajor } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface DataProps {
  value: number;
  payment: Payment;
  status: OrderStatus;
  title?: string;
  icon: IconSource;
}

interface Props {
  fromDate: Date;
  toDate: Date;
}

const Overview = ({ ...props }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderTable = useSelector(orderTableOrderPageSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const { data, isFetching } = apiCaller.useFetchDataProcessedQuery(
    {
      shop: config.shop,
      fromDate: dateToTimeStamp(props.fromDate),
      toDate: dateToTimeStamp(props.toDate),
    },
    { skip: isSkip },
  );
  const dataSettings = useSelector(dataSettingsSelector);
  const overviewConfig = useMemo(() => {
    const isStripe = dataSettings?.syncStripe === 1;
    if (data) {
      const result: DataProps[] = [
        {
          title: isStripe ? 'PayPal & Stripe orders' : 'PayPal orders',
          icon: CreditCardMajor,
          value: isStripe ? data.orderPaypalStripe : data.orderPaidPaypal,
          payment: isStripe ? Payment.PaypalAndStripe : Payment.Paypal,
          status: OrderStatus.All,
        },
        {
          title: 'Total synced',
          icon: FraudProtectMajor,
          value: isStripe ? data?.orderPaypalSynced + data.orderStripeSynced : data?.orderPaypalSynced,
          payment: isStripe ? Payment.PaypalAndStripe : Payment.Paypal,
          status: OrderStatus.Synced,
        },
        {
          title: 'Total sync errors',
          icon: DiamondAlertMinor,
          value: isStripe ? data.totalErr : data.orderPaypalErr,
          payment: isStripe ? Payment.PaypalAndStripe : Payment.Paypal,
          status: OrderStatus.AllError,
        },
      ];
      return result;
    }
    return [];
  }, [dataSettings?.syncStripe, data]);

  const handleClick = (data: DataProps) => () => {
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...orderTable,
        paymentSelected: [data.payment],
        statusSelected: [data.status],
      }),
    );
    navigate(PATH.ORDERS);
  };
  return (
    <div className="overview-container">
      {isFetching ? (
        <SkeletonBodyText lines={3} />
      ) : (
        <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
          {overviewConfig.map((item, index) => {
            return (
              <Card key={index}>
                <div className="analytics-items-title" onClick={handleClick(item)}>
                  <Icon source={item.icon} tone="base" />
                  <Tooltip content="View list detail">
                    <Text fontWeight="medium" as="h5" variant="headingSm">
                      {item.title}
                    </Text>
                  </Tooltip>
                </div>
                <div className="mt-8 ml-4">
                  <Text as="h5" variant="heading2xl" fontWeight="regular">
                    {item.value}
                  </Text>
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      )}
    </div>
  );
};

export default memo(Overview);
