import { Card, Button, ButtonProps, Text } from '@shopify/polaris';
import { memo } from 'react';
import { CardStyled } from './styled';

interface Props {
  title: string | React.ReactElement;
  actions?: {
    content: string;
    action: any;
    buttonProps: ButtonProps;
  };
  children: any;
  display?: string;
  status?: string;
}

const CustomCard = ({ ...props }: Props) => {
  return (
    <CardStyled status={props.status} style={{ display: props.display || 'block' }}>
      <Card padding={{ xl: '0' }}>
        <div className="custom-card-header">
          <div className="custom-card-title">
            <Text as="h4" variant="headingMd">
              {props.title}
            </Text>
          </div>
          {props.actions && (
            <Button {...props.actions.buttonProps} onClick={props.actions.action}>
              {' '}
              {props.actions?.content}{' '}
            </Button>
          )}
        </div>
        <div className="custom-card-content">{props.children}</div>
      </Card>
    </CardStyled>
  );
};

export default memo(CustomCard);
