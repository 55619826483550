import BoldText from '@/components/BoldText';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { disputeTableSelector } from '@/redux/slice/dispute.slice';
import { Card, InlineGrid, SkeletonBodyText } from '@shopify/polaris';
import { DonutChart, PolarisVizProvider, SimpleBarChart } from '@shopify/polaris-viz';
import { useSelector } from 'react-redux';
import { optionsReason } from '../options';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const DisputeReportChart = () => {
  const disputeTable = useSelector(disputeTableSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const { data, isFetching } = apiCaller.useDisputeChartQuery(
    {
      fromDate: dateToTimeStamp(disputeTable.fromDate),
      toDate: dateToTimeStamp(disputeTable.toDate),
    },
    { skip: isSkip },
  );

  const dataChart = data?.data.topReasons.map((item) => {
    return {
      key: optionsReason.find((reason) => reason.value === item.reason)?.label || 'Other',
      value: item.count,
    };
  });

  const dataDonut = data?.data.topReasons.map((item) => {
    const label = optionsReason.find((reason) => reason.value === item.reason)?.label || 'Other';
    return {
      data: [
        {
          key: label,
          value: item.count,
        },
      ],
      name: label,
    };
  });

  return (
    <div className="mt-16">
      <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={'400'}>
        <Card>
          <div className="mb-8">
            <BoldText>Top 3 dispute reasons</BoldText>
          </div>
          {isFetching ? (
            <div className="mt-16">
              <SkeletonBodyText lines={16} />
            </div>
          ) : (
            <PolarisVizProvider>
              <SimpleBarChart theme="Light" type="default" showLegend={false} data={[{ data: dataChart || [] }]} />
            </PolarisVizProvider>
          )}
        </Card>

        <Card>
          {isFetching ? (
            <div className="mt-16">
              <SkeletonBodyText lines={16} />
            </div>
          ) : (
            <PolarisVizProvider
              themes={{
                Light: {
                  arc: {
                    cornerRadius: 5,
                    thickness: 12,
                  },
                },
              }}
            >
              <div style={{ height: '240px' }}>
                <DonutChart data={dataDonut || []} legendPosition="bottom" theme="Light" />
              </div>
            </PolarisVizProvider>
          )}
        </Card>
      </InlineGrid>
    </div>
  );
};

export default DisputeReportChart;
