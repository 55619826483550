import styled from 'styled-components';

export const DigitalProductStyled = styled.div`
  .ml-10 {
    margin-left: 10px;
  }
  p {
    margin-bottom: 0px;
  }
`;
