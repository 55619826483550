import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';
export const MenuStyled = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 68px;
  .Polaris-Button__Content {
    .Polaris-Button__Text {
      height: 20px;
      line-height: 20px;
    }
  }
  .mobile-nav {
    display: none;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 10px;
    top: 10px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #333333;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #3f4eae;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0 !important;
    top: 0;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  }

  /* General sidebar styles */
  .bm-menu {
    background: #fff;
    padding: 20px 10px;
    font-size: 1.15em;
    line-height: 38px;
  }

  .bm-menu a {
    color: #333;
    display: block;
    line-height: 38px;
  }

  .bm-menu a:hover {
    color: #3f4eae;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 40px;
  }
  .Polaris-Badge {
    padding: 5px 10px !important;
    height: 26px;
    margin-left: 10px;
    .Polaris-Text--root:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MD}px) {
    height: 40px;
    padding: 0;
    .desktop-nav,
    .active-plan-status {
      display: none !important;
    }
    .mobile-nav {
      display: block;
    }
  }
`;
