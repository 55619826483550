import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import config from '@/config';

interface IState {
  token: string | null;
  expiredTime: number;
  isLoading: boolean;
}

// Define the initial state using that type
const initialState: IState = {
  token: null,
  expiredTime: Date.now() - 1000,
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleToken: (state, action: PayloadAction<IState>) => {
      state.expiredTime = action.payload.expiredTime;
      state.token = action.payload.token;
      state.isLoading = action.payload.isLoading;
    },
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});
export const tokenSelector = createSelector(
  (state: RootState) => state.auth,
  (state) => state,
);

export const isSkipApiSelector = createSelector(
  (state: RootState) => state.auth,
  (state) => (config.embedded === '1' ? !state.token : false),
);

export default authSlice;
