import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import config from '@/config';
import store from '../store';
import authSlice from '../slice/auth.slice';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { createApp } from '@shopify/app-bridge';
const endPoint = process.env.REACT_APP_API_END_POINT;
const params = {
  shop: config.shop,
  urlParams: config.urlParams,
};

const convertParams = (input: any) => {
  const newParams = {
    ...input,
    ...params,
  };
  return newParams;
};

const configApp = {
  apiKey: process.env.REACT_APP_API_KEY || '',
  host: new URLSearchParams(window.location.search).get('host') || '',
  forceRedirect: process.env.REACT_APP_MODE === 'live',
};

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: endPoint,
    prepareHeaders: async (headers) => {
      if (
        ((!store.getState().auth.token && config.embedded === '1') || Date.now() > store.getState().auth.expiredTime) &&
        !store.getState().auth.isLoading
      ) {
        try {
          store.dispatch(authSlice.actions.handleLoading(true));
          const app = createApp(configApp);
          const sessionToken = await getSessionToken(app);
          store.dispatch(
            authSlice.actions.handleToken({
              token: sessionToken,
              expiredTime: Date.now() + 60 * 1000,
              isLoading: false,
            }),
          );
          headers.set('authorization', `Bearer ${sessionToken}`);
        } catch (err) {
          store.dispatch(authSlice.actions.handleLoading(false));
        }
      }
      if (config.embedded === '1' && store.getState().auth.token) {
        headers.set('authorization', `Bearer ${store.getState().auth.token}`);
      }
      if (process.env.REACT_APP_ENV === 'production') {
        headers.set('Content-Security-Policy', `frame-ancestors https://${params.shop} https://admin.shopify.com`);
      }
      headers.set('authorization-url-params', config.urlParams);
      return headers;
    },
  }),
  tagTypes: [
    'courierMapping',
    'stripe',
    'dataSettings',
    'store',
    'paypal',
    'order',
    'dispute',
    'accountDispute',
    'listAccountDispute',
    'disputeDetail',
    'courierIgnore',
    'notification',
    'facebookAndInstagram',
  ],
  // get data settings
  endpoints: (builder) => ({
    fetchDataSetting: builder.query<IResponseApi.IGetDataSettingsResponse, string>({
      query: (shop) => {
        return {
          url: 'shop/general-detail',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'dataSettings' }],
    }),

    fetchDataProcessed: builder.query<IResponseApi.IGetDataProcessedResponse, IParamsApi.IGetDataProcessedParams>({
      query: (input) => {
        return {
          url: 'order/data-summary',
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    fetchDataChart: builder.query<IResponseApi.IGetDataChartResponse, IParamsApi.IGetDataChartParams>({
      query: (input) => {
        return {
          url: 'order/data-chart',
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    checkSubmitFeedback: builder.query<IResponseApi.CommonResponse, IParamsApi.ICheckSubmitFeedbackParams>({
      query: (input) => convertParams(input),
    }),

    fetchListOrders: builder.query<IResponseApi.IGetListOrdersResponse, IParamsApi.IGetListOrderParams>({
      query: (input) => {
        return {
          url: 'order/search',
          method: 'GET',
          params: { ...params, ...input },
        };
      },
      providesTags: [{ type: 'order' }],
    }),

    checkWebhook: builder.query<IResponseApi.ICheckWebhookResponse, IParamsApi.ICheckWebhookParams>({
      query: (input) => convertParams(input),
    }),

    fetchProcessGetOldOrders: builder.query<IResponseApi.IProcessGetOldOrdersResponse, IParamsApi.IProcessGetOldOrdersParams>({
      query: (input) => {
        return {
          url: 'order/process-old-orders',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
    }),

    fetchListAccountsCurrentShop: builder.query<IResponseApi.IGetDisputeAccountsCurrentShopResponse, string>({
      query: (shop) => {
        return {
          url: 'dispute/account/list',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'listAccountDispute' }],
    }),

    connectAccountsDisputeCurrentShop: builder.mutation<
      Array<IResponseApi.IConnectDisputeAccountResponse>,
      IParamsApi.IConnectDisputeAccountParams
    >({
      query: (input) => {
        return {
          url: 'dispute/account/connect',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'listAccountDispute' }, { type: 'dispute' }],
    }),

    updateAccountsDisputeCurrentShop: builder.mutation<
      Array<IResponseApi.IUpdateDisputeAccountResponse>,
      IParamsApi.IUpdateDisputeAccountParams
    >({
      query: (input) => {
        return {
          url: 'dispute/account/update',
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'listAccountDispute' }, { type: 'dispute' }],
    }),

    deleteAccountDisputeCurrentShop: builder.mutation<Array<IResponseApi.CommonResponse>, IParamsApi.IDeleteDisputeAccountParams>(
      {
        query: (input) => {
          return {
            url: 'dispute/account/remove',
            method: 'DELETE',
            params: { ...params, ...input },
          };
        },
        invalidatesTags: [{ type: 'listAccountDispute' }, { type: 'dispute' }],
      },
    ),

    fetchDisputeAccountsFilter: builder.query<Array<IResponseApi.IGetDisputeAccountsResponse>, string>({
      query: (shop) => {
        return {
          url: 'dispute/accounts',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [
        {
          type: 'listAccountDispute',
        },
      ],
    }),

    fetchDisputeAnalysis: builder.query<IResponseApi.IGetDisputeAnalysisResponse, IParamsApi.IGetDisputeAnalysisParams>({
      query: (input) => {
        return {
          url: 'dispute/analysis',
          method: 'GET',
          params: {
            ...params,
            ...input,
          },
        };
      },
      providesTags: [{ type: 'dispute' }],
    }),

    fetchDisputeDetail: builder.query<IResponseApi.IGetDisputeDetailResponse, IParamsApi.IGetDisputeDetailParams>({
      query: (input) => {
        return {
          url: 'dispute/detail',
          method: 'GET',
          params: { ...params, ...input },
        };
      },
      providesTags: [{ type: 'disputeDetail' }],
    }),

    sendMessageDispute: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ISendMessageDisputeParams>({
      query: (input) => {
        return {
          url: 'dispute/send-message',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'disputeDetail' }],
    }),

    sendProvideEvidenceDispute: builder.mutation<IResponseApi.CommonResponse, FormData>({
      query: (input) => {
        return {
          url: 'dispute/provide-evidence',
          method: 'POST',
          body: input,
        };
      },
      invalidatesTags: [{ type: 'disputeDetail' }],
    }),

    makeOfferDispute: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IMakeOfferDispute>({
      query: (input) => {
        return {
          url: 'dispute/make-offer',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'disputeDetail' }],
    }),

    acceptClaimDispute: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IAcceptClaimDisputeParams>({
      query: (input) => {
        return {
          url: 'dispute/accept-claim',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'disputeDetail' }],
    }),

    escalateDispute: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IAcceptClaimDisputeParams>({
      query: (input) => {
        return {
          url: 'dispute/escalate-dispute',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'disputeDetail' }],
    }),

    fetchListDispute: builder.query<IResponseApi.IGetListDisputeResponse, IParamsApi.IGetListDisputeParams>({
      query: (input) => {
        return {
          url: 'dispute/get-disputes',
          method: 'GET',
          params: {
            ...params,
            ...input,
          },
        };
      },
      providesTags: [{ type: 'dispute' }],
    }),

    activeDispute: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IActiveDisputeParams>({
      query: (input) => {
        return {
          url: 'shop/dispute/active',
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: ['dataSettings'],
    }),

    fetchListDisputeByPaypalId: builder.query<
      IResponseApi.IGetListDisputeByPaypalIdResponse,
      IParamsApi.IGetListDisputeByPaypalIdParams
    >({
      query: (input) => {
        return {
          url: 'dispute/from-paypal',
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    connectToPayPal: builder.query<IResponseApi.IConnectToPaypalResponse, undefined>({
      query: (input) => {
        return {
          url: 'paypal/url-connect',
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    fetchDataPaypayAccount: builder.query<IResponseApi.IGetDataPaypalAccountResponse, string>({
      query: (shop) => {
        return {
          url: 'paypal/accounts',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'paypal' }],
    }),

    updatePaypalAccount: builder.mutation<IResponseApi.IGetDataPaypalAccountResponse, IParamsApi.ISavePaypalAccountParams>({
      query: (input) => {
        return {
          url: 'paypal/update',
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'paypal' }],
    }),

    setPrimaryAccountPaypal: builder.mutation<
      IResponseApi.ISetPrimaryPaypalAccountResponse,
      IParamsApi.ISetPrimaryPaypalAccountParams
    >({
      query: (input) => {
        return {
          url: 'paypal/set-primary',
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'paypal' }],
    }),

    detelePaypalAccount: builder.mutation<IResponseApi.IDetelePaypalAccountResponse, IParamsApi.IDetelePaypalAccountParams>({
      query: (input) => {
        return {
          url: 'paypal/delete',
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'paypal' }, { type: 'dataSettings' }],
    }),

    updateNotifyBuyer: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUpdateNotifyBuyerPaypalParams>({
      query: (input) => {
        return {
          url: 'paypal/notify-buyer/update',
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    fetchListStripeToken: builder.query<IResponseApi.IGetListStripeTokenResponse, string>({
      query: (shop) => {
        return {
          url: 'stripe/tokens',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'stripe' }],
    }),

    updateSyncStripe: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUpdateSyncStripeParams>({
      query: (input) => {
        return {
          url: 'stripe/active-sync',
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    saveStripeAccount: builder.mutation<IResponseApi.ISaveStripeAccount, IParamsApi.ISaveStripeAccountParams>({
      query: (input) => {
        return {
          url: 'stripe/save-account',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'stripe' }, { type: 'dataSettings' }],
    }),

    deleteStripeAccount: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IDeleteStripeTokenParams>({
      query: (input) => {
        return {
          url: 'stripe/remove-account',
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'stripe' }, { type: 'dataSettings' }],
    }),

    fetchListPayPalCourier: builder.query<IResponseApi.IGetListPayPalCourierResponse, string>({
      query: (shop) => {
        return {
          url: 'paypal/courier-mapping/list-courier',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'courierMapping' }],
    }),

    fetchListCourierIgnore: builder.query<IResponseApi.IGetListCourierIgnoreResponse, string>({
      query: (shop) => {
        return {
          url: 'paypal/couriers-ignore',
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'courierIgnore' }],
    }),

    saveCourierIgnore: builder.mutation<IResponseApi.IGetListCourierIgnoreResponse, IParamsApi.ISaveCourierIgnoreParams>({
      query: (input) => {
        return {
          url: 'paypal/couriers-ignore/save',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'courierIgnore' }],
    }),

    removeCourierIgnore: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: 'paypal/couriers-ignore/delete',
          method: 'DELETE',
          params: {
            ...params,
            id,
          },
        };
      },
      invalidatesTags: [{ type: 'courierIgnore' }],
    }),

    updateMapping: builder.mutation<IResponseApi.ISaveMappingResponse, IParamsApi.ISaveMappingParams>({
      query: (input) => {
        return {
          url: 'paypal/courier-mapping/save-mapping',
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'courierMapping' }],
    }),

    deleteMappingById: builder.mutation<any, IParamsApi.IDeleteMappingByIdParams>({
      query: (input) => {
        return {
          url: 'paypal/courier-mapping/remove-mapping',
          method: 'DELETE',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'courierMapping' }],
    }),

    fetchListStoreIntegrate: builder.query<IResponseApi.IGetListStoreIntegrateResponse, string>({
      query: (shop) => {
        return {
          url: `shop/integrate-stores`,
          method: 'GET',
          params: { ...params, shop },
        };
      },
      providesTags: [{ type: 'store' }],
    }),

    addStoreInter: builder.query<IResponseApi.IAddStoreInterGration, IParamsApi.IAddStoreIntegrationParams>({
      query: (input) => {
        return {
          url: `shop/multi-stores/check-store`,
          method: 'GET',
          params: { ...params, store: input.store },
        };
      },
    }),

    generateConnectionKey: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IGenerateConnectionKeyParams>({
      query: (input) => {
        return {
          url: `shop/multi-stores/connection-key`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }, { type: 'paypal' }],
    }),

    deleteConnectionKey: builder.mutation<IResponseApi.CommonResponse, string>({
      query: (shop) => {
        return {
          url: `shop/multi-stores/delete-connection-key`,
          method: 'PUT',
          body: { ...params, shop },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    submitConnectionKey: builder.mutation<IResponseApi.ISubmitConnectionKey, IParamsApi.ISubmitConnectionKeyParams>({
      query: (input) => {
        return {
          url: `shop/multi-stores/connect`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'store' }, { type: 'dataSettings' }, { type: 'order' }],
    }),

    disconnectStore: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IDisconnectStoreParams>({
      query: (input) => {
        return {
          url: `shop/multi-stores/disconnect`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'store' }, { type: 'dataSettings' }, { type: 'order' }],
    }),

    updateDigitalProduct: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUpdateDigitalProductParams>({
      query: (input) => {
        return {
          url: `shop/digital-product/active`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    // notes order
    updateSaveDataSyncToShopify: builder.mutation<IResponseApi.IShopifyNotesOrder, IParamsApi.IUpdateNotifyBuyerOrderParams>({
      query: (input) => {
        return {
          url: `shop/order-notes/active`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    exportOrders: builder.query<IResponseApi.IExportOrders, IParamsApi.IExportListOrderParams>({
      query: (input) => {
        return {
          url: `order/export/get-link`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
      providesTags: [{ type: 'order' }],
    }),

    importOrders: builder.mutation<IResponseApi.CommonResponse, FormData>({
      query: (file) => {
        return {
          url: `order/upload/orders`,
          method: 'POST',
          body: file,
        };
      },
      invalidatesTags: [{ type: 'order' }],
    }),

    importTrackingNumber: builder.mutation<IResponseApi.CommonResponse, FormData>({
      query: (file) => {
        return {
          url: `order/upload/tracking-numbers`,
          method: 'POST',
          body: file,
        };
      },
      invalidatesTags: [{ type: 'order' }],
    }),

    activePlan: builder.mutation<IResponseApi.IActivePlan, IParamsApi.IActivePlanParams>({
      query: (input) => {
        return {
          url: `shop/plan/active`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    applyDiscountCode: builder.mutation<IResponseApi.IApplyDiscountCode, IParamsApi.IApplyDiscountParams>({
      query: (input) => {
        return {
          url: `discount/apply-code`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    clickReview: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IClickReview>({
      query: (input) => {
        return {
          url: `review-click`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
    }),

    updateWelcomStatus: builder.mutation<IResponseApi.IUpdateWelcomeStatus, void>({
      query: () => {
        return {
          url: `welcome/update/status`,
          method: 'PUT',
          body: { ...params },
        };
      },
    }),

    updateWelcomStep: builder.mutation<IResponseApi.IUpdateWelcomeStep, IParamsApi.IUpdateWelcomeStepParams>({
      query: (input) => {
        return {
          url: `welcome/update/step`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
    }),

    fetchFaqs: builder.query<IResponseApi.IFaqsResponse, undefined>({
      query: () => {
        return {
          url: `faqs`,
          method: 'GET',
        };
      },
    }),

    submitDemo: builder.mutation<IResponseApi.ISubmitOrderResponse, IParamsApi.ISubmitOrderDemo>({
      query: (input) => {
        return {
          url: `order/demo`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),

    checkConnectPaypal: builder.query<IResponseApi.CommonResponse, undefined>({
      query: (input) => {
        return {
          url: `paypal/check-connect`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),

    processCodePayPalReturn: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IProcessCodePayPalReturnParams>({
      query: (input) => {
        return {
          url: `paypal/code-return`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'paypal' }, { type: 'dataSettings' }],
    }),

    emailConsent: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IEmailConsent>({
      query: (input) => {
        return {
          url: `marketing/consent/tracking`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    fetchGA: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IGA4>({
      query: (input) => {
        return {
          url: `marketing/analytic/ga-4/event`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
    }),

    fetchNotifications: builder.query<IResponseApi.IListNotifications, IParamsApi.IGetNotification>({
      query: (input) => {
        return {
          url: `notification`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
      providesTags: [{ type: 'notification' }],
    }),

    readNotifications: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IReadNotification>({
      query: (input) => {
        return {
          url: `notification/readed`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'notification' }],
    }),

    // location
    getCountry: builder.query<IResponseApi.IGetListCountry, void>({
      query: () => {
        return {
          url: `location/country/search`,
          method: 'GET',
          params: { ...params, page: 1, perPage: 1000 },
        };
      },
    }),
    getState: builder.query<IResponseApi.IGetListState, IParamsApi.IGetState>({
      query: (input) => {
        return {
          url: `location/state/search`,
          method: 'GET',
          params: { ...params, ...input, page: 1, perPage: 1000 },
        };
      },
    }),
    getCity: builder.query<IResponseApi.IGetListCity, IParamsApi.IGetCity>({
      query: (input) => {
        return {
          url: `location/city/search`,
          method: 'GET',
          params: { ...params, ...input, page: 1, perPage: 1000 },
        };
      },
    }),

    getDisputeCountryCode: builder.query<IResponseApi.IGetListCountryOffer, undefined>({
      query: () => {
        return {
          url: `dispute/offer-country-code`,
          method: 'GET',
          params: { ...params, page: 1, perPage: 1000 },
        };
      },
    }),

    // Facebook and Instagram payment
    getListFacebookAndInstagramPayment: builder.query<IResponseApi.IGetListFacebookAndInstagramPayment, undefined>({
      query: () => {
        return {
          url: `paypal/paypal-id/search`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: [{ type: 'facebookAndInstagram' }],
    }),

    createFacebookAndInstagramPayment: builder.mutation<
      IResponseApi.CommonResponse,
      IParamsApi.ICreateFacebookAndInstagramPayment
    >({
      query: (input) => {
        return {
          url: `paypal/paypal-id/create`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'facebookAndInstagram' }],
    }),

    updateFacebookAndInstagramPayment: builder.mutation<
      IResponseApi.CommonResponse,
      IParamsApi.IUpdateFacebookAndInstagramPayment
    >({
      query: (input) => {
        return {
          url: `paypal/paypal-id/update`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'facebookAndInstagram' }],
    }),

    deleteFacebookAndInstagramPayment: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (id) => {
        return {
          url: `paypal/paypal-id/remove`,
          method: 'DELETE',
          params: { ...params, id },
        };
      },
      invalidatesTags: [{ type: 'facebookAndInstagram' }],
    }),

    checkLogging: builder.query<void, IParamsApi.ICheckLoggin>({
      query: (input) => {
        return {
          url: `shop/logging`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
    }),

    bannerClick: builder.mutation<void, IParamsApi.IBannerClick>({
      query: (input) => {
        return {
          url: `marketing/banner-tracking/click`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
    }),

    submitLoyalCustomer: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ILoyalCustomer>({
      query: (input) => {
        return {
          url: `admin/loyal-customer/submit`,
          method: 'POST',
          body: { ...params, ...input },
        };
      },
    }),

    pixelBannerDisplay: builder.query<IResponseApi.IGetPixelBanner, string>({
      query: (shop) => {
        return {
          url: `marketing/pixel-banner`,
          method: 'GET',
          params: { ...params, shop },
        };
      },
    }),

    storeReviewDisplay: builder.mutation<IResponseApi.CommonResponse, void>({
      query: () => {
        return {
          url: `shop/review`,
          method: 'PUT',
          body: { ...params },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    disputeReport: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IDisputeReport>({
      query: (input) => {
        return {
          url: `shop/dispute/report/active`,
          method: 'PUT',
          body: { ...params, ...input },
        };
      },
      invalidatesTags: [{ type: 'dataSettings' }],
    }),

    disputeChart: builder.query<IResponseApi.IDisputeChart, IParamsApi.IDisputeChart>({
      query: (input) => {
        return {
          url: `dispute/chart`,
          method: 'GET',
          params: { ...params, ...input },
        };
      },
    }),
  }),
});
