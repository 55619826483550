import RegularText from '@/components/RegularText';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { IDispute } from '@/types/components';
import { Checkbox, List, Modal, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
const Escalate = ({ ...props }: IDispute.IDisputeEscalate): JSX.Element => {
  const [escalate, escalateStatus] = apiCaller.useEscalateDisputeMutation();
  const [state, setState] = useState({
    reason: {
      value: '',
      error: '',
    },
    checkbox: false,
  });
  const handleEscalate = () => {
    escalate({
      disputeId: props.disputeId,
      note: state.reason.value,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setState({
          ...state,
          reason: {
            ...state.reason,
            error: condition.msg,
          },
        });
      } else {
        handleToastMutation(res);
        setState({
          reason: {
            value: '',
            error: '',
          },
          checkbox: false,
        });
        props.onClose();
      }
    });
  };
  return (
    <Modal
      open={props.isOpenModal}
      onClose={() => {
        props.onClose();
        setState({
          reason: {
            value: '',
            error: '',
          },
          checkbox: false,
        });
      }}
      title="Escalate dispute to PayPal"
      primaryAction={{
        content: 'Escalate dispute',
        onAction: handleEscalate,
        disabled: !state.reason.value || !state.checkbox,
        loading: escalateStatus.isLoading,
      }}
    >
      <Modal.Section>
        <RegularText>A few things you should know before you escalate:</RegularText>
        <div className="mt-8">
          <List type="bullet">
            <List.Item>You won’t be able to communicate directly with the buyer, once you escalate this issue</List.Item>
            <List.Item>Any open offers will be canceled</List.Item>
            <List.Item>PayPal will review the information you exchanged with the buyer</List.Item>
            <List.Item>It can take up to 30 days for PayPal to review your case and reach a decision</List.Item>
          </List>
        </div>

        <TextField
          label="Reason for escalating this issue"
          value={state.reason.value}
          onChange={(value) =>
            setState({
              ...state,
              reason: {
                value,
                error: '',
              },
            })
          }
          onBlur={() => {
            if (!state.reason.value) {
              setState({
                ...state,
                reason: {
                  ...state.reason,
                  error: 'Note is required',
                },
              });
            }
          }}
          error={state.reason.error}
          multiline={5}
          autoComplete="off"
        />
        <Checkbox
          label="I hereby declare that the information provided here is true to the best of my knowledge."
          checked={state.checkbox}
          onChange={(value) => {
            setState({ ...state, checkbox: value });
          }}
        />
      </Modal.Section>
    </Modal>
  );
};

export default memo(Escalate);
