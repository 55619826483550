import InformationBanner from '@/components/InformationBanner';
import { Plan, Subscription } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isVisibleProcessOldOrdersUpdatePlanSelector } from '@/redux/slice/commonSetting.slice';
import { dataSettingsSelector } from '@/redux/slice/masterData.slice';
import { ChoiceList, Modal, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
const planLevel = {
  free: 1,
  basic: 2,
  pro: 3,
  premium: 4,
  unlimited: 5,
};

interface Props {
  plan: Plan;
  subcriptions: Subscription;
  isOpen: boolean;
  handleClose: () => void;
}

const PlanTableModal = ({ ...props }: Props) => {
  const isVisibleBanner = useSelector(isVisibleProcessOldOrdersUpdatePlanSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const { prePathName } = location.state || {};
  const [activePlanMutation, { isLoading }] = apiCaller.useActivePlanMutation();
  const [clickReviewMutation] = apiCaller.useClickReviewMutation();
  const [state, setState] = useState({
    overQuotaSelected: '1',
    period: '30',
  });

  const handleChangeRangeDayProcess = (value: string) => {
    if (Number(value) > 365) {
      value = '365';
    }
    setState({
      ...state,
      period: value,
    });
  };
  const confirmActivePlan = () => {
    clickReviewMutation({
      type: 'click_upgrade',
      note: 'table_account',
    });
    activePlanMutation({
      subscription: props.subcriptions,
      dayProcess: Number(state.period),
      type: props.plan,
      source: prePathName,
    }).then((res) => {
      if ('data' in res) {
        if (res.data.confirmationUrl) {
          window.top?.location.replace(res.data.confirmationUrl);
        }
      }
      props.handleClose();
    });
  };

  const modalDowngradeMarkup = (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      title="Are you sure?"
      primaryAction={{
        content: 'Confirm, I understand the risk',
        onAction: confirmActivePlan,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: 'Leave it now',
          onAction: props.handleClose,
        },
      ]}
    >
      <Modal.Section>
        <div className="plan-table">
          Please confirm this downgrade plan may affect immediately to <b>the number of orders tracking syncing to Paypal</b>. And
          you need to <b>reconnect the sub-stores</b> if you are using <b>Multi-stores integration</b>
        </div>
      </Modal.Section>
    </Modal>
  );

  const modalUpgradeMarkup = (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      title="Would you like to process the old orders? "
      primaryAction={{
        content: 'Active Plan',
        onAction: confirmActivePlan,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: props.handleClose,
        },
      ]}
    >
      <Modal.Section>
        <div className="plan-table">
          <ChoiceList
            title=""
            choices={[
              {
                label: (
                  <div className="d-flex plan-table" style={{ lineHeight: '36px' }}>
                    Process the old orders in last{' '}
                    <div className="horizontal-4">
                      <TextField
                        label=""
                        type="number"
                        autoComplete="off"
                        value={state.period}
                        onChange={(value) => {
                          handleChangeRangeDayProcess(value);
                        }}
                        max={365}
                      />
                    </div>
                    {'(max 365 days)'}
                  </div>
                ),
                value: '1',
              },
              { label: 'Skip the old orders', value: '2' },
            ]}
            selected={[state.overQuotaSelected]}
            onChange={(value) => {
              setState({ ...state, overQuotaSelected: value[0] });
            }}
          />
        </div>
        <InformationBanner
          isVisible={isVisibleBanner}
          title="Note"
          onDismiss={() => {
            dispatch(slice.commonSettingSlice.actions.handleVisibleProcessOldOrdersUpdatePlan(false));
          }}
        >
          - The old orders will be count to your number of usage this month.
          <br />- If you are using multi-store integration, the subscription fee will apply to your main store.
        </InformationBanner>
      </Modal.Section>
    </Modal>
  );

  return (
    <div>
      {dataSettings && planLevel[props.plan as keyof typeof planLevel] < planLevel[dataSettings.plan as keyof typeof planLevel]
        ? modalDowngradeMarkup
        : modalUpgradeMarkup}
    </div>
  );
};

export default PlanTableModal;
