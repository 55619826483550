import supportImg from '@/assets/dashboard/support.png';
import freeImg from '@/assets/pricing/free.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import { Ga4Event, Plan, Subscription } from '@/constants/enum';
import useScope from '@/hooks/Scope';
import { useGa4 } from '@/hooks/useGa4';
import bannerSlice, { CrossSellSelector } from '@/redux/slice/banner.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { Badge, Button, Icon, InlineGrid, Link, SkeletonDisplayText, Text } from '@shopify/polaris';
import { HeartMajor, SandboxMajor, TickMinor } from '@shopify/polaris-icons';
import parse from 'html-react-parser';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DiscountCode } from './DiscountCode';
import PlanTableModal from './PlanTable';
import { AccountPlanStyled } from './styled';
import pricingTableData from './tableData';

const AccountPlan = () => {
  const scope = useScope();
  const [isOpenModalDiscount, setIsOpenModalDiscount] = useState(false);
  const [state, setState] = useState({
    plan: Plan.BASIC,
    isOpen: false,
  });
  const isLoading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [subcription, setSubcription] = useState(dataSettings?.subscription);
  const crossSell = useSelector(CrossSellSelector);
  const { handleGa4 } = useGa4();
  const dispatch = useDispatch();

  const subcriptionStatus = useMemo(() => {
    if (subcription) {
      return {
        monthly: subcription === Subscription.Monthly,
        annually: subcription === Subscription.Annualy,
      };
    } else {
      return {
        monthly: dataSettings?.subscription === Subscription.Monthly,
        annually: dataSettings?.subscription === Subscription.Annualy,
      };
    }
  }, [dataSettings, subcription]);
  useEffect(() => {
    setSubcription(dataSettings?.subscription);
  }, [dataSettings?.subscription]);
  if (!crossSell.isShowPricing) {
    dispatch(bannerSlice.actions.handleBanner({ key: 'isShowPricing', value: true }));
    handleGa4(Ga4Event.pricingPlan);
  }
  return (
    <AccountPlanStyled>
      <span className="account-subcription-btn">
        <Button
          onClick={() => {
            setSubcription(Subscription.Monthly);
          }}
          variant={subcriptionStatus.monthly ? 'primary' : 'tertiary'}
        >
          Monthly
        </Button>
        <Button
          onClick={() => {
            setSubcription(Subscription.Annualy);
          }}
          variant={subcriptionStatus.annually ? 'primary' : 'tertiary'}
        >
          Annual (save 30%)
        </Button>
      </span>
      <div className="mt-16 discount-btn">
        {dataSettings && dataSettings.discount ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RegularText>
              Enjoy your{' '}
              <b>
                {dataSettings.discountType === 'percent'
                  ? `${dataSettings.discount}% discount`
                  : dataSettings.discountType === 'price'
                  ? `$${dataSettings.discount} discount`
                  : `fixed price $${dataSettings.discount}`}
              </b>{' '}
              for all monthly plans when upgrading.
            </RegularText>
            <CustomButton
              onClick={() => {
                setIsOpenModalDiscount(true);
              }}
              variant="plain"
            >
              Replace your discount code
            </CustomButton>
          </div>
        ) : (
          <CustomButton
            onClick={() => {
              setIsOpenModalDiscount(true);
            }}
            variant="plain"
          >
            Apply your discount code
          </CustomButton>
        )}
      </div>
      <div className="mt-16 w-100">
        <InlineGrid columns={{ xl: 4, lg: 4, md: 2, sm: 1, xs: 1 }} gap={'400'}>
          {pricingTableData.map((item) => {
            return (
              <div key={item.name} className="account-plan-details ">
                <div>
                  <BoldText>{item.display_name}</BoldText>
                  <div className="mt-8">
                    <RegularText>{item.description}</RegularText>
                  </div>
                  <div className="mt-16">
                    <Text as="h3" variant="headingLg">
                      {subcriptionStatus.monthly ? (
                        <Text as="h3" variant="headingLg">
                          {item.price}
                        </Text>
                      ) : (
                        <span className="d-flex">
                          <Text as="h3" variant="headingLg">
                            {item.priceYearly}
                          </Text>
                          <div className="ml-8">
                            <Text as="p" textDecorationLine="line-through" variant="bodySm" tone="critical">
                              {item.price}
                            </Text>
                          </div>
                        </span>
                      )}
                    </Text>
                  </div>
                  <div className="mt-8">
                    <RegularText>monthly</RegularText>
                  </div>

                  <div className="mt-16 d-flex account-benefit">
                    <Icon source={TickMinor} tone="success" />
                    <div className="ml-8">
                      <RegularText>
                        <b> {item.quota} </b> synced/month
                      </RegularText>
                    </div>
                  </div>
                  <div className="d-flex account-benefit">
                    <Icon source={SandboxMajor} tone="caution" />
                    <div className="ml-8">
                      <BoldText>All in {item.prePlan}, plus:</BoldText>
                    </div>
                  </div>
                  {item.content.map((contentItem, index) => {
                    return (
                      <div key={index} className=" d-flex account-benefit">
                        <div>
                          <Icon source={TickMinor} tone="success" />
                        </div>
                        <div className="ml-8">
                          <RegularText>{parse(contentItem)}</RegularText>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {isLoading ? (
                  <div className="mt-16">
                    <SkeletonDisplayText />
                  </div>
                ) : (
                  <div className="account-plan-details-btn">
                    {subcription && dataSettings?.subscription === subcription && dataSettings.plan === item.name ? (
                      <Badge progress="complete" tone="success">
                        Active
                      </Badge>
                    ) : (
                      <CustomButton
                        onClick={() => {
                          if (!crossSell.isChoosePlan) {
                            handleGa4(Ga4Event.choosePlan, {
                              plan: item.name,
                              type: subcription,
                            });
                            dispatch(
                              bannerSlice.actions.handleBanner({
                                key: 'isChoosePlan',
                                value: true,
                              }),
                            );
                          }

                          setState({ isOpen: true, plan: item.name });
                        }}
                        variant="primary"
                      >
                        Choose plan
                      </CustomButton>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </InlineGrid>
      </div>

      <div className="mt-16 w-100">
        <BannerTemplate src={freeImg}>
          <div className="account-free-right">
            <BoldText>You can experience Synctrack completely for FREE with:</BoldText>

            <div className="mt-8 account-free-benefit">
              <Icon source={TickMinor} tone="success" />
              <div className="ml-8">
                <RegularText>30 synced orders/month</RegularText>
              </div>
            </div>
            <div className="mt-8 account-free-benefit">
              <Icon source={TickMinor} tone="success" />
              <div className="ml-8">
                <RegularText>Unlimited PayPal accounts</RegularText>
              </div>
            </div>
            <div className="mt-8 account-free-benefit">
              <Icon source={TickMinor} tone="success" />
              <div className="ml-8">
                <RegularText>Sync old orders</RegularText>
              </div>
            </div>
            <div className="mt-8 account-free-benefit">
              <Icon source={TickMinor} tone="success" />
              <div className="ml-8">
                <RegularText>Auto-matching courier</RegularText>
              </div>
            </div>
            {isLoading ? (
              <SkeletonDisplayText />
            ) : (
              <div className="mt-8">
                <Button
                  disabled={dataSettings?.plan === Plan.FREE || scope.isViewOnly}
                  onClick={() => {
                    setState({ isOpen: true, plan: Plan.FREE });
                  }}
                >
                  {dataSettings?.plan === Plan.FREE ? 'Current plan' : 'Choose plan'}
                </Button>
              </div>
            )}
          </div>
        </BannerTemplate>
      </div>

      <div className="mt-16 w-100">
        <BannerTemplate src={supportImg}>
          <div className="account-support-right">
            <div className="account-support-right-title">
              <Icon source={HeartMajor} tone="base" />
              <Text as="h3" variant="headingMd">
                We're here for you. What do you need?
              </Text>
            </div>
            <div className="mt-8">
              <RegularText>
                We always try to provide the best, most complete support service whenever you need us.
                <br />
                You can always email us here -{' '}
                <Link url="mailto:support@synctrack.io" target="_blank">
                  support@synctrack.io
                </Link>
              </RegularText>
            </div>

            <div className="mt-16 d-flex">
              <Button url="https://docs.synctrack.io/" external>
                See documentation
              </Button>
              <div className="ml-8">
                <CustomButton
                  variant="primary"
                  onClick={() => {
                    try {
                      $crisp.push(['do', 'chat:open']);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Start a conversation with the team
                </CustomButton>
              </div>
            </div>
          </div>
        </BannerTemplate>
      </div>

      <PlanTableModal
        plan={state.plan}
        subcriptions={subcription || Subscription.Monthly}
        isOpen={state.isOpen}
        handleClose={() => setState({ ...state, isOpen: false })}
      />

      <DiscountCode isOpen={isOpenModalDiscount} handleClose={() => setIsOpenModalDiscount(false)} />
    </AccountPlanStyled>
  );
};

export default memo(AccountPlan);
