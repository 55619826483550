/* eslint-disable jsx-a11y/iframe-has-title */
import BoldText from '@/components/BoldText';
import CustomButton from '@/components/CustomButton';
import InformationBanner from '@/components/InformationBanner';
import RegularText from '@/components/RegularText';
import CustomCard from '@/components/card';
import config from '@/config';
import { Plan } from '@/constants/enum';
import { LINK } from '@/constants/link';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, listStoreSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { isVisibleBannerStoresSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import {
  Badge,
  Banner,
  Button,
  Checkbox,
  Icon,
  InlineGrid,
  Link,
  Modal,
  SkeletonBodyText,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { DeleteMajor, DomainNewMajor, DuplicateMinor, ExchangeMajor, ExistingInventoryMajor } from '@shopify/polaris-icons';
import { memo, useCallback, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import BuyMultiStores from './BuyMultiStores';
import { IntergrationStoreStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/constants/path';

const MultiStore = (): JSX.Element => {
  const [submitExistKey, setSubmitExistKey] = useState({ msg: '' });
  const [state, setState] = useState<ISettingsPage.IStateMultiStoreSetting>({
    isOpenAddExistKeyModal: false,
    isOpenAddStoreModal: false,
    checkedInherit: false,
    store: '',
    storeErrText: '',
    connectionKeyErr: '',
    connectionKey: '',
  });
  const buyMoreStoresRef = useRef<any>(null);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const isLoading = useSelector(loadingSelector);
  const listStore = useSelector(listStoreSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [disconnectStoreMutation, disconectStoreStatus] = apiCaller.useDisconnectStoreMutation();
  const [addStoreInterGrationStore, addStoreInterGrationStoreStatus] = apiCaller.useLazyAddStoreInterQuery();
  const [generateConnectionKeyMutation, generateConnectionKeyStatus] = apiCaller.useGenerateConnectionKeyMutation();
  const [deleteConnectionKeyMutation, deleteConnectionKeyStatus] = apiCaller.useDeleteConnectionKeyMutation();
  const [submitConnectionKeyMutation, submitConnectionStatus] = apiCaller.useSubmitConnectionKeyMutation();
  const isVisibleBanner = useSelector(isVisibleBannerStoresSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenModalDisconnect, setisOpenModalDisconnect] = useState({ status: false, store: '' });

  const handleCheckBox = useCallback((newChecked: any) => setState({ ...state, checkedInherit: newChecked }), [state]);
  const openModalConnection = () => {
    setState({
      ...state,
      isOpenAddExistKeyModal: true,
    });
  };

  const closeModalAddConnection = () => {
    setSubmitExistKey({
      msg: '',
    });
    setState({
      ...state,
      isOpenAddExistKeyModal: false,
      connectionKey: '',
      connectionKeyErr: '',
    });
  };

  const getStatus = (shopParent: string, shop: string) => {
    if (shopParent === shop) {
      return <Badge tone="success"> Main store</Badge>;
    } else {
      return 'Integrated store';
    }
  };

  const generateConnectionKey = () => {
    let connectionKey = uuidv4();
    generateConnectionKeyMutation({ connectionKey }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  const changeconnectionKey = (value: string) => {
    if (submitExistKey.msg) {
      setSubmitExistKey({ msg: '' });
    }
    setState({
      ...state,
      connectionKey: value,
      connectionKeyErr: '',
    });
  };

  const changeStore = (value: string) => {
    setState({
      ...state,
      store: value,
      storeErrText: '',
    });
  };

  const confirmDisconnectStore = (store: string) => () => {
    disconnectStoreMutation({ store }).then((res) => {
      setisOpenModalDisconnect({
        ...isOpenModalDisconnect,
        status: false,
      });
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  const handleAddStoreIntergration = () => {
    addStoreInterGrationStore({ store: state.store }).then((res) => {
      if (res.isSuccess && res.data.state === 1) {
        window.open(res.data.url, '_blank', 'noopener,noreferrer');
        setState({ ...state, isOpenAddStoreModal: false });
      } else {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setState({ ...state, storeErrText: condition.msg });
        } else {
          setState({ ...state, isOpenAddStoreModal: false });
        }
      }
    });
  };

  const handleDeleteConnectionKey = () => {
    deleteConnectionKeyMutation(config.shop).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  const handleSubmitConnectionKey = () => {
    submitConnectionKeyMutation({ checkedInherit: state.checkedInherit, connectionKey: state.connectionKey }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setSubmitExistKey({
          msg: condition.msg,
        });
      } else {
        setState({
          ...state,
          isOpenAddExistKeyModal: false,
        });
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };
  const scrollToBuyMoreStore = () => {
    if (buyMoreStoresRef.current) {
      buyMoreStoresRef.current.scrollIntoView({ behavior: 'smooth' });
      if (!isHighlighted) {
        setIsHighlighted(true);

        // Sau một thời gian nhất định (ví dụ 2 giây), bỏ highlight
        setTimeout(() => {
          setIsHighlighted(false);
        }, 2000);
      }
    }
  };
  return (
    <IntergrationStoreStyled>
      <InformationBanner
        isVisible={isVisibleBanner}
        onDismiss={() => {
          dispatch(slice.settingsPageSlice.actions.handleVisibleBannerStores(false));
        }}
        title="Benefits of integration when you have multiple stores?"
      >
        <RegularText>- Manage tracking number synchronization across all stores in one place.</RegularText>
        <br />
        <RegularText>- Pay a single fee for syncing tracking numbers across multiple stores.</RegularText>
        <br />
        <RegularText>
          <Link url={LINK.connectMultiStoreVideo} external removeUnderline>
            How to connect multiple stores?
          </Link>
        </RegularText>
      </InformationBanner>

      <div className={`${isVisibleBanner ? 'mt-16' : ''}`}>
        <CustomCard title="Integrated stores">
          <div className="store-content-top">
            <RegularText>
              <Link url={LINK.multiStoreGuide} external removeUnderline>
                View multi-store instruction
              </Link>
            </RegularText>
            {dataSettings && (dataSettings.connectionKey || dataSettings.shop === dataSettings.shopParent) ? (
              <>
                {listStore && dataSettings.slotMultiStores > listStore?.length ? (
                  <CustomButton onClick={() => setState({ ...state, isOpenAddStoreModal: true })} variant="primary">
                    Add connection
                  </CustomButton>
                ) : (
                  <CustomButton
                    onClick={dataSettings.plan !== Plan.UNLIMITED ? () => navigate(PATH.ACCOUNT) : scrollToBuyMoreStore}
                    variant="primary"
                  >
                    {dataSettings.plan !== Plan.UNLIMITED ? 'Upgrade for more stores' : 'Buy more store'}
                  </CustomButton>
                )}
              </>
            ) : null}
            {/* {dataSettings &&
            (dataSettings.plan === Plan.PREMIUM || dataSettings?.plan === Plan.UNLIMITED || dataSettings.slotMultiStores > 1) ? (
              <div className="store-content-top-btn">
                {!(dataSettings?.connectionKey === null && Number(listStore?.length) < 2) && (
                  <CustomButton
                    disabled={listStore ? dataSettings.slotMultiStores <= listStore?.length : true}
                    onClick={() => setState({ ...state, isOpenAddStoreModal: true })}
                    variant="primary"
                  >
                    Add connection
                  </CustomButton>
                )}
              </div>
            ) : null} */}
          </div>
          <div className="pl-5 pr-5">
            {dataSettings?.connectionKey === null && Number(listStore?.length) < 2 ? (
              <InlineGrid
                columns={
                  dataSettings &&
                  (dataSettings.plan === Plan.PREMIUM ||
                    dataSettings?.plan === Plan.UNLIMITED ||
                    dataSettings.slotMultiStores > 1)
                    ? { xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }
                    : { xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }
                }
              >
                {dataSettings &&
                (dataSettings.plan === Plan.PREMIUM ||
                  dataSettings?.plan === Plan.UNLIMITED ||
                  dataSettings.slotMultiStores > 1) ? (
                  <div className="integration-page-layout text-center">
                    <div>
                      <div
                        className="statistical-layout-icon"
                        style={{
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      >
                        <Icon tone="primary" source={DomainNewMajor} />
                      </div>
                      <div>
                        <div className="mb-16">
                          <BoldText>New setup</BoldText>
                        </div>
                        <div className="lh-20">
                          <RegularText>
                            Create a connection key, then add it to other stores to integrate into this store
                          </RegularText>
                        </div>
                      </div>
                    </div>
                    {/* {dataSettings.plan === Plan.FREE || dataSettings.plan === Plan.BASIC ? (
                      <div className="mb-16">
                        <AlertInline
                          content="To use this feature!"
                          link="Upgrade higher plan"
                          navigate={() => {
                            navigate(PATH.ACCOUNT);
                          }}
                        />
                      </div>
                    ) : null} */}
                    <div className="mt-16">
                      <CustomButton
                        loading={generateConnectionKeyStatus.isLoading}
                        onClick={generateConnectionKey}
                        variant="primary"
                      >
                        Generate a connection key
                      </CustomButton>
                    </div>
                  </div>
                ) : null}

                <div className="integration-page-layout text-center">
                  <div>
                    <div
                      className="statistical-layout-icon"
                      style={{
                        margin: 'auto',
                        borderRadius: '50%',
                      }}
                    >
                      <Icon tone="primary" source={ExistingInventoryMajor} />
                    </div>
                    <div>
                      <div className="mb-16">
                        <BoldText>Existing setup</BoldText>
                      </div>
                      <div className="lh-20">
                        <RegularText>
                          Want to integrate this store into another store of yours? Let's add an existing connection key for
                          integration
                        </RegularText>
                      </div>
                    </div>
                  </div>

                  <div className="mt-16">
                    <CustomButton onClick={() => openModalConnection()}>Add a existing key</CustomButton>
                  </div>
                </div>
              </InlineGrid>
            ) : isLoading ? (
              <SkeletonBodyText lines={10} />
            ) : (
              <div>
                {dataSettings?.plan === Plan.UNLIMITED ? (
                  <RegularText>
                    Total slots: <b>{dataSettings?.slotMultiStores}</b> | Used: <b>{listStore?.length}</b>
                  </RegularText>
                ) : null}
                <table width="100%" className="mt-16">
                  <thead>
                    <tr>
                      <th>
                        <BoldText>#</BoldText>
                      </th>
                      <th>
                        <Text variant="headingSm" as="p" alignment="start">
                          Store
                        </Text>
                      </th>
                      <th>
                        <Text variant="headingSm" as="p" alignment="center">
                          Status
                        </Text>
                      </th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listStore?.map((store, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td className={store.shop === config.shop ? 'active' : ''}>
                            <RegularText>
                              <Link external url={'https://' + store.shop} removeUnderline>
                                {store.shop}
                              </Link>
                            </RegularText>
                          </td>
                          <td>
                            <Text variant="bodySm" as="p" alignment="center">
                              {getStatus(store.shopParent, store.shop)}
                            </Text>
                          </td>
                          <td>
                            <div style={{ textAlign: 'center' }}>
                              {config.shop === store.shop ? (
                                <div>
                                  <Badge tone="success">This store</Badge>
                                </div>
                              ) : null}
                              {store.shopParent && store.shopParent !== store.shop && config.shop === listStore[0].shop ? (
                                <CustomButton
                                  onClick={() => setisOpenModalDisconnect({ status: true, store: store.shop })}
                                  variant="plain"
                                  tone="critical"
                                >
                                  Disconnect
                                </CustomButton>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {dataSettings?.connectionKey !== null ? (
                  <div className="connection-key-layout">
                    <BoldText>Connection key: </BoldText>
                    <span className="key">
                      <RegularText>{dataSettings?.connectionKey}</RegularText>
                    </span>

                    <div className="d-flex" style={{ alignItems: 'center' }}>
                      <CopyToClipboard
                        text={dataSettings?.connectionKey || ''}
                        onCopy={() =>
                          dispatch(
                            slice.commonSettingSlice.actions.handleToast({
                              content: 'Copied',
                              error: false,
                              isOpen: true,
                            }),
                          )
                        }
                      >
                        <span className="ml-8">
                          <Tooltip content={'Copy'}>
                            <Button icon={DuplicateMinor} />
                          </Tooltip>
                        </span>
                      </CopyToClipboard>
                      <span className="ml-8">
                        <Tooltip content={'Change'}>
                          <CustomButton
                            onClick={generateConnectionKey}
                            icon={ExchangeMajor}
                            loading={generateConnectionKeyStatus.isLoading}
                          />
                        </Tooltip>
                      </span>
                      <span className="ml-8">
                        <Tooltip content={'Delete'}>
                          <CustomButton
                            loading={deleteConnectionKeyStatus.isLoading}
                            onClick={handleDeleteConnectionKey}
                            icon={DeleteMajor}
                            tone="critical"
                          />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="connection-key-layout mt-4">
                    {listStore && config.shop === listStore[0].shop ? (
                      <CustomButton onClick={generateConnectionKey} tone="success">
                        Generate a connection key
                      </CustomButton>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </div>
        </CustomCard>
      </div>
      {dataSettings?.plan === Plan.UNLIMITED && (
        <div ref={buyMoreStoresRef}>
          <BuyMultiStores highlighted={isHighlighted} />
        </div>
      )}
      <Modal
        open={state.isOpenAddExistKeyModal}
        onClose={closeModalAddConnection}
        title="Add connection confirm"
        primaryAction={{
          disabled: state.connectionKey === '',
          content: submitExistKey.msg !== '' ? 'Ok' : 'Submit',
          onAction: submitExistKey.msg !== '' ? closeModalAddConnection : handleSubmitConnectionKey,
          loading: submitConnectionStatus.isLoading,
        }}
        secondaryActions={
          submitExistKey.msg === ''
            ? [
                {
                  content: 'Cancel',
                  onAction: () => closeModalAddConnection(),
                },
              ]
            : undefined
        }
      >
        <Modal.Section>
          <TextField
            label="Connection key: "
            value={state.connectionKey}
            onChange={changeconnectionKey}
            onBlur={() => {
              if (!state.connectionKey) {
                setState({
                  ...state,
                  connectionKeyErr: 'Connection key is required',
                });
              } else {
                const regEx = new RegExp('^[a-zA-Z0-9_.#.-]*$');
                if (!regEx.test(state.connectionKey)) {
                  setState({
                    ...state,
                    connectionKeyErr: 'The connection key is invalid',
                  });
                }
              }
            }}
            error={state.connectionKeyErr ? state.connectionKeyErr : submitExistKey.msg}
            placeholder="Example: xxxx-xxxx-xxxx-xxxx-xxxx"
            autoComplete="off"
          />
          <Checkbox
            label="Inherit the PayPal account from the main store"
            checked={state.checkedInherit}
            onChange={handleCheckBox}
          />
          <div className="mt-8">
            <Banner tone="warning">
              <RegularText>When you connect stores, order information is also shared among the connected stores</RegularText>
            </Banner>
          </div>
        </Modal.Section>
      </Modal>
      <Modal
        open={state.isOpenAddStoreModal}
        onClose={() => setState({ ...state, isOpenAddStoreModal: false, storeErrText: '', store: '' })}
        title="Add connection"
        primaryAction={{
          content: 'Add store',
          onAction: handleAddStoreIntergration,
          disabled: !!state.storeErrText || !state.store,
          loading: addStoreInterGrationStoreStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setState({ ...state, isOpenAddStoreModal: false }),
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Store name: "
            value={state.store}
            onChange={changeStore}
            onBlur={() => {
              if (!state.store) {
                setState({
                  ...state,
                  storeErrText: 'Store name is required',
                });
              }
            }}
            error={state.storeErrText}
            placeholder="Example: mystore.myshopify.com"
            autoComplete="off"
          />
          <div className="mt-8">
            <Banner tone="warning">
              <RegularText>When you connect stores, order information is also shared among the connected stores</RegularText>
            </Banner>
          </div>
        </Modal.Section>
      </Modal>
      <Modal
        open={isOpenModalDisconnect.status}
        title={'Are you sure?'}
        onClose={() => {
          setisOpenModalDisconnect({ store: '', status: false });
        }}
        footer={
          <div className="modal-footer">
            <Button
              onClick={() => {
                setisOpenModalDisconnect({ store: '', status: false });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                loading={disconectStoreStatus.isLoading}
                onClick={confirmDisconnectStore(isOpenModalDisconnect.store)}
                variant="primary"
                tone="critical"
              >
                Disconnect
              </Button>
            </div>
          </div>
        }
        sectioned
      >
        <RegularText>After disconnection, the secondary store will return to the Free plan</RegularText>
      </Modal>
    </IntergrationStoreStyled>
  );
};
export default memo(MultiStore);
