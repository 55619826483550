import { COLORS } from '@/constants/colors';
import styled from 'styled-components';

export const ArlertInlineStyled = styled.div`
  display: flex;
  .Polaris-Icon {
    margin: 0;
  }
  .Polaris-Link {
    color: ${COLORS.text_warning};
  }
  .Polaris-Text--root {
    line-height: 1.25rem;
  }
`;
