import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { ConnectMinor } from '@shopify/polaris-icons';
import { listPaypalSelector } from '@/redux/slice/masterData.slice';
import { useLocation } from 'react-router';
import { Button } from '@shopify/polaris';

const PaypalButton = ({ title }: { title?: string }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const listPaypal = useSelector(listPaypalSelector);
  const [updateWelcomeStep] = apiCaller.useUpdateWelcomStepMutation();
  const [fetchLazyConnectToPaypal] = apiCaller.useLazyConnectToPayPalQuery();
  const [mutationProcessCodePaypalReturn] = apiCaller.useProcessCodePayPalReturnMutation();
  const handleConnectToPayPal = () => {
    fetchLazyConnectToPaypal(undefined).then((res) => {
      if (res.isSuccess) {
        window.open(res.data.url, '_blank');
      }
    });
  };
  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data.type === 'paypalConnected') {
        mutationProcessCodePaypalReturn({ code: e.data.code, path: 'paypal' }).then((res) => {
          if (pathname === '/apps/welcome') {
            updateWelcomeStep({ step: 3 });
            dispatch(slice.WelcomeSlice.actions.handleStep(3));
          }
          const condition = checkShowErrorInline(res);
          if (!condition.status) {
            dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          }
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="d-flex">
      <Button icon={ConnectMinor} onClick={handleConnectToPayPal} variant="primary">
        {title ? title : listPaypal && listPaypal.length > 0 ? 'Add PayPal account' : 'Connect via PayPal login'}
      </Button>
    </div>
  );
};

export default memo(PaypalButton);
