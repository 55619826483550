import config from '@/config';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScript = () => {
  const location = useLocation();
  const checkReturnCodePaypal = location.pathname === 'apps/handle_request';
  useEffect(() => {
    if (!checkReturnCodePaypal) {
      var gtag = document.createElement('script');
      gtag.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-126587266-1');
      gtag.appendChild(document.createTextNode(``));

      var gtag2 = document.createElement('script');
      gtag2.appendChild(
        document.createTextNode(`
            window.dataLayer = window.dataLayer || [];
      
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
      
            gtag('config', 'UA-126587266-1');
          `),
      );

      var facebookPixels = document.createElement('script');
      facebookPixels.appendChild(
        document.createTextNode(`
            !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '302112827575627');
            fbq('track', 'PageView');
          `),
      );

      var canny = document.createElement('script');
      canny.appendChild(
        document.createTextNode(`
            !(function (w, d, i, s) {
              function l() {
                if (!d.getElementById(i)) {
                  var f = d.getElementsByTagName(s)[0],
                    e = d.createElement(s);
                  (e.type = 'text/javascript'), (e.async = !0), (e.src = 'https://canny.io/sdk.js'), f.parentNode.insertBefore(e, f);
                }
              }
              if ('function' != typeof w.Canny) {
                var c = function () {
                  c.q.push(arguments);
                };
                (c.q = []),
                  (w.Canny = c),
                  'complete' === d.readyState ? l() : w.attachEvent ? w.attachEvent('onload', l) : w.addEventListener('load', l, !1);
              }
            })(window, document, 'canny-jssdk', 'script');
          `),
      );

      var canny2 = document.createElement('script');
      canny2.appendChild(
        document.createTextNode(`
            Canny('render', {
              boardToken: 'e31159c6-06a9-27dc-f4ab-092f94c997cf',
              basePath: null, // See step 2
              ssoToken: null, // See step 3
            });
          `),
      );

      var connectFacebook = document.createElement('script');
      connectFacebook.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.9');
      connectFacebook.appendChild(document.createTextNode(``));

      var crisp = document.createElement('script');
      crisp.setAttribute('src', `https://apps.synctrack.io/apis/public/script/plugin.js?shop=${config.shop}`);
      crisp.setAttribute('id', 'synctrack-shopify-crisp');
      crisp.appendChild(document.createTextNode(``));

      var googleApi = document.createElement('script');
      googleApi.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js');
      googleApi.appendChild(document.createTextNode(``));

      var gtagManager = document.createElement('script');
      gtagManager.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-1010802353');
      gtagManager.appendChild(document.createTextNode(``));

      var gtagLayer = document.createElement('script');
      gtagLayer.appendChild(
        document.createTextNode(`
                window.dataLayer = window.dataLayer || [];
          
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());
          
                gtag('config', 'AW-1010802353');
              `),
      );

      var pageView = document.createElement('script');
      pageView.appendChild(
        document.createTextNode(`
                gtag('event', 'page_view', {
                  send_to: 'AW-1010802353',
                  user_id: 'replace with value',
                });
              `),
      );

      var conversion = document.createElement('script');
      conversion.appendChild(
        document.createTextNode(`
                gtag('event', 'conversion', {
                  send_to: 'AW-1010802353/OdbNCKy4k88BELG9_uED',
                });
              `),
      );

      var bing = document.createElement('script');
      bing.appendChild(
        document.createTextNode(`
                (function (w, d, t, r, u) {
                  var f, n, i;
                  (w[u] = w[u] || []),
                    (f = function () {
                      var o = {
                        ti: '17422155',
                      };
                      (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
                    }),
                    (n = d.createElement(t)),
                    (n.src = r),
                    (n.async = 1),
                    (n.onload = n.onreadystatechange =
                      function () {
                        var s = this.readyState;
                        (s && s !== 'loaded' && s !== 'complete') || (f(), (n.onload = n.onreadystatechange = null));
                      }),
                    (i = d.getElementsByTagName(t)[0]),
                    i.parentNode.insertBefore(n, i);
                })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
              `),
      );

      const listScript = [
        gtag,
        gtag2,
        facebookPixels,
        canny,
        canny2,
        connectFacebook,
        crisp,
        googleApi,
        gtagManager,
        gtagLayer,
        pageView,
        conversion,
        bing,
      ];
      const timer = setTimeout(
        () =>
          listScript.forEach((item) => {
            document.body.appendChild(item);
          }),
        2000,
      );
      return () => {
        clearTimeout(timer);
      };
    }
    // <!-- Global site tag (gtag.js) - Google Analytics -->
  }, [checkReturnCodePaypal]);
  return null;
};
export default useScript;
