import { COLORS } from '@/constants/colors';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const DashBoardStyled = styled.div`
  .dashboard-img {
    img {
      height: 216px;
      width: 244px;
      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        width: 50%;
        height: auto;
      }
    }
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      justify-content: center;
      padding-right: 0rem;
      padding-bottom: 1rem;
    }
  }
  .action-item-container {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    &:hover {
      background-color: ${COLORS.bgHover};
    }
  }
  .apps-title {
    display: flex;
    justify-content: space-between;
  }
  .apps-wrapper {
    .recommended-apps-container {
      display: flex;
      .recommend-app-logo {
        width: 50px;
        height: 50px;
        border-radius: 0.5rem;
      }
      .recommend-app-content {
        width: 100%;
        .Polaris-Text--root {
          line-height: 1.25rem;
        }
        .Polaris-Icon {
          svg {
            stroke: yellow;
            opacity: 0.6;
          }
          margin: 0;
          margin-right: 0.25rem;
        }
        margin-left: 0.5rem;
      }
    }
    .recommend-app-banner {
      margin-top: 1rem;
      width: 100%;
    }
    .recommend-app-btn {
      padding: 16px 54px;
    }
    .Polaris-ShadowBevel {
      height: 100%;
      .Polaris-Box {
        display: flex;
      }
    }
  }
  .recommend-apps-controller {
    display: flex;
    cursor: pointer;
  }
  .dashboard-support-right-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
    }
  }
  .overview-title {
    display: flex;
    justify-content: space-between;
  }
  .overview-container {
    background-color: ${COLORS.backgroundColor};
    padding: 1rem;
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.5rem;
    }
    &:hover {
      cursor: pointer;
      color: ${COLORS.primary};
    }
  }
  .disable {
    pointer-events: none;
    opacity: 0.8;
  }
  .ml-8 {
    margin-left: 0.5rem;
  }
  .mt-8 {
    margin-top: 0.5rem;
  }
  .pl-16 {
    padding-left: 1rem;
  }
  .pd-16 {
    padding: 1rem;
  }
  .synctrack-banner {
    position: relative;
    height: 216px;
    .Polaris-Icon__Svg {
      fill: #ffff;
    }
    .synctrack-banner-img {
      width: calc(100% - 0.5rem);
      height: 216px;
      border-radius: 0.5rem;
      cursor: pointer;

      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        width: 100%;
        height: auto;
        padding-bottom: 1rem;
      }
    }
    .synctrack-banner-dismiss {
      position: absolute;
      right: 0.5rem;
      top: 0rem;
      cursor: pointer;
      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        right: 0.25rem;
      }
    }

    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      height: auto;
    }
  }
  .bfs-badge {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    font-weight: 500;
    padding: 4px 8px 4px 8px;
    background-color: rgb(224, 240, 255);
    border-radius: 4px;
    gap: 4px;
  }
`;
