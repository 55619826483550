import { COLORS } from '@/constants/colors';
import styled from 'styled-components';

export const CourierMappingStyled = styled.div`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .Polaris-FormLayout {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .Polaris-ButtonGroup {
    width: 100%;
    justify-content: center;
  }
  .Polaris-FormLayout__Item {
    width: fit-content;
  }
  .Polaris-InlineGrid {
    padding: 0 0.5rem;
  }
  .courier-mapping-content {
    .Polaris-InlineGrid {
      border-bottom: 1px solid ${COLORS.backgroundColor};
      padding: 0.5rem;
      border-radius: 0.5rem;
      .Polaris-Text--bodySm {
        line-height: 29px;
      }
      .btn-group {
        display: none;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        background-color: ${COLORS.bgHover};
        .btn-group {
          display: flex;
        }
      }
    }
  }
  .mt-16 {
    margin-top: 16px;
  }
  @media (max-width: 1425px) {
    .Polaris-ButtonGroup {
      justify-content: flex-end;
    }
  }
  @media (max-width: 740px) {
    font-size: 12px !important;
  }
`;
