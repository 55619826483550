/* eslint-disable jsx-a11y/iframe-has-title */
import RegularText from '@/components/RegularText';
import CustomCard from '@/components/card';
import CustomDatePicker from '@/components/datePicker';
import SettingToggle from '@/components/settingToggle';
import { LINK } from '@/constants/link';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import commonSettingSlice, { isVisibleBannerActiveDisputeSelector } from '@/redux/slice/commonSetting.slice';
import { disputeTableSelector, titleBtnDatePickerSelector } from '@/redux/slice/dispute.slice';
import masterDataSlice, { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { Button, Card, Link, SkeletonBodyText, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analysis from './components/Analysis';
import ConnectPaypal from './components/ConnectPaypal';
import DisputeTable from './components/TableDispute';
import { DisputeStyled } from './styled';
import DisputeReportChart from './components/DisputeReportChart';
import InformationBanner from '@/components/InformationBanner';
const Dispute = () => {
  const [isChangeDisputeReport, setIsChangeDisputeReport] = useState(false);
  const [updateDisputeReport, updateDisputeReportStatus] = apiCaller.useDisputeReportMutation();
  const scope = useScope();
  const disputeTable = useSelector(disputeTableSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const dispatch = useDispatch();
  const isVisibleBannerActiveDispute = useSelector(isVisibleBannerActiveDisputeSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isLoading = useSelector(loadingSelector);
  const [mutationActiveDispute, activeDisputeStatus] = apiCaller.useActiveDisputeMutation();
  const handleTurnOnDispute = () => {
    if (dataSettings) {
      mutationActiveDispute({
        status: dataSettings.useDisputeManagement ? 0 : 1,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    }
  };
  return (
    <DisputeStyled>
      <InformationBanner
        isVisible={isVisibleBannerActiveDispute && dataSettings?.useDisputeManagement === 0}
        title="Note"
        onDismiss={() => {
          dispatch(slice.commonSettingSlice.actions.handleVisibleBannerActiveDispute(false));
        }}
      >
        For previous users of the old Dispute feature, please turn this setting on to continue managing disputes and automatically
        get new disputes from PayPal
      </InformationBanner>
      {isLoading ? (
        <div>
          <Card>
            <SkeletonBodyText />
          </Card>
          <div className="mt-16">
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
          <div className="mt-16">
            <Card>
              <SkeletonBodyText lines={6} />
            </Card>
          </div>
          <div className="mt-16">
            <Card>
              <SkeletonBodyText lines={4} />
            </Card>
          </div>
          <div className="mt-16">
            <Card>
              <SkeletonBodyText lines={10} />
            </Card>
          </div>
        </div>
      ) : (
        <SettingToggle
          disableStatus={scope.isViewOnly}
          settingToggleProps={{
            action: {
              onAction: handleTurnOnDispute,
              content: dataSettings?.useDisputeManagement ? 'Turn off' : 'Turn on',
              loading: activeDisputeStatus.isLoading,
            },
            enabled: !!dataSettings?.useDisputeManagement,
          }}
          title="Dispute management"
        >
          <RegularText>
            When turning on and connect PayPal account, disputes data will be automatically updated from PayPal to Synctrack.
            <Link external url={LINK.getDisputeAdmin} removeUnderline>
              View dispute management instruction
            </Link>
          </RegularText>
        </SettingToggle>
      )}

      <div className="mt-16"></div>

      {dataSettings?.useDisputeManagement ? (
        <div className="mt-16">
          <CustomCard title="Connected PayPal account">
            <ConnectPaypal />
          </CustomCard>
        </div>
      ) : null}

      {dataSettings?.useDisputeManagement ? (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem 0rem' }}>
            <CustomDatePicker
              setTitleBtn={(title: string) => {
                dispatch(slice.disputePageSlice.actions.handleTitleBtnDatePicker(title));
              }}
              titleBtn={titleBtnDatePicker}
              onSave={(fromDate: Date, toDate: Date) => {
                dispatch(
                  slice.disputePageSlice.actions.handleDisputeTable({
                    ...disputeTable,
                    fromDate,
                    toDate,
                  }),
                );
              }}
              end={disputeTable.toDate}
              start={disputeTable.fromDate}
            />
          </div>

          <div className="mt-16">
            <SettingToggle
              settingToggleProps={{
                enabled: dataSettings.useDisputeEmailReport,
                action: {
                  content: dataSettings.useDisputeEmailReport ? 'Turn off' : 'Turn on',
                  onAction: () => {
                    if (!isChangeDisputeReport) {
                      setIsChangeDisputeReport(true);
                    }
                    dispatch(
                      masterDataSlice.actions.handleDataSettings({
                        ...dataSettings,
                        useDisputeEmailReport: !dataSettings.useDisputeEmailReport,
                      }),
                    );
                  },
                },
              }}
              title="Report dispute information via Email "
              disableStatus={isLoading}
            >
              <div>
                <RegularText>
                  When you turn on this feature, Synctrack will send a monthly email summarizing the dispute status on your PayPal
                  account
                </RegularText>
                <div className="mt-16">
                  <TextField
                    autoComplete="off"
                    label="Email to receive reports:"
                    placeholder="example@gmail.com"
                    value={dataSettings.disputeReportEmail}
                    onChange={(value) => {
                      if (!isChangeDisputeReport) {
                        setIsChangeDisputeReport(true);
                      }
                      dispatch(
                        masterDataSlice.actions.handleDataSettings({
                          ...dataSettings,
                          disputeReportEmail: value,
                        }),
                      );
                    }}
                  />
                </div>
                <div className="mt-8">
                  <Button
                    variant="primary"
                    disabled={!isChangeDisputeReport}
                    loading={updateDisputeReportStatus.isLoading}
                    onClick={() => {
                      updateDisputeReport({
                        disputeReportEmail: dataSettings.disputeReportEmail,
                        useDisputeEmailReport: dataSettings.useDisputeEmailReport,
                      }).then((res) => {
                        setIsChangeDisputeReport(false);
                        dispatch(commonSettingSlice.actions.handleToast(handleToastMutation(res)));
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </SettingToggle>
          </div>

          <div className="mt-16">
            <CustomCard title="Dispute statistics">
              <div className="analytics-container">
                <Analysis fromDate={disputeTable.fromDate} toDate={disputeTable.toDate} />
              </div>
            </CustomCard>

            <div className="mt-16 dispute-table-container">
              <DisputeTable fromDate={disputeTable.fromDate} toDate={disputeTable.toDate} />
            </div>
          </div>
          <DisputeReportChart />
        </div>
      ) : null}
    </DisputeStyled>
  );
};

export default memo(Dispute);
