import { COLORS } from '@/constants/colors';
import styled from 'styled-components';

export const WelcomeStyled = styled.div`
  padding-top: 2rem;
  .welcome-underline {
    text-decoration: underline;
    cursor: pointer;
  }
  .welcome-process-container {
    height: 0.75rem;
    background-color: ${COLORS.backgroundColor};
    border-radius: 0.375rem;
    overflow: hidden;
  }
  .welcome-process {
    background-color: #1a1a1a;
    height: 100%;
  }
  .banner-template-right {
    padding-left: 2rem;
  }
  .Polaris-ShadowBevel {
    box-shadow: none;
    &::before {
      box-shadow: none;
    }
  }
`;
