import StepTwoImg from '@/assets/welcome/welcome_2.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import PaypalButton from '@/components/paypalButton';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { Button } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
const StepTwo = () => {
  const dispatch = useDispatch();
  const [updateWelcomeStep, stepLoading] = apiCaller.useUpdateWelcomStepMutation();
  const handleNext = () => {
    updateWelcomeStep({ step: 3 }).then((res) => {
      dispatch(slice.WelcomeSlice.actions.handleStep(3));
    });
  };

  return (
    <BannerTemplate src={StepTwoImg}>
      <BoldText>
        Connect your PayPal account <br />
        Synctrack uses PayPal's secure API
      </BoldText>
      <div className="mt-16">
        <RegularText>Synctrack only asks for minimal permissions from your account to sync tracking info</RegularText>
      </div>
      <div className="mt-16 d-flex">
        <Button loading={stepLoading.isLoading} onClick={handleNext}>
          Skip & Next step
        </Button>
        <div className="ml-8">
          <PaypalButton title="Connect PayPal account" />
        </div>
      </div>
    </BannerTemplate>
  );
};

export default memo(StepTwo);
