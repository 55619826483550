import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const PaypalSettingStyled = styled.div`
  display: flex;
  .paypal-account-layout {
    padding: 10px 15px;
    border: 2px solid #eee;
    display: flex;
    justify-content: space-between;
  }
  .paypal-account-container {
    .btn-group {
      max-height: 28px;
      display: none;
    }
    &:hover {
      .btn-group {
        display: flex;
      }
    }
  }

  .mb-16 {
    margin-bottom: 1rem;
  }
  .mt-16 {
    margin-top: 1rem;
  }
  .mt-8 {
    margin-top: 0.5rem;
  }
  .Polaris-Heading {
    font-weight: bold;
  }
  .badge-container {
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Polaris-Text--headingMd {
    font-weight: bold;
  }
  .paypal-video {
    .Polaris-Icon {
      display: none;
    }
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-20 {
    margin-top: 20px;
  }

  @media screen and (max-width: ${BREAKPOINT.MD}px) {
    display: block;
    .paypal-settings-left {
      width: 100%;
    }
    .paypal-setting-right {
      width: 100%;
      margin-top: 1.25rem;
      padding: 0;
    }
  }
`;
