import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { checkShowErrorInline, convertAmount, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { IDispute } from '@/types/components';
import { Modal, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
const Claim = ({ ...props }: IDispute.IDisputeClaim): JSX.Element => {
  const [acceptClaim, acceptClaimStatus] = apiCaller.useAcceptClaimDisputeMutation();
  const [state, setState] = useState({
    value: '',
    error: '',
  });
  const handleAcceptClaim = () => {
    acceptClaim({
      disputeId: props.disputeId,
      note: state.value,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setState({
          ...state,
          error: condition.msg,
        });
      } else {
        handleToastMutation(res);
        props.onClose();
        setState({
          value: '',
          error: '',
        });
      }
    });
  };
  return (
    <Modal
      open={props.isOpenModal}
      onClose={() => {
        props.onClose();
        setState({
          value: '',
          error: '',
        });
      }}
      title="Accept claim"
      primaryAction={{
        content: 'Accept claim',
        onAction: handleAcceptClaim,
        disabled: !state.value,
        loading: acceptClaimStatus.isLoading,
      }}
    >
      <Modal.Section>
        <RegularText>
          When you accept liability for a claim, the dispute closes in the customer’s favor. A full refund will be refunded to
          buyer and the case will be closed. The item will be retained by the buyer.
        </RegularText>
        <div className="mt-16 d-flex">
          <RegularText>Refund amount:&nbsp;</RegularText>
          <BoldText>{convertAmount(props.disputeAmount, props.disputeCurrencyCode)}</BoldText>
        </div>
        <div className="mt-16">
          <TextField
            label="Note"
            value={state.value}
            placeholder="Should include: (i) notes for acceptance or (ii) reason for acceptance or (iii) The ID of the invoice for the refund"
            onChange={(value) =>
              setState({
                value,
                error: '',
              })
            }
            onBlur={() => {
              if (!state.value) {
                setState({
                  ...state,
                  error: 'Note is required',
                });
              }
            }}
            error={state.error}
            multiline={5}
            autoComplete="off"
          />
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default memo(Claim);
