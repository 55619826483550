import { OrderStatus, Payment } from '@/constants/enum';
import slice from '@/redux/slice';
import { listStoreSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { ChoiceList } from '@shopify/polaris';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const optionsPerPage = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' },
];
const optionsPayment = [
  { label: 'Paypal', value: Payment.Paypal },
  { label: 'Stripe', value: Payment.Stripe },
  { label: 'Paypal & stripe', value: Payment.PaypalAndStripe },
  { label: 'Other', value: Payment.Other },
  { label: 'All', value: Payment.All },
];

const optionsStatus = [
  { label: 'All', value: OrderStatus.All },
  { label: 'Synced', value: OrderStatus.Synced },
  { label: 'In queue', value: OrderStatus.InQueue },
  { label: 'Over quota', value: OrderStatus.OverQuota },
  { label: 'All error', value: OrderStatus.AllError },
  {
    label: 'No or invalid account',
    value: OrderStatus.AccountInvalid,
  },
  {
    label: 'No tracking number',
    value: OrderStatus.NoTrackingNumber,
  },
  {
    label: 'No transaction ID',
    value: OrderStatus.NoTransactionId,
  },
  {
    label: 'Missing PayPal account',
    value: OrderStatus.MissingPaypalAccount,
  },
  {
    label: 'Invalid tracking number',
    value: OrderStatus.TrackingNumberInvalid,
  },
  {
    label: 'Store pickup',
    value: OrderStatus.StorePickup,
  },
];

const getLabelByValue = (options: Array<{ label: string; value: string }>, value: string) =>
  options.find((option) => option.value === value)?.label;

const Filters = () => {
  const listStore = useSelector(listStoreSelector);
  const getOrderTable = useSelector(orderTableOrderPageSelector);
  const dispatch = useDispatch();
  const listStoreOptions = useMemo(() => {
    const result = listStore?.map((item) => {
      return {
        label: item.shop,
        value: item.shop,
      };
    });
    return result;
  }, [listStore]);
  const handleOrderTable = (key: keyof typeof getOrderTable) => (value: any) => {
    if (value.length !== 0) {
      dispatch(
        slice.orderPageSlice.actions.handleFiltersOrderTable({
          ...getOrderTable,
          [key]: value,
          currentPage: 1,
        }),
      );
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const filters = [
    {
      key: 'storeSelected',
      label: 'Store',
      filter: (
        <ChoiceList
          allowMultiple
          title="Store"
          titleHidden
          choices={listStoreOptions ? [{ label: 'All', value: 'all' }, ...listStoreOptions] : []}
          selected={getOrderTable.storeSelected}
          onChange={handleOrderTable('storeSelected')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'paymentSelected',
      label: 'Payment',
      filter: (
        <ChoiceList
          title="Payment"
          titleHidden
          choices={optionsPayment}
          selected={getOrderTable.paymentSelected}
          onChange={handleOrderTable('paymentSelected')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'statusSelected',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Payment"
          titleHidden
          choices={optionsStatus}
          selected={getOrderTable.statusSelected}
          onChange={handleOrderTable('statusSelected')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={optionsPerPage}
          selected={getOrderTable.perPage}
          onChange={handleOrderTable('perPage')}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () => handleOrderTable('storeSelected')(['all']),
      key: 'storeSelected',
      label:
        'Store: ' +
        (getOrderTable.storeSelected.findIndex((item) => item === 'all') === -1 ? getOrderTable.storeSelected.toString() : 'all'),
    },
    {
      onRemove: () => handleOrderTable('paymentSelected')(['all']),
      key: 'paymentSelected',
      label: 'Payment: ' + getLabelByValue(optionsPayment, getOrderTable.paymentSelected[0]),
    },
    {
      onRemove: () => handleOrderTable('statusSelected')(['all']),
      key: 'statusSelected',
      label: 'Status: ' + getLabelByValue(optionsStatus, getOrderTable.statusSelected[0]),
    },
    {
      onRemove: () => handleOrderTable('perPage')(['10']),
      key: 'perPage',
      label: 'Record per page: ' + getLabelByValue(optionsPerPage, getOrderTable.perPage[0]),
    },
  ];

  return {
    appliedFilters,
    filters,
  };
};

export default Filters;
