import config from '@/config';
import { Ga4Event, Subscription } from '@/constants/enum';
import { apiCaller } from '@/redux/query';

export const useGa4 = () => {
  const [fetchGA] = apiCaller.useFetchGAMutation();

  const handleGa4 = (
    event: Ga4Event,
    options?: {
      plan?: string;
      type?: Subscription;
    },
  ) => {
    fetchGA({
      urlParams: config.urlParams,
      shop: config.shop,
      event,
      plan: options?.plan,
      type: options?.type,
    });
  };
  return {
    handleGa4,
  };
};
