import DisputeImg from '@/assets/dashboard/dispute.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import CustomDatePicker from '@/components/datePicker';
import Layout from '@/components/layout';
import config from '@/config';
import { OrderStatus, Payment } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { listStoreSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector, titleBtnDatePickerSelector } from '@/redux/slice/orderPage.slice';
import { Button, Card, Select, SkeletonBodyText } from '@shopify/polaris';
import {
  AddNoteMajor,
  BankMajor,
  ChecklistMajor,
  CircleAlertMajor,
  CreditCardMajor,
  CreditCardSecureMajor,
  DiamondAlertMinor,
  FraudProtectMajor,
  FraudProtectPendingMajor,
  FraudProtectUnprotectedMajor,
  FulfillmentFulfilledMajor,
  PaperCheckMajor,
  PaymentsMajor,
  PlanMajor,
  RiskMajor,
  TransactionMajor,
} from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from './components/Chart';
import AnalysticError from './components/Error';
import AnalysticOrders from './components/Orders';
import { AnalyticsStyled } from './styled';
import QuickHelp from './components/QuickHelp';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const Analytics = () => {
  const isSkip = useSelector(isSkipApiSelector);

  const [isOpenQuickHelp, setIsOpenQuickHelp] = useState(false);
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const listStore = useSelector(listStoreSelector) || [];
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const orderTable = useSelector(orderTableOrderPageSelector);
  const onSaveDatePicker = (fromDate: Date, toDate: Date) => {
    dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable({ ...orderTable, fromDate, toDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.orderPageSlice.actions.handleTitleBtnDatePicker(title));
  };

  const getdisputeTemplate = () => {
    const sendText = 'I want to get Dispute template!';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };

  const listStoreOptions = listStore.map((item) => {
    return {
      label: item.shop,
      value: item.shop,
    };
  });

  const { data, isFetching } = apiCaller.useFetchDataProcessedQuery(
    {
      fromDate: dateToTimeStamp(orderTable.fromDate),
      toDate: dateToTimeStamp(orderTable.toDate),
      store: orderTable.storeSelected.includes('all') ? undefined : orderTable.storeSelected[0],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      shop: config.shop,
    },
    { skip: isSkip },
  );

  const dataProcessed = useMemo(() => {
    if (data) {
      const orders = {
        paypal: {
          fullfilledOrders: {
            value: data.allOrderFulfilled,
            payment: Payment.All,
            status: OrderStatus.All,
            icon: FulfillmentFulfilledMajor,
            title: 'Fullfilled orders',
          },
          'paypal &StripeOrders': {
            value: data.orderPaypalStripe,
            payment: Payment.PaypalAndStripe,
            status: OrderStatus.All,
            title: 'PayPal & Stripe orders',
            icon: CreditCardMajor,
          },
          otherGateways: {
            value: data.orderPaidByOther,
            payment: Payment.Other,
            status: OrderStatus.All,
            icon: PaymentsMajor,
            title: 'Other gateways',
          },
          paypalOrders: {
            value: data.orderPaidPaypal,
            payment: Payment.Paypal,
            status: OrderStatus.All,
            title: 'PayPal orders',
            icon: CreditCardSecureMajor,
          },
          paypalSynced: {
            value: data.orderPaypalSynced,
            payment: Payment.Paypal,
            status: OrderStatus.Synced,
            title: 'PayPal synced',
            icon: FraudProtectMajor,
          },
          paypalSyncError: {
            value: data.orderPaypalErr,
            payment: Payment.Paypal,
            status: OrderStatus.AllError,
            title: 'PayPal sync error',
            icon: FraudProtectUnprotectedMajor,
          },
        },
        stripe: {
          stripeOrders: {
            value: data.orderStripe,
            payment: Payment.Stripe,
            status: OrderStatus.All,
            icon: BankMajor,
            title: 'Stripe orders',
          },
          stripeSynced: {
            value: data.orderStripeSynced,
            payment: Payment.Stripe,
            status: OrderStatus.Synced,
            icon: ChecklistMajor,
            title: 'Stripe synced',
          },
          stripeSyncError: {
            value: data.orderStripeErr,
            payment: Payment.Stripe,
            status: OrderStatus.AllError,
            icon: RiskMajor,
            title: 'Stripe sync error',
          },
        },
      };
      const errors = {
        inQueue: {
          value: data.orderPending,
          payment: Payment.All,
          status: OrderStatus.InQueue,
          icon: FraudProtectPendingMajor,
          tooltip: 'Orders are waiting to be synchronized',
          title: 'In queue',
        },
        noTrackingNumber: {
          value: data.orderNoTracking,
          payment: Payment.All,
          status: OrderStatus.NoTrackingNumber,
          icon: AddNoteMajor,
          tooltip: 'The order has not been fulfilled or there is no tracking number on Shopify',
          title: 'No tracking number',
        },
        noTransactionId: {
          value: data.orderNoTransactionId,
          payment: Payment.All,
          status: OrderStatus.NoTransactionId,
          tooltip: 'There is no transaction information on Shopify or the order has been transferred to another payment gateway',
          icon: TransactionMajor,
          title: 'No transaction ID',
        },
        overQuota: {
          value: data.orderOverPlan,
          payment: Payment.All,
          status: OrderStatus.OverQuota,
          icon: PlanMajor,
          tooltip: 'Excess limit under subscription Plan',
          title: 'Over quota',
        },
        noOrInvalidAccount: {
          value: data.orderUnconfirmAccount,
          payment: Payment.All,
          status: OrderStatus.AccountInvalid,
          icon: CircleAlertMajor,
          tooltip: 'PayPal or Stripe account is not connected',
          title: 'No or invalid account',
        },
        missingPaypalAccount: {
          value: data.orderInvalidAccount,
          payment: Payment.All,
          status: OrderStatus.MissingPaypalAccount,
          icon: PaperCheckMajor,
          tooltip: 'You need to connect all the PayPal accounts that receive money from Shopify orders',
          title: 'Missing PayPal account',
        },
      };
      return {
        orders,
        errors,
        syncError: data.totalErr,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handleCloseQuickHelp = useCallback(() => {
    setIsOpenQuickHelp(false);
  }, []);

  return (
    <AnalyticsStyled>
      <Layout
        title="Analytics"
        secondaryActions={
          <div className="control-group">
            <div>
              {listStore.length > 1 ? (
                <Select
                  label="Store: "
                  labelInline
                  options={[{ value: 'all', label: 'All' }, ...listStoreOptions]}
                  onChange={(value) => {
                    dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable({ ...orderTable, storeSelected: [value] }));
                  }}
                  value={orderTable.storeSelected[0]}
                />
              ) : null}
            </div>
            <div className="ml-4">
              <CustomDatePicker
                setTitleBtn={onSaveTitleBtnDatePicker}
                titleBtn={titleBtnDatePicker}
                start={orderTable.fromDate}
                end={orderTable.toDate}
                onSave={onSaveDatePicker}
              />
            </div>
          </div>
        }
      >
        <div className="mt-16">
          <Card>
            <div className="analytics-title">
              <BoldText>Orders</BoldText>
            </div>
            <div className="mt-16 analytics-content">
              {isFetching ? <SkeletonBodyText lines={10} /> : dataProcessed && <AnalysticOrders {...dataProcessed.orders} />}
            </div>
          </Card>
        </div>
        {dataProcessed && dataProcessed.syncError ? (
          <div className="mt-16">
            <Card>
              <div className="analytics-title">
                <BoldText>Sync error reasons</BoldText>
                <div className="notification-container" ref={ref}>
                  <Button
                    onClick={() => {
                      setIsOpenQuickHelp(true);
                    }}
                    icon={DiamondAlertMinor}
                  >
                    Quick help
                  </Button>
                </div>
              </div>
              <div className="mt-16 analytics-content">
                {isFetching ? <SkeletonBodyText /> : dataProcessed && <AnalysticError {...dataProcessed.errors} />}
              </div>
            </Card>
          </div>
        ) : null}
        <div className="mt-16">
          <Card>
            <BoldText>Orders statistics</BoldText>
            <Chart
              store={orderTable.storeSelected[0]}
              fromDate={dateToTimeStamp(orderTable.fromDate)}
              toDate={dateToTimeStamp(orderTable.toDate)}
            />
          </Card>
        </div>
        <div className="mt-16">
          <BannerTemplate src={DisputeImg}>
            <BoldText>We are giving you a list of DISPUTE TEMPLATES</BoldText>
            <div className="mt-16">
              <RegularText>
                They are the emails which help you communicate with your customers or PayPal to win when a dispute has been raised
              </RegularText>
            </div>

            <div className="mt-16">
              <CustomButton onClick={getdisputeTemplate}>Contact to get dispute template</CustomButton>
            </div>
          </BannerTemplate>
          <QuickHelp isOpen={isOpenQuickHelp} handleClose={handleCloseQuickHelp} dataSummary={data} />
        </div>
      </Layout>
    </AnalyticsStyled>
  );
};

export default memo(Analytics);
