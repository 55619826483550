import styled from 'styled-components';

export const DisputeDetailStyled = styled.div`
  margin-top: 16px;
  .custom-columns {
    .Polaris-Divider {
      margin: 16px 0px;
    }
  }
  .Polaris-Columns {
    grid-row-gap: 0px;
  }
  .custom-card-content {
    overflow-x: auto;
  }
  .bg-buyer {
    background-color: rgba(235, 249, 252, 1);
  }
  .Polaris-DropZone__Container {
    height: fit-content;
    .Polaris-Text--root {
      white-space: pre;
    }
  }
  .response-btn-group {
    display: flex;
    justify-content: flex-end;
  }
  .Polaris-LegacyStack {
    position: relative;
  }
  .conversation-table {
    min-width: 1200px;
  }
  .remove-icon {
    position: absolute;
    right: -0px;
    top: 16px;
    z-index: 2;
    background-color: white;
    cursor: pointer;
  }
  .pl-16 {
    padding-left: 16px;
  }
  .mb-16 {
  }
  .pl-24 {
    padding-left: 24px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .h-24 {
    height: 24px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .bold {
    font-weight: bold;
  }
  .j-c-s {
    justify-content: space-between;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .pd-16 {
    padding: 16px;
  }
  .br-8 {
    border-radius: 8px;
  }
`;
