import StepFiveImg from '@/assets/welcome/welcome_5.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { Button } from '@shopify/polaris';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
const StepFive = () => {
  const navigate = useNavigate();
  const [updateWelcomeStatus, statusLoading] = apiCaller.useUpdateWelcomStatusMutation();

  const handleSkip = () => {
    updateWelcomeStatus().then((res) => {
      navigate(PATH.DEFAULT);
    });
  };
  return (
    <BannerTemplate src={StepFiveImg}>
      <BoldText>
        All is done <br />
        Do you have more than 30 orders to sync monthly?
      </BoldText>
      <div className="mt-16">
        <RegularText>
          Pick a plan to get all your orders synced. Otherwise, only 30 orders will be synced for Free plan
        </RegularText>
      </div>
      <div className="mt-16 d-flex">
        <Button loading={statusLoading.isLoading} onClick={handleSkip}>
          Skip & Finish
        </Button>
        <div
          className="ml-8"
          onClick={() => {
            navigate(PATH.ACCOUNT);
          }}
        >
          <Button variant="primary">Pick A Plan</Button>
        </div>
      </div>
    </BannerTemplate>
  );
};

export default memo(StepFive);
