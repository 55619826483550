import images from '@/assets/images.';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import CustomDatePicker from '@/components/datePicker';
import OldOrderbanner from '@/components/oldOrderbanner';
import { Ga4Event, Plan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { capitalizeFirstLetter } from '@/helpers';
import { useGa4 } from '@/hooks/useGa4';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import bannerSlice, { CrossSellSelector } from '@/redux/slice/banner.slice';
import { isVisibleNewInterfaceSelector, isVisibleRecommendAppsSelector } from '@/redux/slice/commonSetting.slice';
import { dataSettingsSelector, listPaypalSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector, titleBtnDatePickerSelector } from '@/redux/slice/orderPage.slice';
import { Banner, Button, Card, Divider, Icon, InlineGrid, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import {
  CreditCardMajor,
  HorizontalDotsMinor,
  MobileCancelMajor,
  NotificationMajor,
  OnlineStoreMajor,
  OrdersMajor,
  QuestionMarkInverseMinor,
} from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmailConsentModal from './components/EmailConsent';
import Overview from './components/Overview';
import ReviewStep from './components/ReviewStep';
import RecommendedApps from './components/recommendedApps';
import { DashBoardStyled } from './styled';
interface ActionItemProps {
  content: React.ReactNode;
  prefix: React.ReactNode;
  isHiddenDivider?: boolean;
  disable?: boolean;
  onAction?: () => void;
}

const ActionItems = ({ content, prefix, isHiddenDivider, disable, onAction }: ActionItemProps) => {
  return (
    <div style={{ cursor: onAction ? 'pointer' : 'auto' }} onClick={onAction} className={disable ? 'disable' : ''}>
      <div className="action-item-container">
        <div className="d-flex">
          <span>{prefix}</span> <div className="ml-8">{content}</div>
        </div>
      </div>
      {!isHiddenDivider && <Divider borderColor="border" />}
    </div>
  );
};

const DashBoard = () => {
  const [bannerClick] = apiCaller.useBannerClickMutation();
  const dispatch = useDispatch();
  const crossSell = useSelector(CrossSellSelector);
  const { handleGa4 } = useGa4();
  const dataSettings = useSelector(dataSettingsSelector);
  const listPayPalAccount = useSelector(listPaypalSelector);
  const isLoading = useSelector(loadingSelector);
  const isVisibleRecommendApps = useSelector(isVisibleRecommendAppsSelector);
  const isVisibleNewInterface = useSelector(isVisibleNewInterfaceSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const orderTable = useSelector(orderTableOrderPageSelector);
  const onSaveDatePicker = (fromDate: Date, toDate: Date) => {
    dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable({ ...orderTable, fromDate, toDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.orderPageSlice.actions.handleTitleBtnDatePicker(title));
  };

  const navigate = useNavigate();
  const usedRatio = useMemo(() => {
    let ratio = 0;
    if (dataSettings) {
      if (dataSettings.plan === Plan.UNLIMITED) {
        return 0;
      } else {
        ratio = Number(((dataSettings.usage * 100) / Number(dataSettings.quota)).toFixed(0));
      }
    } else {
      ratio = 0;
    }
    return ratio;
  }, [dataSettings]);

  const listAction: Array<ActionItemProps> = useMemo(() => {
    const result = [
      {
        content: (
          <Text variant="bodyMd" as="p">
            Your current plan is <b>{capitalizeFirstLetter(dataSettings?.plan || '')}</b>
          </Text>
        ),
        prefix: <Icon source={OnlineStoreMajor} tone="subdued" />,
      },

      {
        content: (
          <Text variant="bodyMd" as="p">
            You have used{' '}
            <b>
              {dataSettings?.usage} / {dataSettings?.quota} orders
            </b>
          </Text>
        ),
        prefix: <Icon source={OrdersMajor} tone="subdued" />,
      },

      {
        content: (
          <Text variant="bodyMd" as="p">
            PayPal account:{' '}
            <b>
              {listPayPalAccount && listPayPalAccount.length > 0
                ? `${listPayPalAccount.length} ${listPayPalAccount.length === 1 ? 'account' : 'accounts'} connected`
                : 'need to connect'}
            </b>
          </Text>
        ),
        prefix: <Icon source={CreditCardMajor} tone="subdued" />,
      },

      {
        content: (
          <Text variant="bodyMd" as="p">
            Get <b>help and support</b>
          </Text>
        ),
        prefix: <Icon source={QuestionMarkInverseMinor} tone="subdued" />,
        onAction: () => {
          try {
            $crisp.push(['do', 'chat:open']);
          } catch (error) {
            console.log(error);
          }
        },
      },
    ];
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSettings?.plan, dataSettings?.quota, dataSettings?.usage]);

  const isNewUser = useMemo(() => {
    const now = new Date();
    const date = dayjs('2023-10-18').unix();
    const lastDate = dayjs('2023-11-1').unix();
    if (now.getTime() / 1000 > lastDate) return true;
    if (dataSettings) {
      const onboarDate = dataSettings.installedAt;
      return onboarDate > date;
    }
    return false;
  }, [dataSettings]);

  return (
    <DashBoardStyled>
      <EmailConsentModal />
      <div className="mt-16">
        {!isNewUser && isVisibleNewInterface ? (
          <Banner
            icon={NotificationMajor}
            title="Discover the Synctrack All-New, User-Friendly Interface"
            tone="info"
            onDismiss={() => {
              dispatch(slice.commonSettingSlice.actions.handleVisibleNewInterface(false));
            }}
          >
            <RegularText>
              We're excited to share that our app just got a makeover! Say hello to our beautifully redesigned interface, crafted
              to provide you with an even smoother and more delightful user experience.
            </RegularText>
          </Banner>
        ) : null}
      </div>
      <div className="mt-16 dashboard-container">
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
          <div className="dashboard-img">
            <img src={images.dashboard} alt="_blank" />
          </div>
          <Card>
            {isLoading ? (
              <SkeletonBodyText lines={6} />
            ) : (
              listAction.map((item, index) => {
                return <ActionItems {...item} isHiddenDivider={listAction.length === index + 1} key={index} />;
              })
            )}
          </Card>
        </InlineGrid>
      </div>

      <div className="mt-16">
        {usedRatio > 80 ? (
          <Banner
            tone={usedRatio < 100 ? 'warning' : 'critical'}
            title={
              usedRatio < 100
                ? `You have used ${dataSettings?.usage}/${
                    dataSettings?.quota
                  } synced orders this month for ${capitalizeFirstLetter(dataSettings?.plan || '')} plan`
                : `You have used up the quota of the ${capitalizeFirstLetter(dataSettings?.plan || '')} plan`
            }
          >
            <RegularText>
              {usedRatio < 100
                ? 'Incoming orders may not be synced when the quota is used up. You can upgrade to a higher package to ensure all orders will be synced'
                : ` Please upgrade to a higher plan so that your next orders will be synced`}
            </RegularText>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <CustomButton onClick={() => navigate(PATH.ACCOUNT)}>Upgrade Now</CustomButton>
            </div>
          </Banner>
        ) : null}
      </div>

      <div className="mt-16">
        <ReviewStep />
      </div>

      <div className="mt-16">
        <Card>
          <div className="overview-title">
            <BoldText>Summary</BoldText>
            <CustomDatePicker
              setTitleBtn={onSaveTitleBtnDatePicker}
              titleBtn={titleBtnDatePicker}
              start={orderTable.fromDate}
              end={orderTable.toDate}
              onSave={onSaveDatePicker}
            />
          </div>
          <div className="mt-16">
            <Overview fromDate={orderTable.fromDate} toDate={orderTable.toDate} />
          </div>
        </Card>
      </div>

      <OldOrderbanner />
      {/* <div className="mt-16"> */}
      {/* <BannerTemplate src={images.blockify_analytics}>
          <BoldText>Make it easy to control store visitors and protect your store.</BoldText>
          <div className="mt-16">
            <RegularText>
              Blockify allows you to easily block visitors based on location and IP, reducing fraud and blocking bots. Analyze
              traffic, identify risks, and enhance store protection with Visitor Analytics. Say goodbye to scammers - choose
              Blockify for high security and peace of mind!
            </RegularText>
          </div>

          <div className="mt-16">
            <Button
              variant="primary"
              onClick={() => {
                bannerClick({ bannerType: 'ads_blockify' });
                window.open(
                  'https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=synctrack&utm_medium=homeinapp',
                  '_blank',
                );
              }}
            >
              Try it now
            </Button>
          </div>
        </BannerTemplate> */}
      {/* </div> */}

      <div className="mt-16">
        <BannerTemplate src={images.checkout_rules_dashboard}>
          <BoldText>Prevent failed deliveries & Tailor shipping options that your customers see.</BoldText>
          <div className="mt-16">
            <RegularText>
              Blockify Checkout Rules effortlessly validate address & checkout info to fix potential shipping issues. Hide or
              reorder shipping/payment methods to for a smooth & cost-effective experience . Get the all-in-one solution fully
              FREE today!
            </RegularText>
          </div>

          <div className="mt-16">
            <Button
              variant="primary"
              onClick={() => {
                if (!crossSell.isClickCheckoutRules) {
                  handleGa4(Ga4Event.CheckoutRulesClick);
                  dispatch(
                    bannerSlice.actions.handleBanner({
                      key: 'isClickCheckoutRules',
                      value: true,
                    }),
                  );
                }
                bannerClick({ bannerType: 'pixel_new' });
                window.open(
                  'https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=synctrack&utm_medium=homeinapp',
                  '_blank',
                );
              }}
            >
              Install FREE app
            </Button>
          </div>
        </BannerTemplate>
      </div>

      <div className="mt-16">
        <BannerTemplate src={images.est_dashboard}>
          <BoldText>
            Increases customer satisfaction and reduce dispute rates to PayPal by simply add estimate delivery dates. Must-have
            app before BFCM.
          </BoldText>
          <div className="mt-16">
            <RegularText>
              Synctrack Estimated Delivery Date allows you to show shipping dates on products, cart, checkout, success page, &
              order email without any limitations. Stores with clear delivery expectations received fewer customer support
              requests, building consumer trust and increasing sales conversion rates.
            </RegularText>
          </div>

          <div className="mt-16">
            <Button
              variant="primary"
              onClick={() => {
                if (!crossSell.isClickEstimated) {
                  handleGa4(Ga4Event.EstimatedClick);
                  dispatch(
                    bannerSlice.actions.handleBanner({
                      key: 'isClickEstimated',
                      value: true,
                    }),
                  );
                }
                bannerClick({ bannerType: 'pixel_new' });
                window.open(
                  'https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=synctrack&utm_medium=homeinapp',
                  '_blank',
                );
              }}
            >
              Try it now
            </Button>
          </div>
        </BannerTemplate>
      </div>

      {isVisibleRecommendApps ? (
        <div className="mt-16">
          <Card>
            <div className="apps-title">
              <BoldText>More apps from Synctrack with the same support team you love</BoldText>
              <div className="recommend-apps-controller">
                <div onClick={() => window.open('https://synctrack.io/partners/', '_blank')}>
                  <Tooltip content="View all">
                    <Icon source={HorizontalDotsMinor} />
                  </Tooltip>
                </div>

                <div
                  className="ml-8"
                  onClick={() => {
                    dispatch(slice.commonSettingSlice.actions.handleVisibleRecommendApps(false));
                  }}
                >
                  <Tooltip content="Dismiss">
                    <Icon source={MobileCancelMajor} tone="base" />
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="mt-16 apps-wrapper">
              <RecommendedApps />
            </div>
          </Card>
        </div>
      ) : null}
    </DashBoardStyled>
  );
};
export default DashBoard;
