import StepOneImg from '@/assets/welcome/welcome_1.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { Button } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const StepOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateWelcomeStatus, statusLoading] = apiCaller.useUpdateWelcomStatusMutation();
  const [updateWelcomeStep, stepLoading] = apiCaller.useUpdateWelcomStepMutation();
  const handleNext = () => {
    updateWelcomeStep({ step: 2 }).then((res) => {
      dispatch(slice.WelcomeSlice.actions.handleStep(2));
    });
  };
  const handleSkip = () => {
    updateWelcomeStatus().then((res) => {
      navigate(PATH.DEFAULT);
    });
  };
  return (
    <BannerTemplate src={StepOneImg}>
      <BoldText>
        Welcome to Synctrack <br />
        Ready to build PayPal trust
      </BoldText>
      <div className="mt-16">
        <RegularText>Let's do a few steps to configure your account, so Synctrack can work properly.</RegularText>
      </div>
      <div className="mt-16 d-flex">
        <Button loading={statusLoading.isLoading} onClick={handleSkip}>
          Skip & Finish
        </Button>
        <div className="ml-8" onClick={handleNext}>
          <Button loading={stepLoading.isLoading} variant="primary">
            Next step
          </Button>
        </div>
      </div>
    </BannerTemplate>
  );
};

export default memo(StepOne);
