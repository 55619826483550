import { formatDate, getLastMonth, getLastSomeMonths, getSomeDaysAgo, getThisMonth } from '@/helpers';
import { Box, Button, ButtonProps, DatePicker, Icon, OptionList, Popover, TextField, useBreakpoints } from '@shopify/polaris';
import { ArrowRightMinor, CalendarMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useState } from 'react';
const endDate = new Date();
const startDate = new Date();
endDate.setHours(23, 59, 59, 99);
startDate.setHours(0, 0, 0);
const startHourYesterday = new Date(getSomeDaysAgo(1));
startHourYesterday.setHours(0);
startHourYesterday.setMinutes(0);
startHourYesterday.setSeconds(0);
const endHourYesterday = new Date(getSomeDaysAgo(1));
endHourYesterday.setHours(23, 59, 59, 99);
const ranges = [
  {
    title: 'Custom',
    alias: 'Custom',
    period: {
      end: endDate,
      start: startDate,
    },
  },
  {
    title: 'Today',
    alias: 'today',
    period: {
      end: endDate,
      start: startDate,
    },
  },
  {
    title: 'Yesterday',
    alias: 'yesterday',
    period: {
      end: endHourYesterday,
      start: startHourYesterday,
    },
  },
  {
    title: 'Last 7 days',
    alias: 'last7days',
    period: {
      end: endDate,
      start: getSomeDaysAgo(7),
    },
  },
  {
    title: 'This month',
    alias: 'thisMonth',
    period: getThisMonth(),
  },
  {
    title: 'Last 30 days',
    alias: 'last30days',
    period: {
      end: endDate,
      start: getSomeDaysAgo(30),
    },
  },
  {
    title: 'Last month',
    alias: 'lastmonth',
    period: {
      end: getLastMonth().end,
      start: getLastMonth().start,
    },
  },
];
// This example is for guidance purposes. Copying it will come with caveats.
function CustomDatePicker({
  onSave,
  start,
  end,
  titleBtn,
  setTitleBtn,
  variant,
}: {
  onSave?: Function;
  start: Date;
  end: Date;
  titleBtn: string;
  setTitleBtn: Function;
  variant?: ButtonProps['variant'];
}) {
  const now = new Date();
  const { mdDown } = useBreakpoints();
  const [selected, setSelected] = useState(ranges[3]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedDates, setSelectedDates] = useState({ start, end });
  const [{ month, year }, setDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);

  useEffect(() => {
    const range = ranges.find((item) => item.title === titleBtn);
    setSelected(range || ranges[0]);
    setSelectedDates({
      end,
      start,
    });
  }, [end, start, titleBtn]);

  const handleTime = () => {
    const start = new Date(selectedDates.start);
    start.setHours(0, 0);
    const end = new Date(selectedDates.end);
    end.setHours(23, 59);
    onSave && onSave(start, end);
  };

  return (
    <div className="custom-date-picker">
      <Popover
        active={popoverActive}
        autofocusTarget="none"
        preferredAlignment="left"
        preferredPosition="below"
        fluidContent
        sectioned={false}
        fullHeight
        activator={
          <Button variant={variant} icon={CalendarMinor} onClick={() => setPopoverActive(!popoverActive)}>
            {titleBtn}
          </Button>
        }
        onClose={() => {
          setSelectedDates({ start, end });
          setPopoverActive(false);
        }}
      >
        <div className="d-flex date-picker-options" style={{ fontSize: '.75rem' }}>
          <OptionList
            options={ranges.map((range) => ({
              value: range.alias,
              label: range.title,
            }))}
            selected={[selected.alias]}
            onChange={(value) => {
              const range = ranges.find((range) => range.alias === value[0]) || ranges[0];
              setSelected(range);
              setSelectedDates({ ...range.period });
              handleMonthChange(range.period.start.getMonth(), range.period.start.getFullYear());
            }}
          />
          <Box padding={{ xs: '400' }} maxWidth={mdDown ? '320px' : '516px'}>
            <div className="d-flex">
              <div>
                <TextField
                  role="combobox"
                  label={'Since'}
                  labelHidden
                  prefix={<Icon source={CalendarMinor} />}
                  value={formatDate(selectedDates.start, 'D MMM YYYY')}
                  readOnly
                  autoComplete="off"
                />
              </div>
              <Icon source={ArrowRightMinor} />
              <div>
                <TextField
                  role="combobox"
                  label={'Until'}
                  labelHidden
                  prefix={<Icon source={CalendarMinor} />}
                  value={formatDate(selectedDates.end, 'D MMM YYYY')}
                  autoComplete="off"
                  readOnly
                />
              </div>
            </div>
            <DatePicker
              month={month}
              year={year}
              selected={{
                start: selectedDates.start,
                end: selectedDates.end,
              }}
              onMonthChange={handleMonthChange}
              onChange={(value) => {
                setSelectedDates(value);
                setSelected(ranges[0]);
              }}
              multiMonth
              allowRange
              disableDatesAfter={now}
              disableDatesBefore={getLastSomeMonths(2)}
            />
            <div className="date-picker-btn-group">
              <Button
                onClick={() => {
                  setPopoverActive(false);
                  setSelectedDates({ start, end });
                }}
              >
                Cancel
              </Button>
              <div className="ml-8">
                <Button
                  variant="primary"
                  onClick={() => {
                    setPopoverActive(false);
                    handleTime();
                    setTitleBtn(
                      selected.title === 'Custom'
                        ? `${formatDate(selectedDates.start, 'D MMM YYYY')} - ${formatDate(selectedDates.end, 'D MMM YYYY')}`
                        : selected.title,
                    );
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </Popover>
    </div>
  );
}
export default memo(CustomDatePicker);
