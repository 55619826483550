import { OrderStatus, Payment } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { camelToTitle } from '@/helpers';
import slice from '@/redux/slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { Card, Icon, IconSource, InlineGrid, Text, Tooltip } from '@shopify/polaris';
import { QuestionMarkInverseMinor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
interface DataProps {
  value: number;
  payment: Payment;
  status: OrderStatus;
  title?: string;
  icon: IconSource;
  tooltip: string;
}
interface Props {
  inQueue: DataProps;
  noTrackingNumber: DataProps;
  noTransactionId: DataProps;
  overQuota: DataProps;
  noOrInvalidAccount: DataProps;
  missingPaypalAccount: DataProps;
}

const AnalyticsError = ({ ...props }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderTable = useSelector(orderTableOrderPageSelector);
  const handleClick = (data: DataProps) => () => {
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...orderTable,
        paymentSelected: [data.payment],
        statusSelected: [data.status],
        currentPage: 1,
      }),
    );
    navigate(PATH.ORDERS);
  };
  return (
    <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
      {Object.entries(props).map(([key, value]) => {
        return (
          <Card key={key}>
            <div className="analytics-items-title" onClick={handleClick(value)}>
              <Icon source={value.icon} tone="base" />
              <Tooltip content="View list detail">
                <Text fontWeight="medium" as="h5" variant="headingSm">
                  {value.title || camelToTitle(key)}
                </Text>
              </Tooltip>

              <div className="ml-8">
                <Tooltip content={value.tooltip}>
                  <Icon source={QuestionMarkInverseMinor} tone="base" />
                </Tooltip>
              </div>
            </div>
            <div className="mt-8 ml-4">
              <Text as="h5" variant="heading2xl" fontWeight="regular">
                {value.value}
              </Text>
            </div>
          </Card>
        );
      })}
    </InlineGrid>
  );
};

export default memo(AnalyticsError);
