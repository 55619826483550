import BoldText from '@/components/BoldText';
import CriticalBanner from '@/components/CriticalBanner';
import RegularText from '@/components/RegularText';
import UploadFile from '@/components/uploadFile';
import config from '@/config';
import { EvidenceType } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { IDispute } from '@/types/components';
import { Checkbox, Icon, InlineGrid, Link, Modal, Select, TextField } from '@shopify/polaris';
import { CircleMinusMinor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

const configEvidence: Array<IDispute.IDisputeEvidenceOption> = [
  {
    reason: 'MERCHANDISE_OR_SERVICE_NOT_RECEIVED',
    fulfillment: true,
    refund: true,
    other: false,
  },
  {
    reason: 'MERCHANDISE_OR_SERVICE_NOT_AS_DESCRIBED',
    fulfillment: false,
    refund: true,
    other: true,
  },
  {
    reason: 'UNAUTHORISED',
    fulfillment: true,
    refund: true,
    other: true,
  },
  {
    reason: 'CREDIT_NOT_PROCESSED',
    fulfillment: false,
    refund: true,
    other: true,
  },
  {
    reason: 'DUPLICATE_TRANSACTION',
    fulfillment: false,
    refund: true,
    other: true,
  },
  {
    reason: 'INCORRECT_AMOUNT',
    fulfillment: false,
    refund: true,
    other: true,
  },

  {
    reason: 'PAYMENT_BY_OTHER_MEANS',
    fulfillment: false,
    refund: true,
    other: true,
  },
  {
    reason: 'CANCELED_RECURRING_BILLING',
    fulfillment: false,
    refund: true,
    other: true,
  },
  {
    reason: 'OTHER',
    fulfillment: false,
    refund: true,
    other: true,
  },
];

const Evidence = ({ ...props }: IDispute.IDisputeEvidence): JSX.Element => {
  const dispatch = useDispatch();
  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '' });
  const handleOptionsType = () => {
    const disputeReason: any = configEvidence.find((item) => item.reason === props.reason) || configEvidence[0];
    const options = [
      { label: 'Proof of fulfillment', value: EvidenceType.FULFILLMENT, key: 'fulfillment' },
      { label: 'Proof of Refund', value: EvidenceType.REFUND, key: 'refund' },
      { label: 'Other evidence', value: EvidenceType.OTHER, key: 'other' },
    ];
    // eslint-disable-next-line array-callback-return
    const result = options.filter((item) => disputeReason[item.key.toLowerCase()]);
    return result || options;
  };
  const [state, setState] = useState({
    isChecked: false,
    proofOfDelivery: [
      {
        carrierName: '',
        trackingNumber: '',
      },
    ],
    refundIds: [''],
    moreDetails: '',
    option: handleOptionsType()[0].value,
  });
  const [files, setFiles] = useState<Array<File>>([]);
  const handleFiles = (filesInput: Array<File>) => {
    setFiles(filesInput);
  };

  const clearState = () => {
    setState({
      isChecked: false,
      proofOfDelivery: [
        {
          carrierName: '',
          trackingNumber: '',
        },
      ],
      refundIds: [''],
      moreDetails: '',
      option: handleOptionsType()[0].value,
    });
    setFiles([]);
    setIsShowErrorInline({ status: false, msg: '' });
  };

  const [sendProvideEvidence, setProvideEvidenceStatus] = apiCaller.useSendProvideEvidenceDisputeMutation();
  const onChangeProofOfDelivery = (value: string, index: number, key: 'carrierName' | 'trackingNumber') => {
    const proofOfDelivery = [...state.proofOfDelivery];
    const item = proofOfDelivery[index];
    item[key] = value;
    setState({ ...state, proofOfDelivery });
  };

  const handleDeleteProofOfRefundItem = (index: number) => () => {
    const refundIds = [...state.refundIds];
    refundIds.splice(index, 1);
    setState({ ...state, refundIds });
  };

  const handleDeleteProofOfDeliveryItem = (index: number) => () => {
    const proofOfDelivery = [...state.proofOfDelivery];
    proofOfDelivery.splice(index, 1);
    setState({ ...state, proofOfDelivery });
  };

  const handleClose = () => {
    clearState();
    props.onClose();
    setIsShowErrorInline({
      msg: '',
      status: false,
    });
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append('disputeId', props.disputeId);
    data.append('shop', config.shop);
    data.append('urlParams', config.urlParams);
    data.append('evidenceType', state.option);
    if (state.option === EvidenceType.FULFILLMENT) {
      const carrierName = state.proofOfDelivery.map((item) => item.carrierName);
      const trackingNumber = state.proofOfDelivery.map((item) => item.trackingNumber);
      files.forEach((item) => {
        data.append('files', item);
      });
      data.append('carrierName', carrierName.toString());
      data.append('trackingNumber', trackingNumber.toString());
      data.append('note', state.moreDetails);
    }
    if (state.option === EvidenceType.REFUND) {
      files.forEach((item) => {
        data.append('files', item);
      });
      data.append('refundIds', state.refundIds.toString());
    }
    if (state.option === EvidenceType.OTHER) {
      files.forEach((item) => {
        data.append('files', item);
      });
      data.append('note', state.moreDetails);
    }
    sendProvideEvidence(data).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setIsShowErrorInline({ ...condition });
      } else {
        handleClose();
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };
  return (
    <Modal
      open={props.isOpenModal}
      onClose={handleClose}
      title="Provide evidence"
      primaryAction={{
        content: 'Submit',
        onAction: handleSubmit,
        loading: setProvideEvidenceStatus.isLoading,
      }}
    >
      <Modal.Section>
        <CriticalBanner isVisible={isShowErrorInline.status}>{isShowErrorInline.msg}</CriticalBanner>
        {/* Proof Of delivery */}
        <div className={isShowErrorInline.status ? 'mt-16' : ''}>
          <Select
            label="Evidence type"
            options={handleOptionsType()}
            onChange={(value: EvidenceType) => {
              setState({ ...state, option: value });
            }}
            value={state.option}
          />
        </div>

        <div className="mt-16">
          {state.option === EvidenceType.FULFILLMENT ? (
            <div>
              <BoldText>Proof of fulfillment</BoldText>
              <Checkbox
                label="Is service or digital good"
                checked={state.isChecked}
                onChange={(value) => {
                  setState({ ...state, isChecked: value });
                }}
              />
              {state.proofOfDelivery.map((item, index) => {
                return (
                  <div className="margin-top-8" key={index}>
                    {state.proofOfDelivery.length > 1 && (
                      <div className="province-remove-btn" onClick={handleDeleteProofOfDeliveryItem(index)}>
                        <Icon source={CircleMinusMinor} tone="critical" />
                      </div>
                    )}
                    <InlineGrid gap="400" columns={2}>
                      <TextField
                        disabled={state.isChecked}
                        label="Courier name"
                        value={item.carrierName}
                        onChange={(value) => {
                          onChangeProofOfDelivery(value, index, 'carrierName');
                        }}
                        // error={state.error}
                        autoComplete="off"
                      />
                      <TextField
                        disabled={state.isChecked}
                        label="Tracking number"
                        value={item.trackingNumber}
                        onChange={(value) => {
                          onChangeProofOfDelivery(value, index, 'trackingNumber');
                        }}
                        // error={state.error}
                        autoComplete="off"
                      />
                    </InlineGrid>
                  </div>
                );
              })}
              {!state.isChecked ? (
                <div className="margin-top-8">
                  <Link
                    removeUnderline
                    onClick={() =>
                      setState({ ...state, proofOfDelivery: [...state.proofOfDelivery, { carrierName: '', trackingNumber: '' }] })
                    }
                  >
                    Add another tracking number
                  </Link>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Proof of refund */}
          {state.option === EvidenceType.REFUND ? (
            <div>
              <BoldText>Proof of refund</BoldText>
              <RegularText>Refund ID (if refund through PayPal only)</RegularText>
              {state.refundIds.map((item, index) => {
                return (
                  <div key={index} className={`${index !== 0 ? 'mt-32' : ''} relative`}>
                    {state.refundIds.length > 1 && (
                      <div className="province-remove-btn-refund" onClick={handleDeleteProofOfRefundItem(index)}>
                        <Icon source={CircleMinusMinor} tone="critical" />
                      </div>
                    )}
                    <TextField
                      label=""
                      labelHidden
                      value={item}
                      onChange={(value) => {
                        const refundIds = [...state.refundIds];
                        refundIds[index] = value;
                        setState({ ...state, refundIds });
                      }}
                      // error={state.error}
                      autoComplete="off"
                    />
                  </div>
                );
              })}
              <div className="mt-8">
                <RegularText>
                  <Link removeUnderline onClick={() => setState({ ...state, refundIds: [...state.refundIds, ''] })}>
                    Add another refund ID
                  </Link>
                </RegularText>
              </div>
            </div>
          ) : null}
          <div className="mt-16">
            <UploadFile files={files} onSave={handleFiles} />
            <div className="mt-16">
              <TextField
                label=""
                labelHidden
                value={state.moreDetails}
                onChange={(value) => {
                  setState({ ...state, moreDetails: value });
                }}
                multiline={5}
                autoComplete="off"
                placeholder="Add more details"
              />
            </div>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default memo(Evidence);
