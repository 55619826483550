import CriticalBanner from '@/components/CriticalBanner';
import RegularText from '@/components/RegularText';
import { CONSTAN } from '@/constants/constan';
import { CountryCodePaypalRequired, OfferType } from '@/constants/enum';
import { checkShowErrorInline, convertAmount, convertCurrency, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { IDispute } from '@/types/components';
import { Modal, Select, TextField } from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface Option {
  label: string;
  value: string;
}
const optionsType = [
  { label: 'Refund', value: OfferType.REFUND },
  { label: 'Refund with return', value: OfferType.REFUND_WITH_RETURN },
  { label: 'Refund with replacement', value: OfferType.REFUND_WITH_REPLACEMENT },
  { label: 'Replacement without refund', value: OfferType.REPLACEMENT_WITHOUT_REFUND },
];

const Offer = ({ ...props }: IDispute.IDisputeOffer): JSX.Element => {
  const isSkip = useSelector(isSkipApiSelector);

  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '' });
  const countryCode = apiCaller.useGetDisputeCountryCodeQuery(undefined, { skip: isSkip }).data;
  const [locationOptions, setLocationOptions] = useState<{ state: Option[]; city: Option[] }>({
    state: [],
    city: [],
  });
  const dispatch = useDispatch();
  const [makeOffer, makeOfferStatus] = apiCaller.useMakeOfferDisputeMutation();
  const initialState = {
    amount: {
      error: '',
      value: '',
    },
    message: {
      error: '',
      value: '',
    },
    invoice: '',
    countryCode: '',
    countryCodeSelected: {
      label: '',
      value: '',
      postalCode: 2,
      province: 2,
    },
    streetAddress: {
      value: '',
      error: '',
    },
    province: '',
    city: '',
    postalCode: { value: '', error: '' },
  };
  const [state, setState] = useState({
    type: OfferType.REFUND,
    ...initialState,
  });

  const [getStates] = apiCaller.useLazyGetStateQuery();
  const [getCities] = apiCaller.useLazyGetCityQuery();

  useEffect(() => {
    if (state.countryCode) {
      const getData = async () => {
        const { data } = await getStates({ countryCode: state.countryCode });
        const optionsStates = data?.data.result.map(({ name, isoCode }) => {
          return {
            value: isoCode,
            label: name,
          };
        });
        setLocationOptions({
          ...locationOptions,
          state: optionsStates || [],
        });
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.countryCode]);

  useEffect(() => {
    if (state.province) {
      const getData = async () => {
        const { data } = await getCities({ countryCode: state.countryCode, stateCode: state.province });
        const optionsCities = data?.data.result.map(({ name }) => {
          return {
            value: name,
            label: name,
          };
        });
        setLocationOptions({
          ...locationOptions,
          city: optionsCities || [],
        });
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.province]);

  const clearState = () => {
    setState({
      type: OfferType.REFUND,
      ...initialState,
    });
    setIsShowErrorInline({ status: false, msg: '' });
  };
  const handleDisableBtn = () => {
    const isErrorAmount = state.type !== OfferType.REPLACEMENT_WITHOUT_REFUND && !state.amount.value;
    const isErrorMessage = !state.message.value;
    const isErrorStressAddress = !state.streetAddress.value && state.type === OfferType.REFUND_WITH_RETURN;
    const isErrorCity = !state.city && state.type === OfferType.REFUND_WITH_RETURN;
    const isErrorprovince =
      !state.province &&
      state.countryCodeSelected.province !== CountryCodePaypalRequired.NOT_USE &&
      state.countryCodeSelected.province === CountryCodePaypalRequired.REQUIRED;
    const isErrorPostalCode =
      !state.postalCode.value &&
      state.countryCodeSelected.postalCode !== CountryCodePaypalRequired.NOT_USE &&
      state.countryCodeSelected.postalCode === CountryCodePaypalRequired.REQUIRED;
    return isErrorAmount || isErrorMessage || isErrorStressAddress || isErrorCity || isErrorprovince || isErrorPostalCode;
  };

  const handleSendOffer = () => {
    if (state.type === OfferType.REFUND || state.type === OfferType.REFUND_WITH_REPLACEMENT) {
      makeOffer({
        offerType: state.type,
        disputeId: props.disputeId,
        offerValue: Number(state.amount.value),
        invoiceId: state.invoice,
        note: state.message.value,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setIsShowErrorInline({ ...condition });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
          props.onClose();
        }
      });
    }
    if (state.type === OfferType.REFUND_WITH_RETURN) {
      makeOffer({
        offerType: state.type,
        disputeId: props.disputeId,
        offerValue: Number(state.amount.value),
        invoiceId: state.invoice,
        note: state.message.value,
        returnAddress: {
          countryCode: state.countryCode,
          postalCode: state.postalCode.value,
          province: state.province,
          city: state.city,
          address: state.streetAddress.value,
        },
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setIsShowErrorInline({ ...condition });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
          props.onClose();
        }
      });
    }
    if (state.type === OfferType.REPLACEMENT_WITHOUT_REFUND) {
      makeOffer({
        offerType: state.type,
        note: state.message.value,
        disputeId: props.disputeId,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setIsShowErrorInline({ ...condition });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
          props.onClose();
        }
      });
    }
  };
  const handleClose = () => {
    props.onClose();
    clearState();
  };
  return (
    <Modal
      open={props.isOpenModal}
      onClose={handleClose}
      title="Offer to resolve dispute"
      primaryAction={{
        content: 'Send offer',
        onAction: handleSendOffer,
        disabled: handleDisableBtn(),
        loading: makeOfferStatus.isLoading,
      }}
    >
      <Modal.Section>
        <CriticalBanner isVisible={isShowErrorInline.status}>{isShowErrorInline.msg}</CriticalBanner>
        <div className={isShowErrorInline.status ? 'mt-16' : ''}>
          <Select
            label="Offer type"
            options={optionsType}
            onChange={(value: OfferType) => {
              setState({ ...initialState, type: value });
            }}
            value={state.type}
          />
        </div>

        {state.type !== OfferType.REPLACEMENT_WITHOUT_REFUND && (
          <div className="mt-16">
            <RegularText>
              <span>Amount requested by the buyer: </span>
              <strong>{convertAmount(props.disputeAmount, props.disputeCurrencyCode)}</strong>
            </RegularText>
          </div>
        )}

        {state.type !== OfferType.REPLACEMENT_WITHOUT_REFUND ? (
          <div className="mt-16">
            <TextField
              prefix={convertCurrency(props.disputeCurrencyCode)}
              label="Refund amount"
              value={state.amount.value}
              requiredIndicator
              onChange={(value) =>
                setState({
                  ...state,
                  amount: {
                    error: '',
                    value: value > props.disputeAmount ? props.disputeAmount : value,
                  },
                })
              }
              onBlur={() => {
                setState({
                  ...state,
                  amount: {
                    ...state.amount,
                    error: state.amount.value ? '' : 'Please enter a valid amount',
                    value:
                      state.amount.value && Number(state.amount.value) < CONSTAN.minAmount
                        ? CONSTAN.minAmount.toString()
                        : state.amount.value,
                  },
                });
              }}
              autoComplete="off"
              error={state.amount.error}
            />
          </div>
        ) : (
          <div className="mt-16">
            <RegularText>
              <span>Buyer’s shipping address: </span>
              <strong>{props.shippingAddress}</strong>
            </RegularText>
          </div>
        )}
        {state.type !== OfferType.REPLACEMENT_WITHOUT_REFUND ? (
          <div className="margin-top-4">
            <TextField
              max={256}
              label="Invoice ID (optional)"
              value={state.invoice}
              onChange={(value) => setState({ ...state, invoice: value })}
              autoComplete="off"
            />
          </div>
        ) : null}
        {state.type === OfferType.REFUND_WITH_RETURN ? (
          <div className="margin-top-4">
            <Select
              label="Return address"
              options={countryCode?.data.result || []}
              onChange={(value: string) => {
                setState({
                  ...state,
                  countryCode: value,
                  countryCodeSelected: countryCode?.data.result.find((item) => item.value === value) || {
                    label: 'ALBANIA',
                    value: 'AL',
                    postalCode: 2,
                    province: 2,
                  },
                });
              }}
              value={state.countryCode}
              placeholder="Country code"
            />

            {state.countryCodeSelected.postalCode !== CountryCodePaypalRequired.NOT_USE ? (
              <div className="margin-top-4">
                <TextField
                  max={256}
                  label="Postal code"
                  requiredIndicator={state.countryCodeSelected.postalCode === CountryCodePaypalRequired.REQUIRED}
                  value={state.postalCode.value}
                  onChange={(value) => {
                    setState({
                      ...state,
                      postalCode: {
                        value,
                        error: '',
                      },
                    });
                  }}
                  onBlur={() => {
                    if (!state.postalCode.value && state.countryCodeSelected.postalCode === CountryCodePaypalRequired.REQUIRED) {
                      setState({
                        ...state,
                        postalCode: {
                          ...state.postalCode,
                          error: 'Postal code is required',
                        },
                      });
                    }
                  }}
                  autoComplete="off"
                  error={state.postalCode.error}
                  placeholder="Postal code"
                />
              </div>
            ) : null}
            {state.countryCodeSelected.province !== CountryCodePaypalRequired.NOT_USE ? (
              <div className="margin-top-4">
                <Select
                  label="State/Province"
                  value={state.province}
                  requiredIndicator={state.countryCodeSelected.province === CountryCodePaypalRequired.REQUIRED}
                  onChange={(value) => {
                    setState({
                      ...state,
                      province: value,
                    });
                  }}
                  placeholder="State/Province"
                  options={locationOptions.state}
                />
              </div>
            ) : null}

            <div className="margin-top-4">
              <Select
                label="City"
                value={state.city}
                onChange={(value) => {
                  setState({
                    ...state,
                    city: value,
                  });
                }}
                placeholder="City"
                requiredIndicator
                options={locationOptions.city}
              />
            </div>

            <div className="margin-top-4">
              <TextField
                max={256}
                label="Street address"
                value={state.streetAddress.value}
                onChange={(value) => {
                  setState({
                    ...state,
                    streetAddress: {
                      value,
                      error: '',
                    },
                  });
                }}
                onBlur={() => {
                  if (!state.streetAddress.value) {
                    setState({
                      ...state,
                      streetAddress: {
                        ...state.streetAddress,
                        error: 'street address is required',
                      },
                    });
                  }
                }}
                autoComplete="off"
                error={state.streetAddress.error}
                placeholder="Street address"
                requiredIndicator
              />
            </div>
          </div>
        ) : null}

        <div className="margin-top-4">
          <TextField
            max={2000}
            label="Message"
            value={state.message.value}
            onChange={(value) =>
              setState({
                ...state,
                message: {
                  error: '',
                  value,
                },
              })
            }
            onBlur={(value) => {
              if (!state.message.value) {
                setState({
                  ...state,
                  message: {
                    ...state.message,
                    error: 'Message is required',
                  },
                });
              }
            }}
            autoComplete="off"
            multiline={5}
            error={state.message.error}
            requiredIndicator
          />
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default memo(Offer);
