import returnsDrive from '@/assets/apps/return_logo.avif';
import ordertracking from '@/assets/apps/ot_logo.avif';
import ordertrackingBanner from '@/assets/apps/order_tracking_banner.png';
import returnsDriveBanner from '@/assets/apps/return_banner.png';
import { Ga4Event } from '@/constants/enum';

export const configRecommend = [
  {
    title: 'Omega - Order Tracking',
    description: 'Track your orders with a branded tracking page & automatic notifications. Reduce WISMO support tickets.',
    logo: ordertracking,
    href: 'https://apps.shopify.com/omega-order-tracking?utm_campaign=crs&utm_source=synctrack&utm_medium=homeinapp',
    banner: ordertrackingBanner,
    star: '4.9',
    plan: 'Free plan available',
    marketingBanner: 'ads_order_tracking',
    hasBFS: true,
    ga4Event: Ga4Event.OTClick,
    key: 'isClickOT',
  },
  {
    title: 'Synctrack ‑ Returns & Exchanges',
    brandURL: 'https://apps.shopify.com/partners/omegaapps',
    description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue',
    logo: returnsDrive,
    href: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=synctrack&utm_medium=homeinapp',
    banner: returnsDriveBanner,
    star: '4.9',
    plan: 'Free',
    marketingBanner: 'ads_returnsdrive',
    hasBFS: true,
    ga4Event: Ga4Event.ReturnClick,
    key: 'isClickReturn',
  },
];
