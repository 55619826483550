import StepFourImg from '@/assets/welcome/welcome_4.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import config from '@/config';
import { PATH } from '@/constants/path';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { Button, Checkbox } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const StepFour = () => {
  const scope = useScope();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [lazyProcessOldOrders] = apiCaller.useLazyFetchProcessGetOldOrdersQuery();
  const [updateWelcomeStatus, statusLoading] = apiCaller.useUpdateWelcomStatusMutation();
  const [updateWelcomeStep, stepLoading] = apiCaller.useUpdateWelcomStepMutation();
  const handleNext = () => {
    updateWelcomeStep({ step: 5 }).then((res) => {
      if (isChecked) {
        lazyProcessOldOrders({
          oldOrderRange: 0,
          store: config.shop,
          path: 'welcome',
        });
      }
      dispatch(slice.WelcomeSlice.actions.handleStep(5));
    });
  };
  const handleSkip = () => {
    updateWelcomeStatus().then((res) => {
      navigate(PATH.DEFAULT);
    });
  };
  return (
    <BannerTemplate src={StepFourImg}>
      <BoldText>
        Process your old orders <br />
        Do you want to sync old orders to PayPal?
      </BoldText>
      <div className="mt-16">
        <RegularText>Please check the option below, let SyncTrack handle the rest</RegularText>
        <div className="mt-16">
          <Checkbox
            disabled={scope.isViewOnly}
            label="Yes, I want to sync the last 60 days trackings to PayPal"
            checked={isChecked}
            onChange={(newCheck) => setIsChecked(newCheck)}
          />
        </div>
      </div>
      <div className="mt-16 d-flex">
        <Button loading={statusLoading.isLoading} onClick={handleSkip}>
          Skip & Finish
        </Button>
        <div className="ml-8" onClick={handleNext}>
          <Button loading={stepLoading.isLoading} variant="primary">
            Next step
          </Button>
        </div>
      </div>
    </BannerTemplate>
  );
};

export default memo(StepFour);
