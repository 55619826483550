import { COLORS } from '@/constants/colors';
import styled from 'styled-components';

export const DisputeStyled = styled.div`
  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Polaris-Icon {
      width: 150px;
      height: 150px;
    }
  }
  .Polaris-AlphaStack {
    background-color: white;
    padding: 16px 0px;
    border-radius: 8px;
  }
  .analytics-container {
    background-color: ${COLORS.backgroundColor};
    padding: 1rem;
  }

  .statistical-layout {
    display: flex;
    height: 100%;
    .Polaris-Text--root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .statistical-layout-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      margin: 0px 20px 0px 0px !important;
      .Polaris-Icon {
        width: 30px;
        height: 30px;
      }
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      text-align: start;
      cursor: pointer;
      &:hover {
        color: ${COLORS.primary};
      }
    }
    .value {
      font-size: 28px;
      line-height: 20px;
      margin: 0px;
      margin-top: 12px;
      text-align: start;
    }
  }
  .vertical-center {
    line-height: 26px;
    align-items: center;
    .text-truncate {
      font-size: 14px;
      margin: 0;
      a {
        text-decoration: none;
      }
    }
    .Polaris-Icon {
      margin: 0;
    }
    .break-line {
      white-space: pre-line;
      line-break: auto;
    }
  }
  .font-weight-bold {
    font-weight: bold;
  }
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
  }
  .Polaris-ResourceList {
    min-width: 1370px;
    .table-content {
      justify-content: space-between;
    }
  }
  .list-paypal-account-container {
    padding: 16px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease-out;
    .Polaris-Text--bodySm {
      line-height: 1.25rem;
    }
    .btn-group {
      display: none;
    }
    &:hover {
      .btn-group {
        display: flex;
      }
    }
  }
  .Polaris-IndexFilters__ButtonWrap {
    .Polaris-Button--sizeMicro {
      display: none;
    }
  }
  .mt-8 {
    margin-top: 8px;
  }
  .dispute-account-content {
    max-width: 80%;
  }
  .your-response {
    .Polaris-Divider {
      margin: 0 !important;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .j-s-b {
    justify-content: space-between;
    align-items: center;
  }
  .m-vertical-16 {
    margin: 16px 0px;
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.5rem;
    }
  }
  .dispute-view-btn {
    width: 60px;
    height: 28px;
    .Polaris-Button {
      display: none;
    }
  }
  .Polaris-IndexTable__TableRow--hovered {
    .dispute-view-btn {
      .Polaris-Button {
        display: block;
      }
    }
  }
`;
