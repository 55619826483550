import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import config from '@/config';
import { IDispute } from '@/types/components';
import { getSomeDaysAgo } from '@/helpers';
const toDate = new Date();
toDate.setHours(23, 59, 59, 99);
// Define the initial state using that type
const initialState = {
  reason: '',
  disputeTable: {
    currentPage: 1,
    search: '',
    perPage: ['10'],
    store: [config.shop],
    reason: ['all'],
    account: ['all'],
    fromDate: getSomeDaysAgo(30),
    toDate: toDate,
    sortBy: 'updateTime',
    sort: 'desc',
    status: 0,
  },
  searchInput: '',
  titleBtnDatePicker: 'Last 30 days',
};

const disputePageSlice = createSlice({
  name: 'dispute',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleReason: (state, action: PayloadAction<string>) => {
      state.reason = action.payload;
    },
    handleTabSelected: (state, action: PayloadAction<number>) => {
      state.disputeTable.account = ['all'];
      state.disputeTable.reason = ['all'];
      state.disputeTable.perPage = ['10'];
      state.disputeTable.search = '';
      state.disputeTable.currentPage = 1;
      state.searchInput = '';
    },
    handleSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    handleDisputeTable: (state, action: PayloadAction<IDispute.IDisputeTable>) => {
      state.disputeTable = action.payload;
    },

    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const reasonSelector = createSelector(
  (state: RootState) => state.dispute,
  (state) => state.reason,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.dispute,
  (state) => state.titleBtnDatePicker,
);
export const disputeTableSelector = createSelector(
  (state: RootState) => state.dispute,
  (state) => state.disputeTable,
);
export const searchInputSelector = createSelector(
  (state: RootState) => state.dispute,
  (state) => state.searchInput,
);

export default disputePageSlice;
