import { apiCaller } from '@/redux/query';
import { IResponseApi } from '@/types/api/response.api';
import { Badge } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import Faq from 'react-faq-component';
// import callApi from '../../utils/apiCaller';

const FAQs = () => {
  const [faqs, setFaqs] = useState<Array<IResponseApi.IFaqs>>([]);
  const [fetchFaqs] = apiCaller.useLazyFetchFaqsQuery();
  useEffect(() => {
    fetchFaqs(undefined).then((res) => {
      if (res.data?.state === 1) {
        let firstFaqs = {
          title: 'Meaning of order status on the application',
          content: (
            <div className="p-2" style={{ lineHeight: '32px' }}>
              <Badge tone="success" progress="complete">
                Completed
              </Badge>{' '}
              Successfully synchronized to PayPal,
              <br />
              <Badge tone="info" progress="partiallyComplete">
                In queue
              </Badge>{' '}
              Orders are waiting to be synchronized,
              <br />
              <Badge tone="warning">No Tracking Number</Badge>
              Order tracking number is not available on Shopify yet,
              <br />
              <Badge tone="warning">Invalid Tracking Number</Badge> The tracking number is in wrong or incorrect format,
              <br />
              <Badge tone="warning">No Transaction id</Badge> Shopify or the payment app does not return a PayPal transaction ID
              (checkoutX is not compatible with the app),
              <br />
              <Badge tone="warning">Over quota</Badge> Excess limit under subscription Plan,
              <br />
              <Badge tone="critical">No Account Connected</Badge> No PayPal accounts connected,
              <br />
              <Badge progress="complete" tone="critical">
                Missing PayPal account
              </Badge>{' '}
              The account corresponding to the payment account for the order has not been connected,
            </div>
          ),
        };
        const listFAQs = [firstFaqs, ...res.data.listFAQs].map((item, index) => {
          return {
            title: index + 1 + '. ' + item.title,
            content: item.content,
          };
        });
        setFaqs(listFAQs);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const styles = {
    rowTitleColor: '#1f5199',
    rowContentColor: 'grey',
  };

  const config = {
    animate: true,
  };
  return <Faq data={{ rows: faqs }} styles={styles} config={config} />;
};

export default FAQs;
