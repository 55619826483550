import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import RegularText from '@/components/RegularText';
import config from '@/config';
import { PATH } from '@/constants/path';
import { convertAmount, convertUrlOrder, dateToTimeStamp, formatDate } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { disputeTableSelector, searchInputSelector } from '@/redux/slice/dispute.slice';
import { IDispute } from '@/types/components';
import {
  Button,
  Card,
  ChoiceList,
  EmptyState,
  IndexFilters,
  IndexFiltersMode,
  IndexFiltersProps,
  IndexTable,
  Link,
  Pagination,
  Text,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import options from '../options';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const sortOptions: IndexFiltersProps['sortOptions'] = [
  { label: 'Amount', value: 'disputeAmount asc', directionLabel: 'Ascending' },
  { label: 'Amount', value: 'disputeAmount desc', directionLabel: 'Descending' },
  { label: 'Due date', value: 'sellerResponseDueDate asc', directionLabel: 'Ascending' },
  { label: 'Due date', value: 'sellerResponseDueDate desc', directionLabel: 'Descending' },
  { label: 'Last update', value: 'updateTime asc', directionLabel: 'A-Z' },
  { label: 'Last update', value: 'updateTime desc', directionLabel: 'Z-A' },
];

const statusOptions = [
  {
    label: 'All',
    value: '0',
  },
  {
    label: 'Response Required',
    value: '1',
  },
  {
    label: 'Under Review by PayPal',
    value: '2',
  },
  {
    label: 'Awaiting buyer’s response',
    value: '3',
  },
  {
    label: 'Resolved',
    value: '4',
  },
];

const getDisputeLink = (disputeId: string) => {
  const url = `https://www.paypal.com/resolutioncenter/view/${disputeId}`;
  return url;
};

const DisputeTable = ({ ...props }: IDispute.IDisputeTableProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const disputeTable = useSelector(disputeTableSelector);
  const searchInput = useSelector(searchInputSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const fetchListPaypalAccount = apiCaller.useFetchDisputeAccountsFilterQuery(config.shop, { skip: isSkip }).data;
  const [state, setState] = useState<IDispute.IDisputeTableState>({
    optionsPaypal: [],
    optionListStore: [],
    isVisiblePopupReadAll: false,
  });

  useEffect(() => {
    if (fetchListPaypalAccount) {
      const optionsPaypal = fetchListPaypalAccount
        .filter((item) => item.getDispute)
        .map((item) => {
          return {
            label: item.email,
            value: item.email,
          };
        });
      setState({
        ...state,
        optionsPaypal: [
          {
            label: 'All',
            value: 'all',
          },
          ...optionsPaypal,
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListPaypalAccount]);
  const { data, isFetching } = apiCaller.useFetchListDisputeQuery(
    {
      account: disputeTable.account[0],
      page: disputeTable.currentPage,
      fromDate: dateToTimeStamp(props.fromDate),
      toDate: dateToTimeStamp(props.toDate),
      perPage: Number(disputeTable.perPage[0]),
      reason: disputeTable.reason[0],
      search: disputeTable.search,
      sort: disputeTable.sort.toUpperCase(),
      sortBy: disputeTable.sortBy,
      status: options.mapValueStatus.find((_, index) => index === disputeTable.status)?.value || 'OPEN',
    },
    { skip: isSkip },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearch = useCallback(
    _debounce((value: IDispute.IDisputeTable) => {
      dispatch(slice.disputePageSlice.actions.handleDisputeTable(value));
    }, 1000),
    [],
  );
  const emptyStateMarkup = (
    <EmptyState heading="No Item" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
      <RegularText>Try changing the filters or search term</RegularText>
    </EmptyState>
  );

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  const filters = [
    {
      key: 'account',
      label: 'Account',
      filter: (
        <ChoiceList
          title="Account"
          titleHidden
          choices={state.optionsPaypal}
          selected={disputeTable.account}
          onChange={(value) =>
            dispatch(
              slice.disputePageSlice.actions.handleDisputeTable({
                ...disputeTable,
                account: value,
                currentPage: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={statusOptions}
          selected={[disputeTable.status.toString()]}
          onChange={(value) =>
            dispatch(
              slice.disputePageSlice.actions.handleDisputeTable({
                ...disputeTable,
                status: Number(value),
                currentPage: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'reason',
      label: 'Reason',
      filter: (
        <ChoiceList
          title="Reason"
          titleHidden
          choices={options.optionsReason}
          selected={disputeTable.reason}
          onChange={(value) =>
            dispatch(
              slice.disputePageSlice.actions.handleDisputeTable({
                ...disputeTable,
                reason: value,
                currentPage: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={options.optionsPerPage}
          selected={disputeTable.perPage}
          onChange={(value) =>
            dispatch(
              slice.disputePageSlice.actions.handleDisputeTable({
                ...disputeTable,
                perPage: value,
                currentPage: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          slice.disputePageSlice.actions.handleDisputeTable({
            ...disputeTable,
            account: ['all'],
            currentPage: 1,
          }),
        ),
      key: 'account',
      label: `Account: ${state.optionsPaypal.find((item) => item.value === disputeTable.account[0])?.label}`,
    },
    {
      onRemove: () =>
        dispatch(
          slice.disputePageSlice.actions.handleDisputeTable({
            ...disputeTable,
            status: 0,
            currentPage: 1,
          }),
        ),
      key: 'status',
      label: 'Status: ' + statusOptions.find((option) => option.value === disputeTable.status.toString())?.label,
    },
    {
      onRemove: () =>
        dispatch(
          slice.disputePageSlice.actions.handleDisputeTable({
            ...disputeTable,
            reason: ['all'],
            currentPage: 1,
          }),
        ),
      key: 'reason',
      label: 'Reason: ' + options.optionsReason.find((option) => option.value === disputeTable.reason[0])?.label,
    },
    {
      onRemove: () =>
        dispatch(
          slice.disputePageSlice.actions.handleDisputeTable({
            ...disputeTable,
            perPage: ['10'],
            currentPage: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + disputeTable.perPage,
    },
  ];

  const disputes = data?.listDispute.map((dispute) => {
    const reasonLable = options.optionsReason.find((item) => item.value === dispute.reason)?.label;
    return {
      id: dispute.disputeId,
      account: <RegularText>{dispute.disputeAccounts[0].email}</RegularText>,
      disputeId: (
        <RegularText>
          <Link
            onClick={() => {
              window.open(getDisputeLink(dispute.disputeId), '_blank');
            }}
            removeUnderline
          >
            {dispute.disputeId}
          </Link>
        </RegularText>
      ),
      store:
        dispute.order.length > 0 ? (
          <div className="elipsis" style={{ maxWidth: '100px' }}>
            {dispute.order.map((item, index) => {
              return (
                <div key={index}>
                  <Tooltip content={item.shop}>
                    <Text variant="bodyMd" as="span" truncate>
                      {item.shop}
                    </Text>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        ) : (
          <RegularText>--------</RegularText>
        ),
      orderId:
        dispute.order.length > 0 ? (
          <div>
            {dispute.order.map((item, index) => {
              return (
                <Tooltip key={index} content={item.shop}>
                  <Link key={index} removeUnderline external url={convertUrlOrder(item.shop, item.orderId)}>
                    {item.name}
                  </Link>
                </Tooltip>
              );
            })}
          </div>
        ) : (
          <RegularText>--------</RegularText>
        ),
      amount: <RegularText>{convertAmount(dispute.disputeAmount, dispute.disputeCurrencyCode)}</RegularText>,
      reason: (
        <Tooltip content={reasonLable}>
          <RegularText>{reasonLable}</RegularText>
        </Tooltip>
      ),
      status: <RegularText>{options.statusLabel.find((item) => item.value === dispute.status)?.label}</RegularText>,
      dueDate:
        disputeTable.status === 0 || disputeTable.status === 1 ? (
          <RegularText>{dispute.sellerResponseDueDate ? formatDate(dispute.sellerResponseDueDate) : 'Unconfimred'}</RegularText>
        ) : undefined,
      lastUpdate: <RegularText>{formatDate(dispute.updateTime)}</RegularText>,
      action: (
        <div className="dispute-view-btn">
          <Button
            onClick={() => {
              navigate({
                pathname: `${PATH.DISPUTE.pathname}/${dispute.id}`,
                search: PATH.DISPUTE.search,
              });
            }}
          >
            Details
          </Button>
        </div>
      ),
    };
  });

  const handleChangeInputSearch = (value: string) => {
    dispatch(slice.disputePageSlice.actions.handleSearchInput(value));
    debounceSetSearch({
      ...disputeTable,
      currentPage: 1,
      search: value,
    });
  };

  const handleSort = (value: string[]) => {
    const data = value[0].split(' ');
    dispatch(
      slice.disputePageSlice.actions.handleDisputeTable({
        ...disputeTable,
        sortBy: data[0],
        sort: data[1],
      }),
    );
  };

  return (
    <Card>
      <IndexFilters
        sortOptions={sortOptions}
        sortSelected={[`${disputeTable.sortBy} ${disputeTable.sort}`]}
        queryValue={searchInput}
        queryPlaceholder="Dispute ID / Transaction ID"
        onQueryChange={handleChangeInputSearch}
        onQueryClear={() => {
          handleChangeInputSearch('');
        }}
        onSort={handleSort}
        cancelAction={{
          onAction: () => {},
          disabled: false,
          loading: false,
        }}
        canCreateNewView={false}
        tabs={[]}
        selected={0}
        onSelect={() => {}}
        filters={filters}
        appliedFilters={appliedFilters.filter(
          (item) =>
            disputeTable[item.key as keyof typeof disputeTable].toString() !== 'all' &&
            disputeTable[item.key as keyof typeof disputeTable].toString() !== '0',
        )}
        onClearAll={() => {
          dispatch(
            slice.disputePageSlice.actions.handleDisputeTable({
              ...disputeTable,
              status: 0,
              reason: ['all'],
              account: ['all'],
              perPage: ['10'],
            }),
          );
        }}
        mode={mode}
        setMode={() => {}}
      />
      <IndexTable
        resourceName={resourceName}
        itemCount={disputes?.length || 0}
        emptyState={emptyStateMarkup}
        headings={
          disputeTable.status === 0 || disputeTable.status === 1
            ? [
                { title: 'Account' },
                { title: 'Dispute ID' },
                { title: 'Store' },
                { title: 'Order ID' },
                { title: 'Amount' },
                { title: 'Reason' },
                { title: 'Status' },
                { title: 'Due date' },
                { title: 'Last update' },
                { title: '' },
              ]
            : [
                { title: 'Account' },
                { title: 'Dispute ID' },
                { title: 'Store' },

                { title: 'Order ID' },
                { title: 'Amount' },
                { title: 'Reason' },
                { title: 'Status' },
                { title: 'Last update' },
                { title: '' },
              ]
        }
      >
        {disputes?.map(
          ({ account, action, amount, disputeId, status, dueDate, lastUpdate, orderId, reason, id, store }, index) => (
            <IndexTable.Row id={id} key={id} position={index}>
              <IndexTable.Cell>{account}</IndexTable.Cell>
              <IndexTable.Cell>{disputeId}</IndexTable.Cell>
              <IndexTable.Cell>{store}</IndexTable.Cell>
              <IndexTable.Cell>{orderId}</IndexTable.Cell>
              <IndexTable.Cell>{amount}</IndexTable.Cell>
              <IndexTable.Cell>{reason}</IndexTable.Cell>
              <IndexTable.Cell>{status}</IndexTable.Cell>

              {disputeTable.status === 0 || disputeTable.status === 1 ? <IndexTable.Cell>{dueDate}</IndexTable.Cell> : null}

              <IndexTable.Cell>{lastUpdate}</IndexTable.Cell>
              <IndexTable.Cell>{action}</IndexTable.Cell>
            </IndexTable.Row>
          ),
        )}
      </IndexTable>
      <div className="d-flex justify-content-center mt-16">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.itemsPerPage) + 1} to ${Math.min(
                    data.meta.currentPage * Number(data.meta.itemsPerPage),
                    data?.meta.totalItems,
                  )} of ${data?.meta.totalItems} disputes`
                : null
            }
            hasPrevious={!isFetching && data && data.meta.currentPage > 1}
            onPrevious={() => {
              dispatch(
                slice.disputePageSlice.actions.handleDisputeTable({
                  ...disputeTable,
                  currentPage: data ? data.meta.currentPage - 1 : 1,
                }),
              );
            }}
            hasNext={
              !isFetching && data && data.meta.currentPage < Math.ceil(data?.meta.totalItems / Number(disputeTable.perPage[0]))
            }
            onNext={() => {
              dispatch(
                slice.disputePageSlice.actions.handleDisputeTable({
                  ...disputeTable,
                  currentPage: data ? data.meta.currentPage + 1 : 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
    </Card>
  );
};

export default DisputeTable;
