import config from '@/config';
import { PATH } from '@/constants/path';
import { Frame, Link, Page, PageProps, Text } from '@shopify/polaris';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../menu';
import Toast from '../toast';
import { LayoutStyled } from './styled';

interface Props extends PageProps {
  isDetailPage?: boolean;
  isHiddenFooter?: boolean;
}

function Layout({ ...props }: Props) {
  const { pathname } = useLocation();
  const { embedded } = JSON.parse(config.urlParams);
  return (
    <LayoutStyled>
      <Frame topBar={embedded !== '1' && pathname !== PATH.WELCOME.pathname && <Menu />}>
        <Toast />
        <Page {...props}>
          <div className="page-content">{props.children}</div>
          {!props.isHiddenFooter ? (
            <Text as="p" variant="bodySm">
              <div className="footer">
                <div className="help-container">
                  <Link removeUnderline external url="https://docs.synctrack.io/">
                    Need help? View our docs
                  </Link>
                  <Link removeUnderline external url="https://synctrack.io/">
                    Visit our site
                  </Link>
                  <Link removeUnderline external url="https://blog.synctrack.io/">
                    Read our blogs
                  </Link>
                </div>
                <div className="about-us">
                  Love,{' '}
                  <Link removeUnderline external url="https://synctrack.io/about/">
                    Synctrack team
                  </Link>
                </div>
              </div>
            </Text>
          ) : null}
        </Page>
      </Frame>
    </LayoutStyled>
  );
}

export default memo(Layout);
