import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define the initial state using that type
const initialState = {
  step: 1,
};

const welcomePageSlice = createSlice({
  name: 'welcome',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const stepSelector = createSelector(
  (state: RootState) => state.welcome,
  (state) => state.step,
);

export default welcomePageSlice;
