import RegularText from '@/components/RegularText';
import { AlertInline } from '@/components/alertInline';
import SettingToggle from '@/components/settingToggle';
import { Plan } from '@/constants/enum';
import { LINK } from '@/constants/link';
import { PATH } from '@/constants/path';
import { disablePlan, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { ISettingsPage } from '@/types/components';
import { Link, Modal } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const OrderNotes = () => {
  const scope = useScope();

  const loadingSettings = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [updateNotesOrderMutation, { isLoading }] = apiCaller.useUpdateSaveDataSyncToShopifyMutation();
  const [state, setState] = useState<ISettingsPage.IStateOrderNotesSettings>({
    isOpenModal: false,
    scopeLink: '',
  });
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleToggle = useCallback(() => {
    updateNotesOrderMutation({ status: dataSettings?.saveDataToOrderNote === 0 ? 1 : 0 }).then((res) => {
      if ('data' in res) {
        if (res.data.state === 1) {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        } else {
          setState({
            ...state,
            isOpenModal: true,
            scopeLink: res.data.url,
          });
        }
      }
    });
  }, [dataSettings?.saveDataToOrderNote, dispatch, state, updateNotesOrderMutation]);
  const contentStatus = dataSettings?.saveDataToOrderNote === 1 ? 'Turn off' : 'Turn on';
  return (
    <div>
      <SettingToggle
        disableStatus={disablePlan([Plan.FREE, Plan.BASIC]) || scope.isViewOnly}
        settingToggleProps={{
          action: {
            onAction: handleToggle,
            content: contentStatus,
            loading: isLoading || loadingSettings,
            disabled: dataSettings?.plan === Plan.FREE || dataSettings?.plan === Plan.BASIC,
          },
          enabled: dataSettings?.saveDataToOrderNote === 1,
        }}
        title="Shopify order note"
      >
        <div>
          <div className="mt-8">
            <RegularText>
              Turn on to display synced tracking information as a note on your orders.
              <br />
              <Link url={LINK.guideShopifyOrderNotes} removeUnderline external>
                View instruction and example
              </Link>
            </RegularText>
          </div>
          {dataSettings?.plan === Plan.FREE || dataSettings?.plan === Plan.BASIC ? (
            <div className="mt-8">
              <AlertInline
                content="This function is only available when using"
                link="Pro plan or higher"
                navigate={() => {
                  navigate(PATH.ACCOUNT, {
                    state: {
                      prePathName: 'orderNotes',
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </SettingToggle>

      <Modal
        open={state.isOpenModal}
        title="Need more scopes!"
        onClose={() => {
          setState({ ...state, isOpenModal: false });
        }}
        sectioned
        primaryAction={{
          content: 'Grant more scopes',
          external: true,
          url: state.scopeLink,
          onAction: () => {
            setState({ ...state, isOpenModal: false });
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setState({ ...state, isOpenModal: false });
            },
          },
        ]}
      >
        <RegularText>To use this feature, we need additional permission to edit your order information.</RegularText>
      </Modal>
    </div>
  );
};

export default OrderNotes;
