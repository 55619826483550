import { IResponseApi } from '@/types/api/response.api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
interface IState {
  dataSettings: IResponseApi.IGetDataSettingsResponse | null;
  loading: boolean;
  listStore: Array<IResponseApi.Store> | null;
  listPaypal: Array<IResponseApi.PayPalAccount> | null;
}
// Define the initial state using that type
const initialState: IState = {
  dataSettings: null,
  loading: false,
  listStore: null,
  listPaypal: null,
};

export const masterDataSlice = createSlice({
  name: 'masterData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleDataSettings: (state, action: PayloadAction<IResponseApi.IGetDataSettingsResponse>) => {
      state.dataSettings = action.payload;
    },
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    handleListStore: (state, action: PayloadAction<Array<IResponseApi.Store>>) => {
      state.listStore = action.payload;
    },
    handleListPaypal: (state, action: PayloadAction<Array<IResponseApi.PayPalAccount>>) => {
      state.listPaypal = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const dataSettingsSelector = createSelector(
  (state: RootState) => state.masterData,
  (state) => state.dataSettings,
);

export const loadingSelector = createSelector(
  (state: RootState) => state.masterData,
  (state) => state.loading,
);
export const listStoreSelector = createSelector(
  (state: RootState) => state.masterData,
  (state) => state.listStore,
);

export const listPaypalSelector = createSelector(
  (state: RootState) => state.masterData,
  (state) => state.listPaypal,
);
export default masterDataSlice;
