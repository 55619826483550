import styled from 'styled-components';

export const AccountPlanStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .account-subcription-btn {
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }
  .account-plan-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    padding: 1rem;
    height: 100%;
    width: 100%;
    .Polaris-Icon {
      margin: 0;
      height: 1.25rem;
      width: 1.25rem;
    }
    &-btn {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      border-top: 1px solid #e9e9e9;
    }
  }
  .account-benefit {
    padding: 0.5rem 0;
    border-top: 1px solid #e9e9e9;
  }
  .account-free-left {
    display: flex;
    align-items: center;
  }
  .account-free-right {
    .account-free-benefit {
      display: flex;
      .Polaris-Icon {
        margin: 0;
        margin-right: 0.25rem;
      }
    }
  }
  .account-support-right {
    &-title {
      display: flex;
      .Polaris-Icon {
        margin: 0;
        margin-right: 0.5rem;
      }
    }
  }
  .discount-btn {
    text-align: center;
  }
  .mt-8 {
    margin-top: 0.5rem;
  }
  .w-100 {
    width: 100%;
  }
`;
