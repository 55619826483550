import BoldText from '@/components/BoldText';
import { IDispute } from '@/types/components';
import { Badge, Divider, InlineGrid, Link, Text } from '@shopify/polaris';
import { memo } from 'react';
interface Props extends IDispute.IDisputeColumnProps {
  titleClass?: string;
  isLink?: boolean;
  url?: string;
  isHidenDivider?: boolean;
  isSuccess?: boolean;
}

const CustomColumn = ({ ...props }: Props) => {
  return (
    <div className="custom-columns">
      <InlineGrid gap="0" columns={2}>
        <BoldText>{props.title}</BoldText>
        <Text as="p" variant="bodySm" breakWord>
          {props.isLink ? (
            <Link removeUnderline url={props.url} external>
              {props.value || '---'}
            </Link>
          ) : props.isSuccess ? (
            <Badge tone="success">Resolved</Badge>
          ) : (
            props.value || '---'
          )}
        </Text>
      </InlineGrid>
      {!props.isHidenDivider && <Divider borderColor="border" />}
    </div>
  );
};

export default memo(CustomColumn);
