import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';
export const LayoutStyled = styled.div`
  .Polaris-Page {
    padding-bottom: 2rem;
  }
  .secondary-btn {
    display: flex;
  }
  .ml-16 {
    margin-left: 1rem;
  }
  .noti-btn-container {
    position: relative;
  }
  .notification-count {
    background-color: red;
    position: absolute;
    color: #f6f6f7;
    height: 1rem;
    width: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 50%;
    right: -9px;
    top: -9px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .help-container {
      margin-top: 1rem;
      display: flex;
      justify-content: space-around;
      width: 500px;
    }
    .about-us {
      text-align: center;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: ${BREAKPOINT.XS}px) {
    .footer {
      .help-container {
        width: 100vw;
        flex-direction: column;
        align-items: center;
        line-height: 1.5rem;
      }
    }
    .page-content {
      padding: 0 0.5rem;
    }
  }
`;
