import RegularText from '@/components/RegularText';
import { convertAmount } from '@/helpers';
import { IDispute } from '@/types/components';
import { Banner } from '@shopify/polaris';

export const BannerOffer = ({ ...props }: IDispute.IDisputeOfferBannerProps) => {
  return (
    <Banner title={`Offer details: ${props.title}`}>
      {props.refund && props.currencyCode ? (
        <RegularText>
          Refund amount:&nbsp;
          <b>{convertAmount(props.refund, props.currencyCode)}</b>
        </RegularText>
      ) : null}
      {props.address ? (
        <RegularText>
          Shipping address for returns:&nbsp;
          <b>{props.address}</b>
        </RegularText>
      ) : null}
      {props.content ? (
        <RegularText>
          <b>{props.content}</b>
        </RegularText>
      ) : null}
    </Banner>
  );
};
