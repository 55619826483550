import { CircleAlertMajor } from '@shopify/polaris-icons';
import { ArlertInlineStyled } from './styled';
import { Icon, Text, Link } from '@shopify/polaris';

export const AlertInline = ({ ...props }: { content: string; navigate?: any; link?: string }) => {
  return (
    <ArlertInlineStyled className="alertText">
      <div className="mr-8">
        <Icon source={CircleAlertMajor} tone="warning" />
      </div>
      <Text tone="caution" as="h3" variant="bodySm">
        {`${props.content} `}
        {props.link && props.navigate ? (
          <Link removeUnderline onClick={() => props.navigate()}>
            {props.link}
          </Link>
        ) : null}
      </Text>
    </ArlertInlineStyled>
  );
};
