import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
export const initialState = {
  checkoutRulesBanner: true,
  isShowedHomePage: false,
  isClickReturn: false,
  isClickOT: false,
  isClickCheckoutRules: false,
  isClickEstimated: false,
  isShowPaypalSetting: false,
  isShowStripeSetting: false,
  isAddDiscount: false,
  isShowPricing: false,
  isChoosePlan: false,
};

const bannerSlice = createSlice({
  name: 'banner',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleBanner: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState;
        value: boolean;
      }>,
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type

export const checkoutRulesBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.checkoutRulesBanner,
);
export const CrossSellSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state,
);
export default bannerSlice;
