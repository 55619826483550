import config from '@/config';
import constants from '@/constants';
import { App, UserPermission } from '@/constants/enum';
import { apiAdminCaller } from '@/redux/query/admin';
import { useLocation } from 'react-router-dom';

const useScope = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const profile = apiAdminCaller.useFetchProfileQuery(undefined, { skip: config.source !== 'admin' || !config.token });
  let rules;
  if (
    constants.CONSTANS.isEmbedded ||
    urlSearchParams.get('hmac') ||
    config.code ||
    location.pathname.includes('handle_request')
  ) {
    rules = {
      isAccessApp: true,
      isViewOnly: false,
      isLoading: false,
    };
  } else {
    rules = {
      isAccessApp: !!profile.data?.data.user.apps.includes(App.Synctrack),
      isViewOnly: !profile.data?.data.user.permissions.includes(UserPermission.Edit),
      isLoading: profile.isLoading,
    };
  }
  return rules;
};
export default useScope;
