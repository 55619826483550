import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { Card, Icon, InlineGrid, Text } from '@shopify/polaris';

import { AnalyticsMinor, DisputeMinor, FraudProtectUnprotectedMajor } from '@shopify/polaris-icons';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
const Analysis = ({ fromDate, toDate }: { fromDate: Date; toDate: Date }) => {
  const isSkip = useSelector(isSkipApiSelector);

  const { data } = apiCaller.useFetchDisputeAnalysisQuery(
    {
      fromDate: dateToTimeStamp(fromDate),
      toDate: dateToTimeStamp(toDate),
    },
    { skip: isSkip },
  );

  const dataAnalysisItems = useMemo(() => {
    return [
      {
        value: data?.dataAnalysis.total || 0,
        icon: DisputeMinor,
        title: 'Total disputes',
        key: 'total',
      },
      {
        value: data?.dataAnalysis.open || 0,
        icon: FraudProtectUnprotectedMajor,
        title: 'Open disputes',
        key: 'open',
      },
      {
        value: `${data?.dataAnalysis.sellerWin || 0} / ${data?.dataAnalysis.buyerWin || 0}`,
        icon: AnalyticsMinor,
        title: 'Win/Lose ratio',
        key: 'ratio',
      },
    ];
  }, [data]);

  return (
    <InlineGrid columns={{ xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }} gap={'400'}>
      {dataAnalysisItems.map((item) => {
        return (
          <Card key={item.key}>
            <div className="analytics-items-title">
              <Icon source={item.icon} tone="base" />
              <Text fontWeight="medium" as="h5" variant="headingSm">
                {item.title}
              </Text>
            </div>
            <div className="mt-8 ml-4">
              <Text as="h5" variant="heading2xl" fontWeight="regular">
                {item.value}
              </Text>
            </div>
          </Card>
        );
      })}
    </InlineGrid>
  );
};

export default Analysis;
