import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IOrderTable } from '@/types/components';
import { getSomeDaysAgo } from '@/helpers';
import { OrderStatus } from '@/constants/enum';
const toDate = new Date();
toDate.setHours(23, 59, 59, 99);
// Define the initial state using that type
const initialState = {
  searchInput: '',
  orderTable: {
    currentPage: 1,
    search: '',
    fromDate: getSomeDaysAgo(30),
    toDate: toDate,
    statusSelected: [OrderStatus.All],
    paymentSelected: ['paypal'],
    perPage: ['10'],
    storeSelected: ['all'],
    sort: 'desc',
    sortBy: 'createdAt',
  },
  titleBtnDatePicker: 'Last 30 days',
};

const orderPageSlice = createSlice({
  name: 'orderPage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleFiltersOrderTable: (state, action: PayloadAction<IOrderTable.IState>) => {
      state.orderTable = action.payload;
    },
    handleSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const orderTableOrderPageSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.orderTable,
);

export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.titleBtnDatePicker,
);
export const listStoreSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.orderTable.storeSelected,
);
export const searchInputSelector = createSelector(
  (state: RootState) => state.order,
  (state) => state.searchInput,
);
export default orderPageSlice;
