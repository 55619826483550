import { Text } from '@shopify/polaris';
import { memo } from 'react';

const BoldText = ({ children }: any) => {
  return (
    <Text as="h6" variant="headingSm">
      {children}
    </Text>
  );
};

export default memo(BoldText);
