import { Modal } from '@shopify/polaris';
import styled from 'styled-components';

export const QuickHelpStyled = styled(Modal)`
  .modal-plan-table {
    .Polaris-Box {
      background-color: #008060;
    }
  }
`;

export const QuickHelpItemStyled = styled.div`
  margin-bottom: 1rem;
  span {
    line-height: 1.25rem;
  }
  .quick-help-heading {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading-title {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      .Polaris-Icon {
        margin: 0;
        margin-right: 0.5rem;
      }
    }
  }
  .solution-title {
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.5rem;
    }
  }

  .quick-help-heading,
  .quick-help-heading-solution {
    margin: 4px 0;
  }

  .quick-help-heading,
  .quick-help-heading > h2,
  .quick-help-heading-solution,
  .quick-help-heading-solution > h2 {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .quick-help-heading h2 > span,
  .quick-help-heading-solution h2 > span {
    margin-right: 0.5rem;
  }

  .quick-help-line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0.5rem 0;
  }

  .quick-help-content .underline {
    text-decoration: underline;
  }
  .quick-help-inner {
    margin-left: 1.75rem;
  }
  .quick-help-inner button {
    margin-top: 0.5rem;
  }
`;
