import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { AlertInline } from '@/components/alertInline';
import CustomCard from '@/components/card';
import config from '@/config';
import { CourierRule, Plan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { ISettingsPage } from '@/types/components';
import {
  Autocomplete,
  Button,
  EmptyState,
  Icon,
  InlineGrid,
  Modal,
  Select,
  SkeletonBodyText,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CourierMappingStyled } from './styled';
import CustomButton from '@/components/CustomButton';
import useScope from '@/hooks/Scope';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const optionsRule = [
  { label: 'Courier Name', value: CourierRule.NAME },
  { label: 'Tracking number start with', value: CourierRule.START_WITH },
  { label: 'Tracking number end with', value: CourierRule.END_WITH },
];

const CourierMapping = () => {
  const scope = useScope();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState({ status: false, id: '-1' });
  const dataSettings = useSelector(dataSettingsSelector);
  const isLoading = useSelector(loadingSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const fetchListPayPalCourier = apiCaller.useFetchListPayPalCourierQuery(config.shop, { skip: isSkip });
  const [deleteMappingById, deleteMappingStatus] = apiCaller.useDeleteMappingByIdMutation();
  const [saveMapping, saveMappingStatus] = apiCaller.useUpdateMappingMutation();
  const [state, setState] = useState<ISettingsPage.IStateCourierMappingSetting>({
    listMapping: [],
    id: undefined,
    isOpenModal: false,
    rule: CourierRule.NAME,
    yourCourier: '',
    paypalCourier: '',
    errText: {
      yourCourier: '',
      paypalCourier: '',
    },
    options: {
      yourCourier: [],
      paypalCourier: [],
    },
  });

  const clearState = () => {
    setState({
      ...state,
      id: undefined,
      isOpenModal: false,
      rule: CourierRule.NAME,
      yourCourier: '',
      paypalCourier: '',
      errText: {
        yourCourier: '',
        paypalCourier: '',
      },
    });
  };

  useEffect(() => {
    setState({
      ...state,
      listMapping: fetchListPayPalCourier.data?.listMapping || [],
      options: {
        yourCourier: fetchListPayPalCourier.data?.listCourier,
        paypalCourier: fetchListPayPalCourier.data?.listCourier,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListPayPalCourier]);

  const handleSaveMapping = () => {
    saveMapping({
      type: state.rule,
      courier: state.yourCourier,
      paypalCourier: state.paypalCourier,
      id: state.id,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setState({
          ...state,
          errText: {
            ...state.errText,
            yourCourier: condition.msg,
          },
        });
      } else {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        clearState();
      }
    });
  };

  const handleAddMappingMessage = () => {
    if (dataSettings && fetchListPayPalCourier.data) {
      if (dataSettings.plan === Plan.FREE) {
        return 'This function is only available when using Basic plan or higher.';
      }
      if (dataSettings.plan === Plan.BASIC && fetchListPayPalCourier.data.listMapping.length > 1) {
        return 'Basic plan can only add up to 2 rules. If you want to add more rules.';
      }
      if (dataSettings.plan === Plan.PRO && fetchListPayPalCourier.data.listMapping.length > 9) {
        return 'Pro plan can only add up to 10 rules.  If you want to add more rules.';
      }
    }
  };

  const addMapping = () => {
    setState({ ...state, isOpenModal: true, paypalCourier: '', yourCourier: '', rule: CourierRule.NAME, id: undefined });
  };

  const editMapping = (id: string) => {
    const courierSelected = state.listMapping.find((item) => item.id === id);
    if (courierSelected) {
      setState({
        ...state,
        isOpenModal: true,
        paypalCourier: courierSelected.paypalCourier,
        yourCourier: courierSelected.courier,
        rule: courierSelected.type,
        id,
      });
    }
  };

  const deleteMapping = (id: string) => () => {
    deleteMappingById({ id }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        setIsOpenDeleteAccountModal({ status: false, id: '-1' });
      }
    });
  };

  const handleCourier = useCallback(
    (type: 'yourCourier' | 'paypalCourier') => (value: string) => {
      const errText = {
        ...state.errText,
        yourCourier: type === 'yourCourier' ? '' : state.errText.yourCourier,
      };
      const resultOptions = fetchListPayPalCourier.data?.listCourier?.filter((option) =>
        option.label.toUpperCase().includes(value.toUpperCase()),
      );
      setState({
        ...state,
        [type]: value,
        errText,
        options: {
          ...state.options,
          [type]: resultOptions,
        },
      });
    },
    [fetchListPayPalCourier.data?.listCourier, state],
  );

  const updateSelection = useCallback(
    (type: 'yourCourier' | 'paypalCourier') => (selected: string[]) => {
      setState({
        ...state,
        [type]: selected[0],
      });
    },
    [state],
  );

  const checkCondition = useMemo(() => {
    return (
      dataSettings?.plan === Plan.FREE ||
      (dataSettings?.plan === Plan.BASIC && fetchListPayPalCourier.data && fetchListPayPalCourier.data.listMapping.length > 1) ||
      (dataSettings?.plan === Plan.PRO && fetchListPayPalCourier.data && fetchListPayPalCourier.data.listMapping.length > 9)
    );
  }, [dataSettings?.plan, fetchListPayPalCourier.data]);

  const yourCourierTextField = (
    <Autocomplete.TextField
      onChange={handleCourier('yourCourier')}
      value={state.yourCourier}
      label="Your courier name"
      error={state.errText.yourCourier}
      placeholder={
        state.rule === CourierRule.NAME
          ? 'Example: Yunexpress'
          : state.rule === CourierRule.START_WITH
          ? 'Example: 1ZA'
          : 'Example: HK'
      }
      autoComplete="off"
      maxLength={255}
    />
  );
  const paypalTextField = (
    <Autocomplete.TextField
      onChange={handleCourier('paypalCourier')}
      value={state.paypalCourier}
      label="PayPal courier"
      error={state.errText.paypalCourier}
      placeholder="Example: UPS"
      autoComplete="off"
      maxLength={255}
    />
  );
  return (
    <CourierMappingStyled>
      <CustomCard
        title="Courier mapping rules"
        actions={
          state.listMapping.length > 0
            ? {
                content: 'Add mapping rules',
                action: addMapping,
                buttonProps: {
                  variant: 'primary',
                  disabled: checkCondition || scope.isViewOnly,
                },
              }
            : undefined
        }
      >
        {checkCondition ? (
          <AlertInline
            content={handleAddMappingMessage() || ''}
            link="Upgrade higher plan"
            navigate={() => {
              navigate(PATH.ACCOUNT, {
                state: {
                  prePathName: 'mappingCourier',
                },
              });
            }}
          />
        ) : null}
        <div className={`${checkCondition ? 'w-100 mt-16' : 'w-100'} `}>
          {state.listMapping.length > 0 ? (
            <InlineGrid columns={4}>
              <BoldText>Type</BoldText>
              <BoldText>Input</BoldText>
              <BoldText>Output</BoldText>
            </InlineGrid>
          ) : null}

          {fetchListPayPalCourier.isLoading || isLoading ? (
            <div className="mt-16">
              <SkeletonBodyText />
            </div>
          ) : (
            <div className="courier-mapping-content">
              {state.listMapping.length > 0 ? (
                state.listMapping.map((item, index) => {
                  return (
                    <InlineGrid key={index} columns={4} gap={'200'}>
                      <RegularText>
                        {item.type === 'name'
                          ? 'Courier name'
                          : item.type === 'start'
                          ? 'Tracking number start with'
                          : 'Tracking number end with'}
                      </RegularText>
                      <RegularText>{item.courier}</RegularText>
                      <RegularText>{item.paypalCourier}</RegularText>
                      <div className="btn-group">
                        <Tooltip content="Edit">
                          <CustomButton
                            onClick={() => editMapping(item.id)}
                            icon={() => {
                              return <Icon source={EditMinor} />;
                            }}
                          />
                        </Tooltip>

                        <div className="ml-8">
                          <Tooltip content="Delete">
                            <CustomButton
                              loading={deleteMappingStatus.isLoading}
                              tone="critical"
                              onClick={() => {
                                setIsOpenDeleteAccountModal({ id: item.id, status: true });
                              }}
                              icon={<Icon source={DeleteMinor} />}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </InlineGrid>
                  );
                })
              ) : (
                <div>
                  <EmptyState
                    heading="Need to change the courier name that is synced to PayPal?"
                    action={{ content: 'Add mapping rules', onAction: addMapping, disabled: checkCondition || scope.isViewOnly }}
                    secondaryAction={{
                      content: 'Learn more',
                      external: true,
                      url: 'https://docs.synctrack.io/feature-guides/how-to-add-courier-mapping-rule',
                    }}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                  >
                    <RegularText>
                      The courier mapping feature will help convert incorrect carrier names, set as "other", or tracking links to
                      the correct carrier name.
                    </RegularText>
                  </EmptyState>
                </div>
              )}
            </div>
          )}
        </div>

        <Modal
          open={state.isOpenModal}
          onClose={clearState}
          title="Courier Mapping"
          primaryAction={{
            content: 'Save',
            onAction: handleSaveMapping,
            loading: saveMappingStatus.isLoading,
            disabled: !state.paypalCourier || !state.yourCourier,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: clearState,
            },
          ]}
        >
          <Modal.Section>
            <div>
              <Select
                label="Mapping type"
                options={optionsRule}
                onChange={(value: CourierRule) =>
                  setState({
                    ...state,
                    rule: value,
                    errText: {
                      paypalCourier: '',
                      yourCourier: '',
                    },
                  })
                }
                value={state.rule}
              />
            </div>

            <div className="mt-8">
              {state.rule === CourierRule.NAME ? (
                <Autocomplete
                  options={state.options.yourCourier || []}
                  selected={[state.yourCourier]}
                  onSelect={updateSelection('yourCourier')}
                  textField={yourCourierTextField}
                />
              ) : (
                <TextField
                  label={state.rule === CourierRule.START_WITH ? 'Tracking number start with' : 'Tracking number end with'}
                  value={state.yourCourier}
                  maxLength={255}
                  onChange={(value) => {
                    setState({ ...state, yourCourier: value });
                  }}
                  placeholder={state.rule === CourierRule.START_WITH ? 'Example: 1ZA' : 'Example: HK'}
                  error={state.errText.yourCourier}
                  autoComplete="off"
                />
              )}
            </div>
            <div className="mt-8">
              <Autocomplete
                options={state.options.paypalCourier || []}
                selected={[state.paypalCourier]}
                onSelect={updateSelection('paypalCourier')}
                textField={paypalTextField}
              />
            </div>
          </Modal.Section>
        </Modal>
      </CustomCard>
      <Modal
        open={isOpenDeleteAccountModal.status}
        title={'Are you sure?'}
        onClose={() => {
          setIsOpenDeleteAccountModal({ id: '-1', status: false });
        }}
        sectioned
        footer={
          <div className="modal-footer">
            <Button
              onClick={() => {
                setIsOpenDeleteAccountModal({ id: '-1', status: false });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                onClick={deleteMapping(isOpenDeleteAccountModal.id)}
                variant="primary"
                tone="critical"
                loading={deleteMappingStatus.isLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>You can add this setting again when you need it again</RegularText>
      </Modal>
    </CourierMappingStyled>
  );
};

export default CourierMapping;
