import BoldText from '@/components/BoldText';
import Layout from '@/components/layout';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { loadingSelector } from '@/redux/slice/masterData.slice';
import { stepSelector } from '@/redux/slice/welcome.slice';
import { Card, InlineGrid, SkeletonBodyText } from '@shopify/polaris';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StepFive from './components/StepFive';
import StepFour from './components/StepFour';
import StepOne from './components/StepOne';
import StepThree from './components/StepThree';
import StepTwo from './components/StepTwo';
import { WelcomeStyled } from './styled';

const welcomeConfig = [
  {
    title: 'Welcome',
    component: <StepOne />,
  },
  {
    title: 'Connect to PayPal',
    component: <StepTwo />,
  },
  {
    title: 'Payment Hub',
    component: <StepThree />,
  },
  {
    title: 'Sync old orders',
    component: <StepFour />,
  },
  {
    title: 'Finish',
    component: <StepFive />,
  },
];

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const step = useSelector(stepSelector);
  const [updateWelcomeStatus] = apiCaller.useUpdateWelcomStatusMutation();
  const [updateWelcomeStep] = apiCaller.useUpdateWelcomStepMutation();
  const isLoading = useSelector(loadingSelector);
  const stepSelected = useMemo(() => {
    if (step === 0) return <StepOne />;
    return welcomeConfig.find((_, index) => index + 1 === step)?.component;
  }, [step]);
  const handleChangeStep = (value: number) => () => {
    dispatch(slice.WelcomeSlice.actions.handleStep(value));
    updateWelcomeStep({ step: value });
  };
  const backAction = () => {
    updateWelcomeStatus().then(() => {
      navigate(PATH.DEFAULT);
    });
  };

  return (
    <Layout backAction={{ onAction: backAction, content: 'Skip onboarding' }} title="Getting started">
      <WelcomeStyled>
        <Card>
          <div className="welcome-process-container">
            <div className="welcome-process" style={{ maxWidth: `${(step - 1) * 20}%`, transition: 'all .5s' }} />
          </div>
          <div className="welcome-underline">
            <InlineGrid gap="400" columns={5}>
              {welcomeConfig.map(({ title }, index) => {
                return (
                  <div key={index} onClick={handleChangeStep(index + 1)} className="mt-8">
                    <BoldText>{`${index + 1}.${title}`}</BoldText>
                  </div>
                );
              })}
            </InlineGrid>
          </div>

          <div className="mt-16">{isLoading ? <SkeletonBodyText lines={8} /> : stepSelected}</div>
        </Card>
      </WelcomeStyled>
    </Layout>
  );
};

export default memo(Welcome);
