import { OrderStatus, Payment } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { camelToTitle } from '@/helpers';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { Card, Icon, IconSource, InlineGrid, Text, Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface DataProps {
  value: number;
  payment: Payment;
  status: OrderStatus;
  title?: string;
  icon: IconSource;
}

interface Props {
  paypal: {
    fullfilledOrders: DataProps;
    ['paypal &StripeOrders']: DataProps;
    otherGateways: DataProps;
    paypalOrders: DataProps;
    paypalSynced: DataProps;
    paypalSyncError: DataProps;
  };
  stripe: {
    stripeOrders: DataProps;
    stripeSynced: DataProps;
    stripeSyncError: DataProps;
  };
}

const AnalyticsOrders = ({ ...props }: Props) => {
  const dataSettings = useSelector(dataSettingsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderTable = useSelector(orderTableOrderPageSelector);
  const handleClick = (data: DataProps) => () => {
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...orderTable,
        paymentSelected: [data.payment],
        statusSelected: [data.status],
        currentPage: 1,
      }),
    );
    navigate(PATH.ORDERS);
  };
  return (
    <div className="analytics-orders-wrapper">
      <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
        {Object.entries(props.paypal).map(([key, value]) => {
          return (
            <Card key={key}>
              <div className="analytics-items-title" onClick={handleClick(value)}>
                <Icon source={value.icon} tone="base" />
                <Tooltip content="View list detail">
                  <Text fontWeight="medium" as="h6" variant="headingSm">
                    {value.title || camelToTitle(key)}
                  </Text>
                </Tooltip>
              </div>
              <div className="mt-8 ml-4">
                <Text as="h5" variant="heading2xl" fontWeight="regular">
                  {value.value}
                </Text>
              </div>
            </Card>
          );
        })}
        {dataSettings?.syncStripe
          ? Object.entries(props.stripe).map(([key, value]) => {
              return (
                <Card key={key}>
                  <div className="analytics-items-title" onClick={handleClick(value)}>
                    <Icon source={value.icon} tone="base" />
                    <Text fontWeight="medium" as="h6" variant="headingSm">
                      {value.title || camelToTitle(key)}
                    </Text>
                  </div>
                  <div className="mt-8 ml-4">
                    <Text as="h5" variant="heading2xl" fontWeight="regular">
                      {value.value}
                    </Text>
                  </div>
                </Card>
              );
            })
          : null}
      </InlineGrid>
    </div>
  );
};

export default memo(AnalyticsOrders);
