import RegularText from '@/components/RegularText';
import { LINK } from '@/constants/link';
import { IHomePage } from '@/types/components';

export const configQuickHelp: Array<IHomePage.IQuickHelpConfig> = [
  {
    title: 'Orders in queue',
    content: [
      <RegularText key={1}>
        There are 2 reasons which lead in queue orders not synced to PayPal/Stripe
        <br />
        <span className="underline">Reason 1:</span> The system is processing synchronization which takes from 1 to 3 minutes.
        Please wait for few seconds
        <br />
        <span className="underline">Reason 2:</span> Your tracking numbers are not in the correct format. PayPal limits the amount
        of tracking number's letters, only accepts 25 letters. Therefore, if you use tracking link instead of tracking number,
        Synctrack would notice that this order is in queue.
      </RegularText>,
    ],
    solution: [
      <RegularText key={2}>
        In case of seeing the orders are in queue after 5 minutes syncing, contact us through live chat support and send us
        <br />
        Transaction ID of the order. Our expert team will help to fix this issue ASAP.
      </RegularText>,
    ],
    button: 'Contact us',
    keyValue: 'orderPending',
  },
  {
    title: 'Over quota',
    content: [
      <RegularText key={3}>
        Your plan is on .... This plan supports ... trackings/month.
        <br />
        This month your store has ... orders. Therefore, our app sucessfully synced ..... for you while ... orders are not
        supported because of over quota for this subscription.
      </RegularText>,
    ],
    solution: [
      <RegularText key={4}>
        <p>Please upgrade to the above plans to make sure syncing process happens fluently without any issues.</p>
      </RegularText>,
    ],

    button: 'Upgrade now',
    keyValue: 'overQuota',
  },
  {
    title: 'Invalid tracking number',
    content: [
      <RegularText key={5}>
        Tracking number is wrong format or tracking number is too long or contains special characters
      </RegularText>,
    ],
    button: 'Contact us',
    keyValue: 'invalidTrackingNumber',
  },
  {
    title: 'No or invalid account',
    content: [
      <RegularText key={6}>
        There are 2 reasons which lead to status of "No Account or Invalid
        <br />
        <span className="underline">Reason1:</span> You have not connected any PayPal/ Stripe account with Synctrack
      </RegularText>,
    ],
    solution: [
      <RegularText key={7}>
        Connect your PayPal/ Stripe with Synctrack. Make sure that your account is the correct one which you use for transactions
        from your Shopify store
        <br />
        <div className="quick-help-line" />
        <span className="underline">Reason 2:</span> You have not connected any PayPal/ Stripe account with Synctrack
      </RegularText>,
    ],
    solution2: [
      <RegularText key={8}>
        Add Stripe token or Replace the new token by navigating to Settings/ Stripe settings and carefully read this instruction
        of how to get Stripe token{' '}
        <a href={LINK.getStripeToken} target="_blank" rel="noreferrer">
          here
        </a>
      </RegularText>,
    ],
    button: 'Contact us',
    keyValue: 'unconfirmAcc',
  },
  {
    title: 'No tracking number',
    content: [
      <RegularText key={9}>
        There are 2 reasons which lead to orders having no tracking numbers and they can not synced to PayPal/Stripe
        <br />
        <span className="underline">Reason 1:</span> Your orders are fulfilled already but the couriers are not delivering those
        orders. Therefore, those orders are not provided with tracking numbers. As soon as the orders have tracking numbers from
        couriers, Synctrack automatically real-time syncs them to PayPal.
        <br />
        <span className="underline">Reason 2:</span> If you are not using any fullfilment app which automatically fulfills orders
        and provides tracking numbers for you on Shopify, you have to manually add tracking number to Shopify by yourself.
      </RegularText>,
    ],
    solution: [
      <RegularText key={10}>
        Wait for the fulfillment party provides tracking number or add tracking number on Shopify manually, click{' '}
        <a href={LINK.addTrackingNumberToOrder} target="_blank" rel="noreferrer">
          here
        </a>{' '}
        for the instruction.
      </RegularText>,
    ],
    button: 'Contact us',
    keyValue: 'noTracking',
  },
  {
    title: 'No transaction ID',
    content: [
      <RegularText key={11}>
        There are 2 reasons which lead to orders having no transaction ID and they can not synced to PayPal/Stripe
        <br />
        <span className="underline">Reason 1:</span> You may be using a third - party app relating to checkout issues and this
        kind of app doens't return transaction ID. <br />
        Example: If you are using Checkify, Checkify doesn't return transaction ID, therefore, Synctrack need to integrate with
        Checkify to get transaction ID and process syncing to PayPal.
      </RegularText>,
    ],
    solution: [
      <RegularText key={12}>
        Go to tab Integration in Synctrack, check if Synctrack supports your third party app. If Synctrack doesn't support your
        third-party app, please contact us through livechat, we do it for free.
        <div className="quick-help-line"></div>
        <br />
        <span className="underline">Reason 2:</span> Your customer's credit card has not enough funds. This transaction is going
        to be cancelled.
      </RegularText>,
    ],
    solution2: [<RegularText key={14}>Send an email to your customers in this case to remind them.</RegularText>],
    button: 'Contact us',
    keyValue: 'noTransaction',
  },
  {
    title: 'Missing PayPal account',
    content: [
      <RegularText key={13}>
        Your PayPal/ Stripe account which is used for transactions from your Shopify store is not the same as the PayPal/ Stripe
        account connected with Synctrack.
      </RegularText>,
    ],
    solution: [<RegularText key={15}>Make sure you are connecting this PayPal account:</RegularText>],
    button: 'Contact us',
    keyValue: 'invalidAccount',
  },
];
