import PermissionDenied from '@/components/PermissionDenied';
import SkeletonPage from '@/components/SkeletonPage';
import Layout from '@/components/layout';
import { PATH } from '@/constants/path';
import HandleRequest from '@/helpers/HandlePaypalConnected';
import useScope from '@/hooks/Scope';
import AccountPlan from '@/pages/AccountPlan';
import Analytics from '@/pages/Analytics';
import DashBoard from '@/pages/Dashboard';
import Orders from '@/pages/Orders';
import Welcome from '@/pages/Welcome';
import Dispute from '@/pages/dispute';
import DisputeDetail from '@/pages/dispute/disputeDetail';
import Settings from '@/pages/settings';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

interface IRouter {
  path: string;
  element: React.ReactNode;
}

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: (
      <Layout title="Synctrack">
        <DashBoard />
      </Layout>
    ),
  },
  {
    path: PATH.ANALYTICS.pathname,
    element: <Analytics />,
  },
  {
    path: PATH.ORDERS.pathname,
    element: <Orders />,
  },
  {
    path: PATH.DISPUTE.pathname,
    element: (
      <Layout title="Disputes">
        <Dispute />
      </Layout>
    ),
  },
  {
    path: `${PATH.DISPUTE.pathname}/:id`,
    element: <DisputeDetail />,
  },
  {
    path: PATH.SETTINGS.pathname,
    element: (
      <Layout isHiddenFooter title="Settings">
        <Settings />
      </Layout>
    ),
  },
  {
    path: PATH.WELCOME.pathname,
    element: <Welcome />,
  },
  {
    path: PATH.ACCOUNT.pathname,
    element: (
      <Layout title="Subscription plans">
        <AccountPlan />
      </Layout>
    ),
  },
  {
    path: PATH.HANDLE_REQUEST.pathname,
    element: <HandleRequest />,
  },
];
function RenderRouter(): JSX.Element {
  const navigate = useNavigate();
  const scope = useScope();
  const { pathname } = useLocation();
  const setScope = pathname.includes('handle_request')
    ? {
        isAccessApp: true,
        isViewOnly: false,
        isLoading: false,
      }
    : scope;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return setScope.isLoading ? (
    <SkeletonPage />
  ) : setScope.isAccessApp ? (
    <Routes>
      {router.map((item: IRouter) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
      <Route path="*" element={<Navigate to={PATH.DEFAULT} replace />} />
    </Routes>
  ) : (
    <PermissionDenied />
  );
}
export default RenderRouter;
