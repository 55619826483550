import { CONSTAN } from '@/constants/constan';
import { IDispute } from '@/types/components';
import { Text, DropZone, Icon, LegacyStack, Thumbnail } from '@shopify/polaris';
import { CancelMajor, CircleAlertMajor, DiamondAlertMajor, NoteMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { UploadFileStyled } from './styled';

function UploadFile({ ...props }: IDispute.IUploadFileProps) {
  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '' });
  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      const files = [...props.files, ...acceptedFiles];
      if (files.length <= 5) {
        props.onSave(files);
      }
    },
    [props],
  );

  const renderSource = (file: any) => {
    if (file.type === 'application/pdf') return NoteMinor;
    if (validImageTypes.includes(file.type)) {
      return window.URL.createObjectURL(file);
    } else {
      return DiamondAlertMajor;
    }
  };

  const validateFile = (file: any) => {
    if (file.size > CONSTAN.maxSizeFileUpload) {
      setIsShowErrorInline({ status: true, msg: 'File too large' });
    } else if (!validImageTypes.includes(file.type)) {
      setIsShowErrorInline({ status: true, msg: 'Unsupported format' });
    } else {
      setIsShowErrorInline({ status: false, msg: '' });
    }
    return file.size < CONSTAN.maxSizeFileUpload && validImageTypes.includes(file.type);
  };

  const handleRemoveFile = (index: number) => () => {
    const files = [...props.files];
    files.splice(index, 1);
    props.onSave(files);
  };

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
  const actionHint = 'Supported formats: JPG, JPEG, GIF, PNG, and PDF\nMaximum of 5 files up to 10 MB each, 50 MB in total';
  const fileUpload = <DropZone.FileUpload actionHint={actionHint} actionTitle="Choose file" />;

  const uploadedFiles = props.files.length > 0 && (
    <LegacyStack>
      {props.files.map((file, index) => (
        <LegacyStack key={index}>
          <div className="remove-icon" onClick={handleRemoveFile(index)}>
            <Icon source={CancelMajor} />
          </div>
          <Thumbnail size="large" alt={file.name} source={renderSource(file)} />
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  return (
    <UploadFileStyled>
      <DropZone
        customValidator={validateFile}
        label={' Attach file'}
        allowMultiple
        disabled={props.files.length > 4}
        onDrop={handleDropZoneDrop}
        variableHeight
      >
        {fileUpload}
      </DropZone>
      {isShowErrorInline.status ? (
        <div className="mt-8 d-flex provide-evidence">
          <Icon source={CircleAlertMajor} tone="critical" />
          <Text as="span" variant="bodyLg" tone="critical">
            {isShowErrorInline.msg}
          </Text>
        </div>
      ) : null}

      <div className="mt-20">{uploadedFiles}</div>
    </UploadFileStyled>
  );
}

export default memo(UploadFile);
