import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import config from '@/config';
import { LINK } from '@/constants/link';
import { checkShowErrorInline, handleToastMutation, validateEmail } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isVisibleBannerDisputeSyncSelector } from '@/redux/slice/commonSetting.slice';
import { disputeTableSelector } from '@/redux/slice/dispute.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  Badge,
  Banner,
  Button,
  Card,
  Icon,
  Link,
  List,
  Modal,
  SkeletonDisplayText,
  TextField,
  Tooltip,
  Text,
} from '@shopify/polaris';
import { CaretDownMinor, CaretUpMinor, ConnectMinor, DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '@/components/CustomButton';
import InformationBanner from '@/components/InformationBanner';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
// const labelCheckbox = () => {
//   return (
//     <Text as="h6" variant="bodyMd">
//       Auto provide proof of fulfillment for “Item not received” (INR) disputes.
//       <Link external removeUnderline url={LINK.autoResolveProofDispute}>
//         Learn more.
//       </Link>
//     </Text>
//   );
// };

const RenderAccountStatus = (confirmStatus: boolean) => {
  if (confirmStatus) {
    return (
      <BoldText>
        Status: <Badge tone={'success'}>Confirmed</Badge>
      </BoldText>
    );
  } else {
    return (
      <BoldText>
        Status: <Badge tone="critical">Unconfirmed</Badge>
      </BoldText>
    );
  }
};

// const RenderAutoProofStatus = (autoProof: boolean) => {
//   if (autoProof) {
//     return (
//       <div>
//         <Badge status={'success'} progress="complete">
//           Auto provide proof for INR disputes
//         </Badge>
//       </div>
//     );
//   } else {
//     return <Badge progress="complete">Not auto provide proof for INR disputes</Badge>;
//   }
// };

const ConnectPaypal = () => {
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);

  const isVisibleBannerDisputeSync = useSelector(isVisibleBannerDisputeSyncSelector);
  const [connectAccount, statusConnect] = apiCaller.useConnectAccountsDisputeCurrentShopMutation();
  const [updateAccount, updateStatus] = apiCaller.useUpdateAccountsDisputeCurrentShopMutation();
  const { data, isLoading } = apiCaller.useFetchListAccountsCurrentShopQuery(config.shop, { skip: isSkip });
  const [deleteAccount, deleteStatus] = apiCaller.useDeleteAccountDisputeCurrentShopMutation();
  const disputeTable = useSelector(disputeTableSelector);
  const [count, setCount] = useState(2);
  const [state, setState] = useState({
    isOpenModal: false,
    isChangeSecret: false,
    accountSelected: -1,
    accountDeleting: -1,
    email: {
      value: '',
      error: '',
    },
    clientId: {
      value: '',
      error: '',
    },
    secret: {
      value: '',
      error: '',
    },
    isAutoProof: false,
    isDisableBtn: true,
  });
  const clearState = () =>
    setState({
      isOpenModal: false,
      accountSelected: -1,
      accountDeleting: -1,
      email: {
        value: '',
        error: '',
      },
      clientId: {
        value: '',
        error: '',
      },
      secret: {
        value: '',
        error: '',
      },
      isDisableBtn: true,
      isAutoProof: false,
      isChangeSecret: false,
    });
  const saveDisputeAccount = () => {
    if (state.accountSelected === -1) {
      const newResult: IParamsApi.IConnectDisputeAccountParams = {
        email: state.email.value,
        clientId: state.clientId.value,
        secret: state.secret.value,
        autoProof: state.isAutoProof,
      };
      connectAccount({ ...newResult }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setState({ ...state, secret: { ...state.secret, error: condition.msg } });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
          dispatch(
            slice.disputePageSlice.actions.handleDisputeTable({
              ...disputeTable,
              account: ['all'],
            }),
          );
        }
      });
    } else {
      updateAccount({
        email: state.email.value,
        clientId: state.clientId.value,
        secret: state.isChangeSecret ? state.secret.value : undefined,
        id: state.accountSelected,
        autoProof: state.isAutoProof,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setState({ ...state, secret: { ...state.secret, error: condition.msg } });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
          dispatch(
            slice.disputePageSlice.actions.handleDisputeTable({
              ...disputeTable,
              account: ['all'],
            }),
          );
        }
      });
    }
  };

  const onDelete = (id: number, index: number) => () => {
    setState({ ...state, accountDeleting: index });
    deleteAccount({ id }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        dispatch(
          slice.disputePageSlice.actions.handleDisputeTable({
            ...disputeTable,
            account: ['all'],
          }),
        );
        setState({ ...state, accountDeleting: -1 });
      }
    });
  };
  const onEdit = (id: number) => () => {
    if (data) {
      const item = data.accounts.find((item) => item.id === id);
      if (item) {
        const result = { ...item };
        setState({
          ...state,
          isOpenModal: true,
          accountSelected: result.id,
          email: {
            value: result.email,
            error: '',
          },
          clientId: {
            value: result.clientId,
            error: '',
          },
          secret: {
            value: '',
            error: '',
          },
          isAutoProof: result.autoProof,
        });
      }
    }
  };

  const handleDisableBtn = () => {
    const status = !(
      state.clientId.value &&
      state.email.value &&
      (!state.isChangeSecret || state.secret.value) &&
      validateEmail(state.email.value)
    );
    return status;
  };

  const handleValidateEmailMsg = (email: string): string => {
    if (!email) {
      return 'Email is required';
    } else {
      return validateEmail(email) ? '' : 'email Invalid';
    }
  };
  return (
    <div>
      {data && data.total === 0 ? (
        <div className="mb-16">
          <Banner title="Connect your PayPal account(s) to enable realtime disputes management" tone="warning">
            <RegularText>
              You need to connect your PayPal account(s) so Synctrack can get new disputes as soon as they are filed to PayPal (we
              only require&#160;
              <b>minimal access&#160;</b>
              so rest assured that your PayPal account(s) will&#160;
              <b>always be safe).</b>
            </RegularText>
          </Banner>
        </div>
      ) : (
        <InformationBanner
          isVisible={isVisibleBannerDisputeSync}
          onDismiss={() => {
            dispatch(slice.commonSettingSlice.actions.handleVisibleBannerDisputeSync(false));
          }}
        >
          <div className="d-flex">
            <RegularText>
              Getting available disputes from recently connected PayPal account for the first time might take a while (depending
              on the number of disputes). Please wait a moment and try the refresh button.
            </RegularText>
          </div>
          <RegularText>For new disputes, rest assured that Synctrack will get them automatically and in real-time.</RegularText>
        </InformationBanner>
      )}
      <Modal
        open={state.isOpenModal}
        onClose={clearState}
        title={`${state.accountSelected !== -1 ? 'Edit PayPal account' : 'Add new PayPal account'}`}
        primaryAction={{
          content: 'Save',
          onAction: saveDisputeAccount,
          disabled: handleDisableBtn(),
          loading: updateStatus.isLoading || statusConnect.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: clearState,
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Email"
            value={state.email.value}
            onChange={(value) => {
              setState({ ...state, email: { value, error: '' } });
            }}
            onBlur={() => setState({ ...state, email: { ...state.email, error: handleValidateEmailMsg(state.email.value) } })}
            autoComplete="off"
            requiredIndicator
            type="email"
            error={state.email.error}
          />
          <TextField
            label="Client ID"
            value={state.clientId.value}
            onChange={(value) => {
              setState({
                ...state,
                clientId: { value, error: '' },
              });
            }}
            onBlur={() => {
              if (!state.clientId.value) {
                setState({
                  ...state,
                  clientId: { ...state.clientId, error: 'Client id is required' },
                });
              }
            }}
            autoComplete="off"
            requiredIndicator
            error={state.clientId.error}
          />
          <TextField
            label="Secret key"
            value={state.secret.value}
            onChange={(value) => {
              if (state.accountSelected !== -1) {
                setState({ ...state, isChangeSecret: true, secret: { value, error: '' } });
              } else {
                setState({ ...state, secret: { value, error: '' } });
              }
            }}
            onBlur={() => {
              if (!state.secret.value) {
                setState({
                  ...state,
                  secret: { ...state.secret, error: 'Secret key is required' },
                });
              }
            }}
            autoComplete="off"
            requiredIndicator
            type="password"
            error={state.secret.error}
            placeholder="********************************************************************"
          />
          {/* <Checkbox
            label={labelCheckbox()}
            checked={state.isAutoProof}
            onChange={(newChecked) => setState({ ...state, isAutoProof: newChecked })}
          /> */}
          <div className="mt-8">
            <RegularText>Steps to get “Cliend ID” and “Secret key”</RegularText>
            <div className="mt-8">
              <RegularText>
                <List type="number">
                  <List.Item>
                    <b>
                      <Link url={LINK.paypalHomePage} external monochrome>
                        Go to Paypal Developer Dashboard
                      </Link>
                    </b>
                  </List.Item>
                  <List.Item>
                    Click “Log in to Dashboard” and log in with your account (If you’ve already logged in, skip this step).
                  </List.Item>
                  <List.Item>
                    On the navigation bar, go to&nbsp;
                    <b>
                      <Link url={LINK.paypalCredentials} external monochrome>
                        “Apps & Credentials”
                      </Link>
                    </b>
                    &nbsp;menu. Switch to “Live mode” using the toggle on the top-right corner.
                  </List.Item>
                  <List.Item>
                    Click&nbsp;
                    <b>
                      <Link url={LINK.paypalCreateApp} external monochrome>
                        “Create App”
                      </Link>
                    </b>
                  </List.Item>
                  <List.Item>Enter App Name and click “Create App”</List.Item>
                  <List.Item>
                    Scroll down to “Feature” section and make sure that the “Customer disputes” setting is checked.
                  </List.Item>
                  <List.Item>
                    Scroll up to “API credentials” section, copy “Client ID” and “Secret key” and paste them here.
                  </List.Item>
                </List>
              </RegularText>
            </div>
          </div>
        </Modal.Section>
      </Modal>
      {data?.accounts.map((item, index) => {
        const result =
          index < count ? (
            <div className={index === 0 ? '' : 'mt-16'}>
              <Card key={index} padding={'100'}>
                <div className={'list-paypal-account-container d-flex'}>
                  <div className="dispute-account-content">
                    <div className="d-flex">
                      {/* <div className="ml-8 pointer" onClick={onEdit(item.id)}>
                    {RenderAutoProofStatus(item.autoProof)}
                  </div> */}
                    </div>
                    <div className="d-flex">
                      <BoldText>Email:&nbsp;</BoldText>
                      <RegularText>{item.email}</RegularText>
                    </div>
                    <div className="mt-8 d-flex client-id-value">
                      <BoldText>Client ID:&nbsp;</BoldText>
                      <Text numeric={true} truncate as="h6" variant="bodySm">
                        {item.clientId.slice(0, 20) + '********************'}
                      </Text>
                    </div>
                    <div className="mt-8">{RenderAccountStatus(item.confirmStatus)}</div>
                  </div>
                  <div className="btn-group">
                    <Tooltip content="Edit">
                      <CustomButton loading={updateStatus.isLoading} onClick={onEdit(item.id)} icon={EditMinor} />
                    </Tooltip>
                    <div className=" ml-8">
                      <Tooltip content="Delete">
                        <CustomButton
                          tone="critical"
                          loading={deleteStatus.isLoading && state.accountDeleting === index}
                          onClick={onDelete(Number(item.id), index)}
                          icon={() => <Icon source={DeleteMinor} tone="base" />}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ) : null;
        return result;
      })}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', transition: 'all 2s linear 1s' }}>
        {data && data.total > count ? (
          <Button onClick={() => setCount(count + 5)} variant="plain" icon={CaretDownMinor}>
            See more
          </Button>
        ) : null}
        {count > 2 ? (
          <Button onClick={() => setCount(1)} variant="plain" icon={CaretUpMinor}>
            See less
          </Button>
        ) : null}
      </div>
      {isLoading ? (
        <SkeletonDisplayText />
      ) : (
        <CustomButton
          variant="primary"
          onClick={() => {
            setState({ ...state, isOpenModal: true });
          }}
          icon={ConnectMinor}
        >
          {data && data.total > 0 ? 'Add PayPal account' : 'Connect PayPal account'}
        </CustomButton>
      )}
    </div>
  );
};
export default memo(ConnectPaypal);
