import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiCaller } from './query';
import commonSettingSlice from './slice/commonSetting.slice';
import disputePageSlice from './slice/dispute.slice';
import orderPageSlice from './slice/orderPage.slice';
import settingsPageSlice from './slice/settings.slice';
import masterDataSlice from './slice/masterData.slice';
import analyticsPageSlice from './slice/analytics.slice';
import welcomePageSlice from './slice/welcome.slice';
import { apiAdminCaller } from './query/admin';
import bannerSlice from './slice/banner.slice';
import config from '@/config';
import storageSession from 'redux-persist/lib/storage/session';
import authSlice from './slice/auth.slice';

const settingsPersistConfig = {
  key: 'settings',
  storage,
  blacklist: ['activeKey'],
};

const commonSettingPersistConfig = {
  key: 'commonSetting',
  storage,
  blacklist: ['toast'],
};

const bannerPersistConfig = {
  key: `${config.shop}-banner`,
  storage: storageSession,
};
const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
};

const rootReducer = combineReducers({
  // Thêm Reducer tại đây. Sample : sample : sampleReducer
  commonSetting: persistReducer(commonSettingPersistConfig, commonSettingSlice.reducer),
  analytics: analyticsPageSlice.reducer,
  order: orderPageSlice.reducer,
  settings: persistReducer(settingsPersistConfig, settingsPageSlice.reducer),
  dispute: disputePageSlice.reducer,
  apiCaller: apiCaller.reducer,
  apiAdminCaller: apiAdminCaller.reducer,
  welcome: welcomePageSlice.reducer,
  masterData: masterDataSlice.reducer,
  banner: persistReducer(bannerPersistConfig, bannerSlice.reducer),
  auth: persistReducer(authPersistConfig, authSlice.reducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const customizedMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return customizedMiddleware.concat(apiCaller.middleware, apiAdminCaller.middleware);
  },
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
