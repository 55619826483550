import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { IOrderTable } from '@/types/components';
import { Modal, OptionList } from '@shopify/polaris';
import { useState } from 'react';

interface Props extends IOrderTable.IExportOrderProps {
  isOpen: boolean;
  handleOpen: (status: boolean) => void;
}

const ExportOrders = ({ ...props }: Props) => {
  const [trigger, { isLoading }] = apiCaller.useLazyExportOrdersQuery();
  const [selectedFields, setSelectedFields] = useState([
    'shop',
    'orderId',
    'name',
    'paymentGatewayNames',
    'trackingNumber',
    'trackingCompany',
    'transactionId',
    'addTrackingStatus',
    'payPalAccountId',
    'processedAt',
  ]);
  const listFields = [
    { value: 'shop', label: 'Shop' },
    { value: 'orderId', label: 'Order Id' },
    { value: 'name', label: 'Order Name' },
    { value: 'paymentGatewayNames', label: 'Payment gateway names' },
    { value: 'trackingNumber', label: 'Tracking number' },
    { value: 'trackingCompany', label: 'Tracking company' },
    { value: 'transactionId', label: 'Transaction id' },
    { value: 'addTrackingStatus', label: 'Sync Status' },
    { value: 'payPalAccountId', label: 'PayPal Account' },
    { value: 'processedAt', label: 'Processed At' },
  ];

  const handleExportOrders = () => {
    trigger({
      fields: selectedFields.toString(),
      search: props.search[0],
      fromDate: dateToTimeStamp(props.fromDate),
      toDate: dateToTimeStamp(props.toDate),
      payment: props.payment[0],
      status: props.status[0],
      store: props.store.includes('all') ? undefined : JSON.stringify(props.store),
      sort: props.sort.toUpperCase(),
      sortBy: props.sortBy,
    }).then((res) => {
      if (res.data) {
        window.open(res.data.exportLink);
      }
      props.handleOpen(false);
    });
  };

  return (
    <div className="float-right mr-4">
      <Modal
        open={props.isOpen}
        onClose={() => props.handleOpen(false)}
        title="Export Options"
        primaryAction={{
          content: 'Export',
          onAction: handleExportOrders,
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => props.handleOpen(false),
          },
        ]}
      >
        <Modal.Section>
          <OptionList
            title="Field to export:"
            onChange={setSelectedFields}
            options={listFields}
            selected={selectedFields}
            allowMultiple
          />
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default ExportOrders;
