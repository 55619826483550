import paypalImg from '@/assets/email_consent/paypal_email_consent.jpg';
import stripeImg from '@/assets/email_consent/stripe_email_consent.jpg';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { EmailConsentPoisition, EmailConsentValue } from '@/constants/enum';
import { validateEmail } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector } from '@/redux/slice/masterData.slice';
import { IHomePage } from '@/types/components';
import { Button, Checkbox, InlineGrid, Modal, Text, TextField } from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useSelector } from 'react-redux';

const Paypal = () => {
  return <img src={paypalImg} alt="_Blank" />;
};
const Stripe = () => {
  return <img src={stripeImg} alt="_Blank" />;
};

const carouselConfig = [Paypal(), Stripe()];

const EmailConsentModal = () => {
  const dataSettings = useSelector(dataSettingsSelector);
  const [getListCountry] = apiCaller.useLazyGetCountryQuery();
  const [emailConsent, emailConsentStatus] = apiCaller.useEmailConsentMutation();
  const [state, setState] = useState<IHomePage.IState>({
    isOpenEmailConsent: false,
    newEmail: '',
    emailType: EmailConsentPoisition.SYNCED_ORDER,
    isSent: false,
    country: '',
  });
  const [emailConsentValue, setEmailConsentValue] = useState<Array<string>>([]);
  const [now] = useState(new Date());
  const [isChecked, setIschecked] = useState({
    synctrackUpdate: false,
    monthlyReports: false,
    hotDeals: false,
    paymentAndDisputeTips: false,
    defaultEmail: false,
    newEmail: false,
  });

  const handleEmailConsent = (type: 'close' | 'sendEmail') => () => {
    const last = new Date();
    if (!state.isSent) {
      emailConsent({
        email: state.newEmail || undefined,
        position: state.emailType,
        value: type === 'sendEmail' && emailConsentValue.length > 0 ? emailConsentValue : undefined,
        displayTime: last.getTime() - now.getTime(),
      }).then((res) => {
        if ('data' in res) {
          if (type === 'sendEmail')
            setState({
              ...state,
              isSent: true,
              newEmail: '',
            });
          else {
            setState({
              ...state,
              isOpenEmailConsent: false,
            });
          }
        }
      });
    } else {
      setState({
        ...state,
        isOpenEmailConsent: false,
      });
    }
  };

  const handleChange = (key: string, value: string) => (checked: boolean) => {
    setIschecked({
      ...isChecked,
      [key]: checked,
    });
    const emailValue = [...emailConsentValue];
    const index = emailValue.indexOf(value);

    if (index === -1) {
      emailValue.push(value);
    } else {
      emailValue.slice(index, 1);
    }
    setEmailConsentValue(emailValue);
  };

  useEffect(() => {
    if (dataSettings && dataSettings?.popupPosition) {
      getListCountry().then((res) => {
        const country = res.data?.data.result.find((item) => item.isoCode === dataSettings?.country)?.name || 'US';
        setState({
          ...state,
          isOpenEmailConsent: true,
          emailType: dataSettings.popupPosition,
          country,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSettings?.popupPosition]);

  return (
    <Modal open={state.isOpenEmailConsent} size="large" title="" titleHidden onClose={handleEmailConsent('close')}>
      <Modal.Section>
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
          <div className="carousel-email-consent">
            <AliceCarousel autoPlayInterval={3000} infinite autoPlay items={carouselConfig} />
          </div>
          <div className="email-consent-content">
            <div>
              <Text as="h6" variant="heading2xl">
                Payment hold & Disputes
              </Text>
              <br />

              <div className="mt-8 d-flex">
                <RegularText>
                  Subscribe to receive more insights and expert guidance to&nbsp;
                  <b>get your money quickly,</b>&nbsp; specialised for&nbsp;
                  <b>{state.country} market</b>
                </RegularText>
              </div>
              <div className="mb-8 mt-16">
                <RegularText>Choose your favorite content here</RegularText>
              </div>
              <div>
                <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                  <Checkbox
                    label="Synctrack updates"
                    checked={isChecked.synctrackUpdate}
                    onChange={handleChange('synctrackUpdate', EmailConsentValue.ST_UPDATE)}
                  />
                  <Checkbox
                    label="Monthly reports"
                    checked={isChecked.monthlyReports}
                    onChange={handleChange('monthlyReports', EmailConsentValue.REPORT)}
                  />
                  <Checkbox
                    label="Hot deals"
                    checked={isChecked.hotDeals}
                    onChange={handleChange('hotDeals', EmailConsentValue.PROMO)}
                  />
                  <Checkbox
                    label="Payment & Dispute tips"
                    checked={isChecked.paymentAndDisputeTips}
                    onChange={handleChange('paymentAndDisputeTips', EmailConsentValue.PAYMENT_TIPS)}
                  />
                </InlineGrid>
              </div>
              <div className="mt-16 mb-8">
                <RegularText>By subscribing, you allow us to send emails to your inbox</RegularText>
              </div>
              <div>
                <InlineGrid columns={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} gap={'400'}>
                  <Checkbox
                    label={
                      <div className="d-flex">
                        <RegularText>Use&nbsp;</RegularText>
                        <BoldText>{dataSettings?.subscriptionEmailMarketing}</BoldText>
                      </div>
                    }
                    checked={isChecked.defaultEmail}
                    onChange={(checked: boolean) => {
                      setIschecked({
                        ...isChecked,
                        newEmail: false,
                        defaultEmail: checked,
                      });
                    }}
                  />
                  <div
                    className="d-flex mt-8 pointer"
                    onClick={() => {
                      if (!isChecked.newEmail) {
                        setIschecked({
                          ...isChecked,
                          newEmail: true,
                          defaultEmail: false,
                        });
                      }
                    }}
                  >
                    <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        label=""
                        labelHidden
                        checked={isChecked.newEmail}
                        onChange={(checked: boolean) => {
                          setIschecked({
                            ...isChecked,
                            newEmail: checked,
                            defaultEmail: false,
                          });
                        }}
                      />
                    </div>
                    <div style={{ width: '100%' }}>
                      <TextField
                        label=""
                        labelHidden
                        disabled={!isChecked.newEmail}
                        placeholder="Or enter your new email"
                        autoFocus
                        value={state.newEmail}
                        onChange={(newEmail) => {
                          setState({
                            ...state,
                            newEmail,
                          });
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </InlineGrid>
              </div>

              <div className="mt-24">
                <Button
                  variant="primary"
                  fullWidth
                  onClick={handleEmailConsent('sendEmail')}
                  disabled={
                    !(
                      (isChecked.synctrackUpdate ||
                        isChecked.monthlyReports ||
                        isChecked.hotDeals ||
                        isChecked.paymentAndDisputeTips) &&
                      (isChecked.defaultEmail || (isChecked.newEmail && validateEmail(state.newEmail)))
                    )
                  }
                  loading={emailConsentStatus.isLoading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </InlineGrid>
      </Modal.Section>
    </Modal>
  );
};
export default memo(EmailConsentModal);
