/* eslint-disable jsx-a11y/anchor-is-valid */
import RegularText from '@/components/RegularText';
import { Button, Card, Icon, InlineGrid, Link } from '@shopify/polaris';
import { StarFilledMinor } from '@shopify/polaris-icons';
import { memo, useEffect, useRef } from 'react';
import { configRecommend } from './configRecommend';
import { apiCaller } from '@/redux/query';
import { useDispatch, useSelector } from 'react-redux';
import bannerSlice, { CrossSellSelector, initialState } from '@/redux/slice/banner.slice';
import { useGa4 } from '@/hooks/useGa4';
import { Ga4Event } from '@/constants/enum';
const RecommendedApps = () => {
  const [bannerClick] = apiCaller.useBannerClickMutation();
  const crossSell = useSelector(CrossSellSelector);
  const { handleGa4 } = useGa4();
  const dispatch = useDispatch();
  const recommendAppRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!crossSell.isShowedHomePage) {
            dispatch(
              bannerSlice.actions.handleBanner({
                key: 'isShowedHomePage',
                value: true,
              }),
            );
            handleGa4(Ga4Event.CheckoutRulesImpression);
            handleGa4(Ga4Event.EstimatedImpression);
            handleGa4(Ga4Event.OTImpression);
            handleGa4(Ga4Event.ReturnImpression);
          }
        }
      });
    });

    if (recommendAppRef.current) {
      observer.observe(recommendAppRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (recommendAppRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(recommendAppRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crossSell.isShowedHomePage]);
  return (
    <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={'400'}>
      {configRecommend.map((item, index) => {
        return (
          <Card key={index}>
            <div>
              <div className="recommended-apps-container">
                <img className="recommend-app-logo" src={item.logo} alt="recommend apps" />
                <div className="recommend-app-content">
                  <Link removeUnderline url={item.href} external target="_blank">
                    {item.title}
                  </Link>
                  {item.hasBFS ? (
                    <div>
                      <span className="bfs-badge">
                        <svg width={12} height={10.5} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="m13 0-1 5-4 9 8-9.5L13 0ZM3 0l1 5 4 9-8-9.5L3 0Z" fill="#0D79A7" />
                          <path d="m3 0 1 5 4 9 4-9 1-5H3Z" fill="#49A3C9" />
                          <path d="M8 14 4 5l-4-.5L8 14ZM8 14l4-9 4-.5L8 14Z" fill="#07425B" />
                          <path d="M8 5.5 4 5l4 9 4-9-4 .5Z" fill="#0D79A7" />
                          <path d="m4 5 4-5 4 5-4.001.5L4 5Z" fill="#95D5F0" />
                          <path d="M4 5 3 0h5L4 5ZM12 5l1-5H8l4 5Z" fill="#49A3C9" />
                          <path d="M4 5 3 0 0 4.5 4 5ZM12 5l1-5 3 4.5-4 .5Z" fill="#0D79A7" />
                        </svg>
                        <span>Built for Shopify</span>
                      </span>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <Icon source={StarFilledMinor} tone="warning" />
                    <RegularText>
                      {item.star} - {item.plan}
                    </RegularText>
                  </div>
                  <RegularText>{item.description}</RegularText>
                </div>
              </div>
              <div className="recommend-app-btn" ref={recommendAppRef}>
                <Button
                  variant="primary"
                  url={item.href}
                  onClick={() => {
                    const key = item.key as keyof typeof initialState;
                    if (!crossSell[key]) {
                      handleGa4(item.ga4Event);
                      dispatch(
                        bannerSlice.actions.handleBanner({
                          key,
                          value: true,
                        }),
                      );
                    }
                    bannerClick({ bannerType: item.marketingBanner });
                  }}
                  external
                  fullWidth
                >
                  Try for free
                </Button>
              </div>
              <img className="recommend-app-banner" src={item.banner} alt="recommend app banner" />
            </div>
          </Card>
        );
      })}
    </InlineGrid>
  );
};

export default memo(RecommendedApps);
