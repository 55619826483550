/* eslint-disable jsx-a11y/iframe-has-title */
import { BREAKPOINT } from '@/constants/enum';
import slice from '@/redux/slice';
import { activeKeySelector } from '@/redux/slice/settings.slice';
import { Icon, InlineGrid, OptionList, Text } from '@shopify/polaris';
import {
  ArchiveMajor,
  CreditCardMajor,
  DiamondAlertMinor,
  DigitalMediaReceiverMajor,
  NoteMajor,
  PaymentsMajor,
  ShipmentMajor,
  SocialAdMajor,
} from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import FacebookAndInstagramPayment from './component/FbAndIgPayment';
import CourierMapping from './component/courier';
import DigitalProduct from './component/digitalProduct';
import OrderNotes from './component/orderNotes';
import PaypalSetting from './component/paypal';
import MultiStore from './component/stores';
import StripeSetting from './component/stripe';
import SyncFilter from './component/syncFilter';
import { SettingsPageStyled } from './styled';

const configItems = [
  {
    value: 'paypal',
    content: <PaypalSetting />,
  },
  {
    value: 'stripe',
    content: <StripeSetting />,
  },
  {
    value: 'fbAndIg',
    content: <FacebookAndInstagramPayment />,
  },

  {
    value: 'courier',
    content: <CourierMapping />,
  },
  {
    value: 'ignoreSync',
    content: <SyncFilter />,
  },
  {
    value: 'store',
    content: <MultiStore />,
  },
  {
    value: 'digitalProduct',
    content: <DigitalProduct />,
  },
  {
    value: 'notes',
    content: <OrderNotes />,
  },
];

const SettingsPage = () => {
  const dispatch = useDispatch();
  const activeKey = useSelector(activeKeySelector);

  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const changeTab = (key: string[]) => {
    dispatch(slice.settingsPageSlice.actions.handleActiveKey(key[0]));
    if (isMobile) {
      window.scrollTo(0, 735);
    }
  };

  const options = useMemo(() => {
    return [
      {
        value: 'paypal',
        label: (
          <div className="settings-title">
            <Icon source={PaymentsMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              PayPal settings
            </Text>
          </div>
        ),
      },
      {
        value: 'stripe',
        label: (
          <div className="settings-title">
            <Icon source={CreditCardMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Stripe settings
            </Text>
          </div>
        ),
      },
      {
        value: 'fbAndIg',
        label: (
          <div className="settings-title">
            <Icon source={SocialAdMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Facebook & Instagram payment
            </Text>
          </div>
        ),
      },
      {
        value: 'courier',
        label: (
          <div className="settings-title">
            <Icon source={ShipmentMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Courier mapping
            </Text>
          </div>
        ),
      },
      {
        value: 'ignoreSync',
        label: (
          <div className="settings-title">
            <Icon source={DiamondAlertMinor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Ignore sync
            </Text>
          </div>
        ),
      },
      {
        value: 'store',
        label: (
          <div className="settings-title">
            <Icon source={ArchiveMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Multi stores
            </Text>
          </div>
        ),
      },
      {
        value: 'digitalProduct',
        label: (
          <div className="settings-title">
            <Icon source={DigitalMediaReceiverMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Digital product
            </Text>
          </div>
        ),
      },
      {
        value: 'notes',
        label: (
          <div className="settings-title">
            <Icon source={NoteMajor} tone="subdued" />
            <Text as="p" variant="bodyMd">
              Shopify order note
            </Text>
          </div>
        ),
      },
    ];
  }, []);
  return (
    <SettingsPageStyled>
      <InlineGrid columns={isMobile ? 1 : ['oneThird', 'twoThirds']}>
        <div className="settings-title-container">
          <OptionList title="" onChange={changeTab} options={options} selected={[activeKey]} />
        </div>
        <div className={`${isMobile ? 'mt-10' : 'mt-6 ml-16'}`}>
          <div>{configItems.find((item) => item.value === activeKey)?.content}</div>
        </div>
      </InlineGrid>
    </SettingsPageStyled>
  );
};

export default memo(SettingsPage);
