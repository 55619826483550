import { Badge, EmptyState, IndexTable, Link, Pagination, SkeletonBodyText, Text } from '@shopify/polaris';
import dayjs from 'dayjs';

import RegularText from '@/components/RegularText';
import config from '@/config';
import { capitalizeFirstLetter, dateToTimeStamp, formatDate } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { listStoreSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { IOrderTable } from '@/types/components';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
const EmptyStateMarkup = () => {
  return (
    <EmptyState heading="No Item" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
      <RegularText>Try changing the filters or search term</RegularText>
    </EmptyState>
  );
};
const OrderTable = () => {
  const listStore = useSelector(listStoreSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const orderTable: IOrderTable.IState = useSelector(orderTableOrderPageSelector);
  const { data, isFetching } = apiCaller.useFetchListOrdersQuery(
    {
      search: orderTable.search,
      status: orderTable.statusSelected[0],
      fromDate: dateToTimeStamp(orderTable.fromDate),
      toDate: dateToTimeStamp(orderTable.toDate),
      store: orderTable.storeSelected.includes('all') ? undefined : JSON.stringify(orderTable.storeSelected),
      payment: orderTable.paymentSelected[0],
      page: orderTable.currentPage,
      perPage: parseInt(orderTable.perPage[0]),
      sort: orderTable.sort.toUpperCase(),
      sortBy: orderTable.sortBy,
    },
    { skip: isSkip },
  );
  const dispatch = useDispatch();

  const getAccountId = (value: string | null) => {
    if (value === '' || value === null) {
      return 'No info';
    } else {
      return value;
    }
  };

  const processTransactionId = useCallback((transactionId: string, paymentName: string) => {
    var res = transactionId.split(' ');
    let paymentURL = 'https://www.paypal.com/activity/payment/';
    if (paymentName.includes('stripe') || paymentName.includes('Stripe')) {
      paymentURL = 'https://dashboard.stripe.com/payments/';
    }
    const result = res.map((item, index) => {
      if (!item) return '---';
      return (
        <div key={index}>
          <Link removeUnderline onClick={() => window.open(paymentURL + item, '_blank')}>
            {item}
          </Link>
          <br />
        </div>
      );
    });

    return result;
  }, []);

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };

  const orders = useMemo(() => {
    return data?.items.map((order) => {
      return {
        id: order.id,
        order: (
          <Link
            removeUnderline
            onClick={() => window.open('https://' + config.shop + '/admin/orders/' + order.orderId, '_blank')}
          >
            {order.name}
          </Link>
        ),
        store: listStore && listStore.length > 1 ? <RegularText>{order.shop}</RegularText> : null,
        payment: (
          <Badge
            tone={
              order.addTrackingStatus !== -2 && order.paymentGatewayNames !== 'manual_checked'
                ? order.paymentGatewayNames === 'paypal'
                  ? 'info'
                  : 'success'
                : undefined
            }
          >
            {capitalizeFirstLetter(order.paymentGatewayNames)}
          </Badge>
        ),
        trackingNumber: order.trackingNumber.split(' | | ').map((item, index) => {
          return (
            <div key={index}>
              <RegularText>{item}</RegularText>
            </div>
          );
        }),
        courier: order.trackingCompany.split(' | | ').map((item, index) => {
          return (
            <div key={index}>
              <RegularText>{item}</RegularText>
            </div>
          );
        }),
        trackingStatus: (
          <RegularText>
            {order.addTrackingStatus === 1 ? <Badge tone="success">Synced</Badge> : null}
            {order.addTrackingStatus === -1 ? <Badge tone="warning">No tracking number</Badge> : null}
            {order.addTrackingStatus === -2 ? <Badge tone="warning">Paid by other gateways</Badge> : null}
            {order.addTrackingStatus === 2 ? <Badge tone="warning">Over quota</Badge> : null}
            {order.addTrackingStatus === 3 ? <Badge tone="critical">No or invalid account</Badge> : null}
            {order.addTrackingStatus === 4 ? <Badge tone="warning">Order no transaction id</Badge> : null}
            {order.addTrackingStatus === 5 ? <Badge tone="warning">Invalid tracking number</Badge> : null}
            {order.addTrackingStatus === 6 ? <Badge tone="critical">Missing PayPal account</Badge> : null}
            {order.addTrackingStatus === 7 ? <Badge tone="warning">Store pickup</Badge> : null}
            {order.addTrackingStatus === 0 ? (
              <Badge tone="info" progress="partiallyComplete">
                In queue
              </Badge>
            ) : null}
          </RegularText>
        ),
        transactionId: <RegularText>{processTransactionId(order.transactionId, order.paymentGatewayNames)}</RegularText>,
        accPaidSynced:
          order.addTrackingStatus === 6 ? (
            <Text variant="bodySm" as="span" tone="critical">
              <b>{!!order.payPalAccountId ? `Need to connect PayPal acc: ${order.payPalAccountId}` : 'No Info'}</b>
            </Text>
          ) : (
            <RegularText>
              {getAccountId(order.payPalAccountId) === getAccountId(order.addTrackingAccount) ? (
                getAccountId(order.payPalAccountId)
              ) : (
                <span>
                  {getAccountId(order.payPalAccountId)} <br />
                  {getAccountId(order.addTrackingAccount)}
                </span>
              )}
            </RegularText>
          ),
        createdAt: <RegularText>{formatDate(order.createdAt)}</RegularText>,
        syncedAt: (
          <Text breakWord variant="bodySm" as="p">
            {order.addTrackingAt === 0 ? '---' : dayjs.unix(order.addTrackingAt).format('D MMM YYYY, hh:mm a')}
          </Text>
        ),
      };
    });
  }, [data?.items, listStore, processTransactionId]);

  const rowMarkup = useMemo(() => {
    return orders?.map(
      (
        { id, order, store, trackingStatus, payment, trackingNumber, courier, transactionId, accPaidSynced, createdAt, syncedAt },
        index,
      ) => (
        <IndexTable.Row id={id} key={id} position={index}>
          <IndexTable.Cell>{order}</IndexTable.Cell>
          {listStore && listStore.length > 1 ? <IndexTable.Cell>{store}</IndexTable.Cell> : null}
          <IndexTable.Cell>{payment}</IndexTable.Cell>
          <IndexTable.Cell>{trackingNumber}</IndexTable.Cell>
          <IndexTable.Cell>{courier}</IndexTable.Cell>
          <IndexTable.Cell>{trackingStatus}</IndexTable.Cell>
          <IndexTable.Cell>{transactionId}</IndexTable.Cell>
          <IndexTable.Cell>{accPaidSynced}</IndexTable.Cell>
          <IndexTable.Cell>{createdAt}</IndexTable.Cell>
          <IndexTable.Cell>{syncedAt}</IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  }, [listStore, orders]);
  return (
    <div className="table-wrapper">
      <IndexTable
        resourceName={resourceName}
        itemCount={orders?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyStateMarkup />}
        headings={
          listStore && listStore.length > 1
            ? [
                { title: 'Order' },
                { title: 'Store' },
                { title: 'Payment' },
                { title: 'Tracking number' },
                { title: 'Courier' },
                { title: 'Status' },
                { title: 'Transaction ID' },
                { title: 'AccPaid/Synced' },
                { title: 'Date' },
                { title: 'Synced at' },
              ]
            : [
                { title: 'Order' },
                { title: 'Payment' },
                { title: 'Tracking number' },
                { title: 'Courier' },
                { title: 'Status' },
                { title: 'Transaction ID' },
                { title: 'AccPaid/Synced' },
                { title: 'Date' },
                { title: 'Synced at' },
              ]
        }
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>
      <div className="pagination-orders">
        {data && data?.meta?.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta?.totalItems
                ? `Showing ${(data?.meta?.currentPage - 1) * Number(data?.meta?.itemsPerPage) + 1} to ${Math.min(
                    data?.meta?.currentPage * Number(data?.meta?.itemsPerPage),
                    data?.meta?.totalItems,
                  )} of ${data?.meta?.totalItems} ${data?.meta?.totalItems > 1 ? 'orders' : 'order'} `
                : null
            }
            hasPrevious={Number(data?.meta?.currentPage) > 1}
            onPrevious={() =>
              dispatch(
                slice.orderPageSlice.actions.handleFiltersOrderTable({
                  ...orderTable,
                  currentPage: (data?.meta?.currentPage || 1) - 1,
                }),
              )
            }
            hasNext={Number(data?.meta?.currentPage) < Number(data?.meta?.totalPages)}
            onNext={() => {
              dispatch(
                slice.orderPageSlice.actions.handleFiltersOrderTable({
                  ...orderTable,
                  currentPage: Number(data?.meta?.currentPage) + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(OrderTable);
