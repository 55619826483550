import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSomeDaysAgo } from '@/helpers';
const toDate = new Date();
toDate.setHours(23, 59, 59, 99);

// Define the initial state using that type
const initialState = {
  fromDate: getSomeDaysAgo(7),
  toDate: toDate,
  titleBtnDatePicker: 'Last 7 days',
};

const analyticsPageSlice = createSlice({
  name: 'analytics',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleDatePicker: (state, action: PayloadAction<{ toDate: Date; fromDate: Date }>) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },

    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.analytics,
  (state) => state.titleBtnDatePicker,
);
export const datePickerSelector = createSelector(
  (state: RootState) => state.analytics,
  (state) => {
    return {
      fromDate: state.fromDate,
      toDate: state.toDate,
    };
  },
);

export default analyticsPageSlice;
