import { Button, Popover, ActionList } from '@shopify/polaris';
import { useState, useCallback, memo } from 'react';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import { apiCaller } from '@/redux/query';
function Dismiss() {
  const [popoverActive, setPopoverActive] = useState(false);
  const [trigger] = apiCaller.useStoreReviewDisplayMutation();

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const activator = <Button icon={HorizontalDotsMinor} onClick={togglePopoverActive} variant="plain" />;

  return (
    <Popover active={popoverActive} activator={activator} autofocusTarget="first-node" onClose={togglePopoverActive}>
      <ActionList
        actionRole="menuitem"
        items={[
          {
            content: 'Dismiss',
            onAction: () => {
              trigger();
            },
          },
        ]}
      />
    </Popover>
  );
}

export default memo(Dismiss);
