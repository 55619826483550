import emptyState from '@/assets/emptystate.avif';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import CustomCard from '@/components/card';
import Layout from '@/components/layout';
import { PATH } from '@/constants/path';
import { convertAmount, convertUrlOrder, formatDate, snakeToCamel } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import {
  ActionList,
  Badge,
  Banner,
  Button,
  Card,
  Divider,
  EmptyState,
  InlineGrid,
  Link,
  Popover,
  SkeletonBodyText,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import options from '../options';
import CustomColumn from './Column';
import { BannerBuyerRefuse } from './bannerMsg/BuyerRefuseBanner';
import { BannerOffer } from './bannerMsg/OfferBanner';
import { DisputeDetailStyled } from './styled';
import ProvideEvidence from './provideEvidence';
import Claim from './claim';
import Escalate from './escalate';
import Offer from './offer';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const ColumnWidth = {
  postedBy: '150px',
  postedAt: '180px',
  Content: '400px',
  attachedDocuments: '300px',
  status: '120px',
};
const DisputeDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setstate] = useState({
    popoverActive: false,
    isOpenModalClaim: false,
    isOpenModalOffer: false,
    isOpenModalEscalate: false,
    isOpenModalEvidence: false,
  });
  const [msg, setMsg] = useState('');
  const isSkip = useSelector(isSkipApiSelector);

  const { data, isLoading } = apiCaller.useFetchDisputeDetailQuery({ id: id || '' }, { skip: isSkip });
  const [sendMessage, sendMessageStatus] = apiCaller.useSendMessageDisputeMutation();
  const [listActions, setListAction] = useState<Array<string>>();
  useEffect(() => {
    if (data) {
      dispatch(slice.disputePageSlice.actions.handleReason(data.disputeDetail.reason));
      setListAction(
        data.disputeDetail.availableActions?.split(',').map((item: string) => {
          return snakeToCamel(item);
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const activator = (
    <Button
      onClick={() => {
        setstate({
          ...state,
          popoverActive: !state.popoverActive,
        });
      }}
      disclosure
    >
      Other actions
    </Button>
  );
  const convertAddress = (address: any) => {
    if (!address) return '---';
    const result = [
      address.name,
      address.company,
      address.address2,
      address.address1,
      address.city,
      address.zip,
      address.country,
    ];
    return result.join(' ');
  };
  const getDisputeLink = (disputeId: string) => {
    const url = `https://www.paypal.com/resolutioncenter/view/${disputeId}`;
    return url;
  };
  return (
    <DisputeDetailStyled>
      <Layout
        backAction={{ onAction: () => navigate(PATH.DISPUTE) }}
        isDetailPage
        title={data?.disputeDetail.disputeId}
        fullWidth
      >
        {isLoading ? (
          <Card>
            <SkeletonBodyText lines={10} />
          </Card>
        ) : (
          <CustomCard title="Details">
            <InlineGrid gap="400" columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
              <Card>
                <CustomColumn
                  isHidenDivider
                  title="Status"
                  value={options.statusLabel.find((item) => item.value === data?.disputeDetail.status)?.label}
                  isSuccess={data?.disputeDetail.status === 'RESOLVED'}
                />
              </Card>
              <Card>
                <CustomColumn
                  isHidenDivider
                  title="Due date"
                  value={
                    data?.disputeDetail.sellerResponseDueDate
                      ? formatDate(data?.disputeDetail.sellerResponseDueDate)
                      : 'Unconfimred'
                  }
                />
              </Card>
            </InlineGrid>
            <div className="mt-16">
              <InlineGrid gap={'400'} columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                <CustomCard title="Case">
                  <CustomColumn
                    isLink={true}
                    url={getDisputeLink(data?.disputeDetail.disputeId || '')}
                    title="Dispute ID"
                    value={data?.disputeDetail.disputeId}
                  />
                  <CustomColumn title="Created on" value={formatDate(data?.disputeDetail.createTime || '')} />
                  <CustomColumn title="Last updated on" value={formatDate(data?.disputeDetail.updateTime || '')} />
                  <CustomColumn
                    title="Amount"
                    value={convertAmount(data?.disputeDetail.disputeAmount || '', data?.disputeDetail.disputeCurrencyCode || '')}
                  />
                  <CustomColumn
                    title="Reason"
                    value={options.optionsReason.find((item) => item.value === data?.disputeDetail.reason)?.label}
                  />
                  <CustomColumn isHidenDivider title="Stage" value={data?.disputeDetail.disputeLifeCycleStage} />
                </CustomCard>
                <CustomCard title="Transaction">
                  <CustomColumn title="Transaction ID" value={data?.disputeDetail.transactionId} />
                  <div className="custom-columns">
                    <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                      <BoldText>Order ID</BoldText>
                      {data && data?.disputeDetail.order.length > 0 ? (
                        <div>
                          {data?.disputeDetail.order.map((item, index) => {
                            return (
                              <Link key={index} removeUnderline url={convertUrlOrder(item.shop, item.orderId)} external>
                                {item.name}
                              </Link>
                            );
                          })}
                        </div>
                      ) : (
                        <span style={{ wordWrap: 'break-word' }}>---</span>
                      )}
                    </InlineGrid>
                    <Divider borderColor="border" />
                  </div>

                  <CustomColumn title="PayPal account" value={data?.disputeDetail.account} />
                  <CustomColumn title="Buyer’s name" value={data?.disputeDetail.buyerName} />
                  <CustomColumn title="Buyer’s email" value={data?.disputeDetail.buyerEmail} />
                  <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                    <BoldText>Shipping address</BoldText>
                    {data && data.disputeDetail.order.length > 0 ? (
                      <div>
                        {data?.disputeDetail.order.map((item, index) => {
                          return (
                            <div key={index}>
                              <RegularText>
                                {item.name} : {item.shippingAddress ? convertAddress(JSON.parse(item.shippingAddress)) : '---'}
                              </RegularText>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span>---</span>
                    )}
                  </InlineGrid>
                </CustomCard>
              </InlineGrid>
            </div>
          </CustomCard>
        )}

        <div className="mt-16" />
        {isLoading ? (
          <Card>
            <SkeletonBodyText lines={10} />
          </Card>
        ) : (
          <CustomCard title="Your conversation with the buyer">
            {data?.disputeDetail.messages.length === 0 ? (
              <EmptyState image={emptyState}>
                <RegularText>No conversation has been found yet</RegularText>
                <RegularText>It is recommended to try resolving dispute by talking to the buyer first </RegularText>
              </EmptyState>
            ) : (
              <div className="conversation-table">
                <div className="bold d-flex j-c-s mr-16 br-8">
                  <div className="pd-16" style={{ width: ColumnWidth.postedBy }}>
                    <BoldText>Posted by</BoldText>
                  </div>
                  <div className="pd-16" style={{ width: ColumnWidth.postedAt }}>
                    <BoldText>Posted at</BoldText>
                  </div>
                  <div className="pd-16" style={{ width: ColumnWidth.Content }}>
                    <BoldText>Content</BoldText>
                  </div>
                  <div className="pd-16" style={{ width: ColumnWidth.attachedDocuments }}>
                    <BoldText>Attached documents</BoldText>
                  </div>
                  <div className="pd-16" style={{ width: ColumnWidth.status }}>
                    <BoldText>Status</BoldText>
                  </div>
                </div>
                <RegularText>
                  {data?.disputeDetail.messages.map((item, index) => {
                    return (
                      <div key={index} className={`${item.postedBy === 'BUYER' ? 'bg-buyer your-response' : ''}`}>
                        <div className={'d-flex j-c-s mr-16 br-8'} key={index}>
                          <div className="pd-16" style={{ width: ColumnWidth.postedBy }}>
                            {item.postedBy}
                          </div>
                          <div className="pd-16" style={{ width: ColumnWidth.postedAt }}>
                            {formatDate(item.postedAt)}
                          </div>
                          <div className="pd-16" style={{ width: ColumnWidth.Content, overflowWrap: 'break-word' }}>
                            {item.disputeAction !== 'ESCALATE' ? item.content : null}
                            {item.offerType && item.type === 'OFFER' ? (
                              <div className="mt-16">
                                <BannerOffer
                                  title={
                                    options.offerDetailsConfig.find((option) => option.value === item.offerType)?.label || ''
                                  }
                                  address={
                                    item.offerReturnAddress
                                      ? `${item.offerReturnAddress.address}, ${item.offerReturnAddress.city}, ${item.offerReturnAddress.province} - ${item.offerReturnAddress.postalCode}`
                                      : undefined
                                  }
                                  content={
                                    !item.offerReturnAddress ? 'The original item will be retained by the buyer.' : undefined
                                  }
                                  currencyCode={item.offerCurrency}
                                  refund={item.offerValue}
                                />
                              </div>
                            ) : null}
                            {item.type === 'OFFER' && item.postedBy === 'BUYER' && item.offerEvent === 'DENIED' ? (
                              <div className="mt-16">
                                <BannerBuyerRefuse />
                              </div>
                            ) : null}
                            {item.type === 'EVIDENCE' && item.disputeAction === 'ACCEPT_CLAIM' ? (
                              <div className="mt-16">
                                <BannerOffer
                                  title={'Partial refund with replacement'}
                                  content={'The original item will be retained by the buyer.'}
                                  currencyCode={data?.disputeDetail.disputeCurrencyCode}
                                  refund={data?.disputeDetail.disputeAmount}
                                />
                              </div>
                            ) : null}
                            {item.type === 'EVIDENCE' && item.disputeAction === 'ESCALATE' ? (
                              <div className="mt-16">
                                <Banner title="You escalated this issue.">
                                  <RegularText>
                                    <b>Escalation info: </b>
                                    {item.content}
                                  </RegularText>
                                </Banner>
                              </div>
                            ) : null}
                          </div>
                          <div className="pd-16" style={{ width: ColumnWidth.attachedDocuments }}>
                            {item.documents.length === 0 ? (
                              '---'
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item.documents.map((item, index) => {
                                  return (
                                    <Tooltip content={item.name} key={index}>
                                      <Button
                                        textAlign="left"
                                        variant="plain"
                                        onClick={() => {
                                          window.open(item.downloadUrl ? item.downloadUrl : item.url, '_blank');
                                        }}
                                      >
                                        {item.name}
                                      </Button>
                                    </Tooltip>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <div className="pd-16" style={{ width: ColumnWidth.status }}>
                            <Badge
                              progress={item.pendingMessage ? 'partiallyComplete' : 'complete'}
                              tone={item.pendingMessage ? 'attention' : 'success'}
                            >
                              {item.postedBy === 'SELLER' ? (item.pendingMessage ? 'Sending' : 'Sent') : 'received'}
                            </Badge>
                          </div>
                        </div>
                        <Divider borderColor="border" />
                      </div>
                    );
                  })}
                </RegularText>
              </div>
            )}
          </CustomCard>
        )}
        {isLoading ? (
          <div className="mt-16">
            <Card>
              <SkeletonBodyText lines={10} />
            </Card>
          </div>
        ) : null}

        {data && data.disputeDetail.availableActions ? (
          <div className="mt-16">
            <CustomCard title="Your response">
              <TextField
                label="Message"
                placeholder="Send a message to buyer"
                value={msg}
                onChange={(value) => {
                  setMsg(value);
                }}
                multiline={5}
                autoComplete="off"
                maxLength={2000}
                disabled={!listActions?.includes('sendMessage')}
              />
              <div className="mt-16 response-btn-group">
                <Popover
                  active={state.popoverActive}
                  activator={activator}
                  autofocusTarget="first-node"
                  onClose={() => setstate({ ...state, popoverActive: false })}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[
                      {
                        content: 'Send offer',
                        onAction: () => {
                          setstate({ ...state, popoverActive: false, isOpenModalOffer: true });
                        },
                        disabled: !listActions?.includes('makeOffer'),
                      },
                      {
                        content: 'Escalate dispute',
                        onAction: () => {
                          setstate({ ...state, popoverActive: false, isOpenModalEscalate: true });
                        },
                        disabled: !listActions?.includes('escalate'),
                      },
                      {
                        content: 'Accept claim',
                        onAction: () => {
                          setstate({ ...state, popoverActive: false, isOpenModalClaim: true });
                        },
                        disabled: !listActions?.includes('acceptClaim'),
                      },
                      {
                        content: 'Provide evidence',
                        onAction: () => {
                          setstate({ ...state, popoverActive: false, isOpenModalEvidence: true });
                        },
                        disabled: !listActions?.includes('provideEvidence'),
                      },
                    ]}
                  />
                </Popover>
                <div className="ml-8">
                  <Button
                    disabled={!listActions?.includes('sendMessage') || !msg || !msg.trim()}
                    variant="primary"
                    loading={sendMessageStatus.isLoading}
                    onClick={() => {
                      sendMessage({
                        disputeId: data.disputeDetail.disputeId,
                        message: msg,
                      }).then(() => {
                        setMsg('');
                      });
                    }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </CustomCard>

            <Offer
              disputeId={data.disputeDetail.disputeId}
              isOpenModal={state.isOpenModalOffer}
              onClose={() => setstate({ ...state, isOpenModalOffer: false })}
              disputeAmount={data.disputeDetail.disputeAmount}
              shippingAddress={data.disputeDetail.order.length > 0 ? data.disputeDetail.order[0].shippingAddress : 'No info'}
              disputeCurrencyCode={data.disputeDetail.disputeCurrencyCode || 'USD'}
            />
            <Escalate
              isOpenModal={state.isOpenModalEscalate}
              onClose={() => setstate({ ...state, popoverActive: false, isOpenModalEscalate: false })}
              disputeId={data.disputeDetail.disputeId}
            />
            <Claim
              disputeId={data.disputeDetail.disputeId}
              disputeAmount={data.disputeDetail.disputeAmount}
              disputeCurrencyCode={data.disputeDetail.disputeCurrencyCode}
              isOpenModal={state.isOpenModalClaim}
              onClose={() => setstate({ ...state, isOpenModalClaim: false })}
            />
            <ProvideEvidence
              disputeId={data.disputeDetail.disputeId}
              reason={data.disputeDetail.reason}
              isOpenModal={state.isOpenModalEvidence}
              onClose={() => setstate({ ...state, isOpenModalEvidence: false })}
            />
          </div>
        ) : null}
      </Layout>
    </DisputeDetailStyled>
  );
};

export default memo(DisputeDetail);
