import { DisputeStage, OfferType } from '@/constants/enum';

const optionsPerPage = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' },
];
export const optionsReason = [
  { label: 'All', value: 'all' },
  {
    label: 'Product not received',
    value: 'MERCHANDISE_OR_SERVICE_NOT_RECEIVED',
  },
  {
    label: 'Product not as described',
    value: 'MERCHANDISE_OR_SERVICE_NOT_AS_DESCRIBED',
  },
  { label: 'Unauthorized payment', value: 'UNAUTHORISED' },
  { label: 'Credit not processed', value: 'CREDIT_NOT_PROCESSED' },
  { label: 'Duplicate transaction', value: 'DUPLICATE_TRANSACTION' },
  { label: 'Incorrect charge', value: 'INCORRECT_AMOUNT' },
  { label: 'Payment by other means', value: 'PAYMENT_BY_OTHER_MEANS' },
  {
    label: 'Charge for canceled billing',
    value: 'CANCELED_RECURRING_BILLING',
  },
  { label: 'Problem with remittance', value: 'PROBLEM_WITH_REMITTANCE' },
  { label: 'Other', value: 'OTHER' },
];

const optionsStage = [
  {
    label: 'Inquiry',
    value: DisputeStage.INQUIRY,
  },
  {
    label: 'Chargeback',
    value: DisputeStage.CHARGEBACK,
  },
  { label: 'Pre Arbitration', value: DisputeStage.PRE_ARBITRATION },
  { label: 'Arbitration', value: DisputeStage.ARBITRATION },
  { label: 'All', value: 'all' },
];
const mapValueStatus = [
  {
    value: 'all',
  },
  {
    value: 'OPEN,WAITING_FOR_SELLER_RESPONSE,OTHER',
  },
  {
    value: 'UNDER_REVIEW',
  },
  {
    value: 'WAITING_FOR_BUYER_RESPONSE',
  },
  {
    value: 'RESOLVED',
  },
];

const statusLabel = [
  {
    label: 'Waiting for seller response',
    value: 'WAITING_FOR_SELLER_RESPONSE',
  },
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Under review',
    value: 'UNDER_REVIEW',
  },
  {
    label: 'Waiting for buyer response',
    value: 'WAITING_FOR_BUYER_RESPONSE',
  },
  {
    label: 'Resolved',
    value: 'RESOLVED',
  },
];

const offerDetailsConfig = [
  {
    label: 'Partial refund with replacement',
    value: OfferType.REFUND,
  },
  {
    label: 'Partial refund with replacement',
    value: OfferType.REFUND_WITH_REPLACEMENT,
  },
  {
    label: 'Full refund with return of item',
    value: OfferType.REFUND_WITH_RETURN,
  },
  {
    label: 'Replacement of item',
    value: OfferType.REPLACEMENT_WITHOUT_REFUND,
  },
];

export default {
  optionsPerPage,
  optionsReason,
  optionsStage,
  mapValueStatus,
  statusLabel,
  offerDetailsConfig,
};
