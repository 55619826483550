import { Ga4Event } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { useGa4 } from '@/hooks/useGa4';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import bannerSlice, { CrossSellSelector } from '@/redux/slice/banner.slice';
import { Modal, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface Props {
  isOpen: boolean;
  handleClose: () => void;
}
export const DiscountCode = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const crossSell = useSelector(CrossSellSelector);
  const [state, setState] = useState({
    discountCode: '',
    errorDiscount: '',
  });
  const { handleGa4 } = useGa4();
  const [discountMutation, { isLoading }] = apiCaller.useApplyDiscountCodeMutation();
  const applyDiscountCode = () => {
    discountMutation({ discountCode: state.discountCode }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setState({ ...state, errorDiscount: condition.msg });
      } else {
        if (!crossSell.isAddDiscount) {
          dispatch(bannerSlice.actions.handleBanner({ key: 'isAddDiscount', value: true }));
          handleGa4(Ga4Event.addDiscountCode);
        }

        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        setState({ errorDiscount: '', discountCode: '' });
        props.handleClose();
      }
    });
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      title="Apply discount code"
      primaryAction={{
        content: 'Apply',
        onAction: applyDiscountCode,
        disabled: state.discountCode === '',
        loading: isLoading,
      }}
    >
      <Modal.Section>
        <TextField
          autoComplete="off"
          label="Discount code:"
          helpText={
            <span>
              <b>Note: </b>
              <p style={{ display: 'inline' }}>
                Discount code does not apply to yearly plans (yearly plans receive 30% discount by default).
              </p>
            </span>
          }
          value={state.discountCode}
          onBlur={() => {
            if (!state.discountCode) {
              setState({
                ...state,
                errorDiscount: 'You have not entered the discount code',
              });
            }
          }}
          onChange={(value) => {
            setState({
              ...state,
              discountCode: value,
              errorDiscount: '',
            });
          }}
          error={state.errorDiscount}
        />
      </Modal.Section>
    </Modal>
  );
};
