import config from '@/config';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { IMenuComponent } from '@/types/components';
import { Badge, Button, ButtonGroup } from '@shopify/polaris';
import {
  AnalyticsMajor,
  DisputeMinor,
  HomeMinor,
  OnlineStoreMinor,
  OrderStatusMinor,
  SettingsMinor,
} from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { slide as MenuNav } from 'react-burger-menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HelpCenter from './components/HelpCenter/helpCenter/HelpCenter';
import { MenuStyled } from './styled';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { useSelector } from 'react-redux';

const menu: Array<IMenuComponent.IMenuItem> = [
  {
    to: PATH.DEFAULT,
    label: 'Dashboard',
    icon: HomeMinor,
    activeKey: [PATH.DEFAULT.pathname],
  },
  {
    to: PATH.ANALYTICS,
    label: 'Analytics',
    icon: AnalyticsMajor,
    activeKey: [PATH.ANALYTICS.pathname],
  },
  {
    to: PATH.ORDERS,
    label: 'Orders',
    icon: OrderStatusMinor,
    activeKey: [PATH.ORDERS.pathname],
  },
  {
    to: PATH.DISPUTE,
    label: 'Dispute',
    icon: DisputeMinor,
    activeKey: [PATH.DISPUTE.pathname],
  },
  {
    to: PATH.SETTINGS,
    label: 'Settings',
    icon: SettingsMinor,
    activeKey: [PATH.SETTINGS.pathname],
  },
  {
    to: PATH.ACCOUNT,
    label: 'Subcription plans',
    icon: OnlineStoreMinor,
    activeKey: [PATH.ACCOUNT.pathname],
  },
];

const Menu = () => {
  const isSkip = useSelector(isSkipApiSelector);

  const dataSettings = apiCaller.useFetchDataSettingQuery(config.shop, { skip: isSkip }).data;
  const { pathname } = useLocation();
  const [state, setState] = useState<IMenuComponent.IState>({ isMenuMobileOpen: false, openFaqs: false, popoverActive: false });
  const navigate = useNavigate();
  //   const dataSettings = useSelector((state) => state.dataSettings);
  const MenuLinkMobile = ({ to, label }: IMenuComponent.IMenuItem) => {
    const handleClick = useCallback(() => {
      setState({
        ...state,
        isMenuMobileOpen: false,
      });
      navigate({ ...to });
    }, [to]);
    return (
      <Link onClick={handleClick} to={to}>
        {label}
      </Link>
    );
  };
  const MenuLink = ({ to, label, icon, activeKey }: IMenuComponent.IMenuItem) => {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(
        { ...to },
        {
          state: {
            prePathName: pathname,
          },
        },
      );
      setState({ ...state, popoverActive: false });
    };

    return (
      <Button onClick={handleClick} icon={icon} variant={!!activeKey.find((item) => item === pathname) ? 'primary' : undefined}>
        {label}
      </Button>
    );
  };

  const handleNavigate = (to: { pathname: string; search: string }) => () => {
    if (to.pathname === PATH.ACCOUNT.pathname) {
      navigate(
        { ...to },
        {
          state: {
            prePathName: 'menu',
          },
        },
      );
    } else {
      navigate({ ...to });
    }
  };
  return (
    <MenuStyled className="topnav-wrap p-3">
      <div className="desktop-nav">
        <ButtonGroup variant="segmented">
          {menu.map((item) => {
            return <MenuLink activeKey={item.activeKey} key={item.label} to={item.to} label={item.label} icon={item.icon} />;
          })}
        </ButtonGroup>
      </div>
      <div className="mobile-nav">
        <MenuNav right={false} isOpen={state.isMenuMobileOpen}>
          {menu.map((item) => {
            return (
              <MenuLinkMobile activeKey={item.activeKey} to={item.to} label={item.label} icon={item.icon} key={item.label} />
            );
          })}
        </MenuNav>
      </div>
      <HelpCenter isOpenFaqs={state.openFaqs} handleOpenFaqs={() => setState({ ...state, openFaqs: false })} />
      <div className="active-plan-status">
        <div className="d-flex">
          <div className="mt-2 d-flex">
            <div onClick={handleNavigate(PATH.ACCOUNT)}>
              <Badge tone="success" progress="complete">
                {`Active Plan: ${dataSettings?.plan.toUpperCase() || 'FREE'}`}
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </MenuStyled>
  );
};

export default Menu;
