import CustomButton from '@/components/CustomButton';
import InformationBanner from '@/components/InformationBanner';
import RegularText from '@/components/RegularText';
import CustomCard from '@/components/card';
import { LINK } from '@/constants/link';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import settingsPageSlice, { isVisibleBannerFacebookAndInstagramSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import { Badge, Button, Card, Checkbox, Icon, Link, List, Modal, Text, TextField, Tooltip } from '@shopify/polaris';
import { DeleteMinor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookAndInstagramStyled } from './styled';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import commonSettingSlice from '@/redux/slice/commonSetting.slice';
import CriticalBanner from '@/components/CriticalBanner';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const FacebookAndInstagramPayment = () => {
  const [critBanner, setCritBanner] = useState({
    status: false,
    msg: '',
  });
  const dispatch = useDispatch();
  const isVisibleBanner = useSelector(isVisibleBannerFacebookAndInstagramSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const { data } = apiCaller.useGetListFacebookAndInstagramPaymentQuery(undefined, { skip: isSkip });
  const [createAccount, createAccountStatus] = apiCaller.useCreateFacebookAndInstagramPaymentMutation();
  const [updateAccount, updateAccountStatus] = apiCaller.useUpdateFacebookAndInstagramPaymentMutation();
  const [deleteAccount, deleteAccountStatus] = apiCaller.useDeleteFacebookAndInstagramPaymentMutation();
  const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState({
    status: false,
    id: -1,
    error: {
      status: false,
      msg: '',
    },
  });
  const scope = useScope();
  const [state, setState] = useState<ISettingsPage.IStateFacebookAndInstagramSetting>({
    id: -1,
    isOpen: false,
    clientId: '',
    secret: '',
    processOrders: 0,
    errClientId: '',
    errSecret: '',
    type: 'add',
    isDisableSyncBtn: false,
    isActive: false,
  });
  const clearState = () => {
    setState({
      id: -1,
      clientId: '',
      secret: '',
      processOrders: 0,
      isOpen: false,
      errSecret: '',
      errClientId: '',
      type: 'add',
      isDisableSyncBtn: false,
      isActive: false,
    });
  };
  return (
    <FacebookAndInstagramStyled>
      <InformationBanner
        onDismiss={() => dispatch(settingsPageSlice.actions.handleVisibleBannerStripe(false))}
        title="Your account is protected"
        isVisible={isVisibleBanner}
      >
        We only require <b>minimal access </b> from your account to add tracking info to Paypal. Rest assured that your account
        will <b>always be safe</b>
        <br />
        <Link
          url="https://docs.synctrack.io/integrations/how-to-sync-tracking-info-for-orders-from-facebook-and-instagram-sale-channels"
          target="_blank"
          external
          removeUnderline
        >
          How to connect Facebook & Instagram payment?
        </Link>
      </InformationBanner>

      <div className="mt-16">
        <CustomCard title="Facebook and Instagram settings">
          <div>
            {data && data.data.length > 0 ? (
              <div className="w-100 fb-ig-account-container">
                {data.data.map((item, index) => {
                  return (
                    <Card key={index}>
                      <div className="fb-ig-account-content">
                        <div className="fb-ig-account-content-left">
                          <div className="d-flex fb-ig-token-container">
                            <Text as="p" variant="bodySm" truncate>
                              <b>Client ID: </b>
                              {item.clientId}
                            </Text>
                          </div>
                          <div className="mt-8">
                            <RegularText>
                              <b>Status: </b>
                              <Badge progress="complete" tone={item.isActive ? 'success' : 'warning'}>
                                {item.isActive ? 'Active' : 'Inactive'}
                              </Badge>
                            </RegularText>
                          </div>
                        </div>
                        <div className="d-flex btn-group">
                          <Tooltip content="Edit">
                            <CustomButton
                              onClick={() =>
                                setState({
                                  ...state,
                                  id: item.id,
                                  clientId: item.clientId,
                                  isOpen: true,
                                  type: 'edit',
                                  isActive: item.isActive,
                                })
                              }
                              icon={EditMinor}
                            />
                          </Tooltip>
                          <div className="delete-button ml-8">
                            <Tooltip content="Delete">
                              <CustomButton
                                tone="critical"
                                onClick={() =>
                                  setIsOpenDeleteAccountModal({
                                    ...isOpenDeleteAccountModal,
                                    id: item.id,
                                    status: true,
                                  })
                                }
                                icon={() => <Icon source={DeleteMinor} tone="base" />}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <RegularText>
                <RegularText>Steps to get “Cliend ID” and “Secret key”</RegularText>
                <div className="mt-8">
                  <RegularText>
                    <List type="number">
                      <List.Item>
                        <Link removeUnderline url={LINK.paypalHomePage} external monochrome>
                          Go to Paypal Developer Dashboard
                        </Link>
                      </List.Item>
                      <List.Item>
                        Click “Log in to Dashboard” and log in with your account (If you’ve already logged in, skip this step).
                      </List.Item>
                      <List.Item>
                        On the navigation bar, go to&nbsp;
                        <Link removeUnderline url={LINK.paypalCredentials} external monochrome>
                          “Apps & Credentials”
                        </Link>
                        &nbsp;menu. Switch to “Live mode” using the toggle on the top-right corner.
                      </List.Item>
                      <List.Item>
                        Click&nbsp;
                        <Link removeUnderline url={LINK.paypalCreateApp} external monochrome>
                          “Create App”
                        </Link>
                      </List.Item>
                      <List.Item>Enter App Name and click “Create App”</List.Item>
                      <List.Item>
                        Scroll down to “Feature” section and make sure that the “Transaction search” setting is checked.
                      </List.Item>
                      <List.Item>Scroll up to “API credentials” section, copy “Client ID” and “Secret key”.</List.Item>
                      <List.Item>
                        Click <b>"Add account"</b> and enter the <b>Client ID</b> and <b>Secret key</b> into the text field.
                      </List.Item>
                    </List>
                  </RegularText>
                </div>
              </RegularText>
            )}
            <div className="mt-16">
              <Button
                variant="primary"
                onClick={() => {
                  setState({ ...state, isOpen: true });
                }}
                icon={PlusMinor}
                disabled={scope.isViewOnly}
              >
                Add account
              </Button>
            </div>
          </div>
        </CustomCard>
      </div>

      <Modal
        open={state.isOpen}
        onClose={clearState}
        title={state.type === 'add' ? 'Add payment account' : 'Edit payment account'}
        primaryAction={{
          content: 'Save',
          disabled: (state.clientId === '' || state.secret === '') && state.type !== 'edit',
          onAction: () => {
            if (state.type === 'add') {
              createAccount({
                clientId: state.clientId,
                processOrders: state.processOrders,
                secret: state.secret,
              }).then((res) => {
                const status = checkShowErrorInline(res);
                if (status.status) {
                  setCritBanner({
                    status: true,
                    msg: status.msg,
                  });
                } else {
                  dispatch(commonSettingSlice.actions.handleToast(handleToastMutation(res)));
                  clearState();
                }
              });
            } else {
              updateAccount({
                id: state.id,
                isActive: state.isActive,
                secret: state.secret || undefined,
              }).then((res) => {
                const status = checkShowErrorInline(res);
                if (status.status) {
                  setState({
                    ...state,
                    errSecret: status.msg,
                  });
                } else {
                  dispatch(commonSettingSlice.actions.handleToast(handleToastMutation(res)));
                  clearState();
                }
              });
            }
          },
          loading: createAccountStatus.isLoading || updateAccountStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: clearState,
          },
        ]}
      >
        <Modal.Section>
          <CriticalBanner isVisible={critBanner.status}>{critBanner.msg}</CriticalBanner>
          <TextField
            label="Client ID:"
            value={state.clientId}
            onChange={(value) => {
              if (critBanner.status) {
                setCritBanner({
                  status: false,
                  msg: '',
                });
              }
              setState({
                ...state,
                clientId: value,
                errClientId: '',
              });
            }}
            onBlur={() => {
              if (!state.clientId) {
                setState({ ...state, errClientId: 'Client ID is required!' });
              }
            }}
            error={state.errClientId}
            type="text"
            autoComplete="off"
            disabled={state.type === 'edit'}
          />

          <TextField
            label="Secret key:"
            value={state.secret}
            onChange={(value) => {
              if (critBanner.status) {
                setCritBanner({
                  status: false,
                  msg: '',
                });
              }
              setState({
                ...state,
                secret: value,
                errSecret: '',
              });
            }}
            onBlur={() => {
              if (!state.secret) {
                if (state.type === 'add') {
                  setState({ ...state, errSecret: 'Secret is required!' });
                }
              }
            }}
            error={state.errSecret}
            type="text"
            autoComplete="off"
            placeholder="**********"
          />
          {state.type === 'edit' ? (
            <Checkbox
              label="Status"
              checked={state.isActive}
              onChange={(isActive) => {
                setState({
                  ...state,
                  isActive,
                });
              }}
            />
          ) : null}
          {state.type === 'add' ? (
            <TextField
              label="Process orders:"
              value={state.processOrders.toString()}
              onChange={(value) =>
                setState({
                  ...state,
                  processOrders: Number(value),
                })
              }
              suffix="day(s)"
              type="number"
              autoComplete="off"
              min={0}
              max={365}
            />
          ) : null}

          <div className="mt-8"></div>
          {/* <Link url={LINK.linkStripe} external>
            How to get Stripe token?
          </Link> */}
        </Modal.Section>
      </Modal>
      <Modal
        open={isOpenDeleteAccountModal.status}
        title={'Are you sure?'}
        onClose={() => {
          setIsOpenDeleteAccountModal({
            id: -1,
            status: false,
            error: {
              status: false,
              msg: '',
            },
          });
        }}
        sectioned
        footer={
          <div className="modal-footer">
            <Button
              onClick={() => {
                setIsOpenDeleteAccountModal({
                  id: -1,
                  status: false,
                  error: {
                    status: false,
                    msg: '',
                  },
                });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                onClick={() => {
                  deleteAccount(isOpenDeleteAccountModal.id).then((res) => {
                    const status = checkShowErrorInline(res);
                    if (status.status) {
                      setIsOpenDeleteAccountModal({
                        ...isOpenDeleteAccountModal,
                        error: {
                          status: status.status,
                          msg: status.msg,
                        },
                      });
                    } else {
                      setIsOpenDeleteAccountModal({
                        id: -1,
                        status: false,
                        error: {
                          status: false,
                          msg: '',
                        },
                      });
                      dispatch(commonSettingSlice.actions.handleToast(handleToastMutation(res)));
                    }
                  });
                }}
                variant="primary"
                tone="critical"
                loading={deleteAccountStatus.isLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <CriticalBanner isVisible={isOpenDeleteAccountModal.error.status}>{isOpenDeleteAccountModal.error.msg}</CriticalBanner>
        <RegularText>This account will not be synced</RegularText>
      </Modal>
    </FacebookAndInstagramStyled>
  );
};

export default memo(FacebookAndInstagramPayment);
