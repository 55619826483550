import { Text } from '@shopify/polaris';
import { memo } from 'react';

const RegularText = ({ children }: any) => {
  return (
    <Text as="span" variant="bodySm">
      {children}
    </Text>
  );
};

export default memo(RegularText);
