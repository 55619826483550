import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { Link } from '@shopify/polaris';

const Guide = () => {
  return (
    <div>
      <BoldText>Following steps below to create PayPal key :</BoldText>
      <RegularText>
        1.{' '}
        <Link removeUnderline external url="https://developer.paypal.com/developer/applications">
          Login to Paypal Developer Dashboard.
        </Link>
        <br />
        2. If you don't Login, click on <strong>Log into Dashboard</strong> <br />
        3. On{' '}
        <Link removeUnderline external url="https://developer.paypal.com/developer/applications">
          <strong>My Apps & Credentials</strong> menu,
        </Link>{' '}
        Click <strong>Live</strong> box.
        <br />
        4. Click{' '}
        <Link removeUnderline external url="https://developer.paypal.com/developer/applications/create?env=live">
          <strong>Create App</strong>
        </Link>{' '}
        Click <strong>Live</strong> box.
        <br />
        5. Type <strong>App Name</strong> and click <strong>Create App</strong>
        <br />
        6. In the <strong>APP SETTINGS</strong> box, in the <strong>App feature options</strong> section. You have only selected{' '}
        <strong>Customer Disputes & Transaction Search</strong> to authorize us to send Tracking code
        <br />
        7. Copy <strong>Client ID</strong> & <strong>Secret</strong> and paste on Form
      </RegularText>
    </div>
  );
};

export default Guide;
