import RegularText from '@/components/RegularText';
import { AlertInline } from '@/components/alertInline';
import SettingToggle from '@/components/settingToggle';
import { Plan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DigitalProductStyled } from './styled';
import useScope from '@/hooks/Scope';
const DigitalProduct = () => {
  const scope = useScope();
  const navigate = useNavigate();
  const dataSettings = useSelector(dataSettingsSelector);
  const loadingSettings = useSelector(loadingSelector);
  const [lazyUpdateDigitalProduct, { isLoading }] = apiCaller.useUpdateDigitalProductMutation();
  const dispatch = useDispatch();

  const handleUpdateDigitalProduct = () => {
    lazyUpdateDigitalProduct({ digitalProduct: dataSettings?.syncDigitalProduct === 0 ? 1 : 0 }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  const contentStatus = dataSettings?.syncDigitalProduct === 1 ? 'Turn off' : 'Turn on';
  return (
    <DigitalProductStyled>
      <SettingToggle
        disableStatus={disablePlan([Plan.FREE]) || scope.isViewOnly}
        settingToggleProps={{
          action: {
            onAction: handleUpdateDigitalProduct,
            content: contentStatus,
            loading: isLoading || loadingSettings,
          },
          enabled: dataSettings?.syncDigitalProduct === 1,
        }}
        title="Sync tracking info for digital products"
      >
        <div>
          <RegularText>
            This feature serves stores that sell products that do not require shipping, services, movies, courses,...
          </RegularText>
          {dataSettings?.plan === Plan.FREE ? (
            <div className="mt-8">
              <AlertInline
                content="This function is only available when using"
                link="Basic plan or higher"
                navigate={() => {
                  navigate(PATH.ACCOUNT, {
                    state: {
                      prePathName: 'digitalProduct',
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </SettingToggle>
    </DigitalProductStyled>
  );
};

export default DigitalProduct;
