import styled from 'styled-components';

export const CustomSettingToggleStyled = styled.div`
  .Polaris-Badge {
    margin-left: 4px;
  }
  .toggle-btn {
    display: flex;
    justify-content: end;
    height: fit-content;
  }
  .toggle-settings-title {
    display: flex;
    align-items: center;
    .Polaris-Text--root {
      display: flex;
    }
    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
      width: 2rem;
    }
  }
  .Polaris-SkeletonDisplayText__DisplayText {
    width: 4.5rem;
  }
  .ml-4 {
    margin-left: 0.25rem;
  }
  .toggle-settings-container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
`;
