import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import config from '@/config';
import { Plan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { IResponseApi } from '@/types/api/response.api';
import { IHomePage } from '@/types/components';
import { Button, Card, Icon } from '@shopify/polaris';
import { AlertMinor, ChevronDownMinor, CircleTickOutlineMinor, FlagMajor, ListMajor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuickHelpItemStyled } from './styled';
import { useSelector } from 'react-redux';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

interface Props extends IHomePage.IQuickHelpConfig {
  dataAnalys: number;
  dataSummary?: IResponseApi.IGetDataProcessedResponse;
}

const getLimit = (fetchDataSettings: IResponseApi.IGetDataSettingsResponse) => {
  switch (fetchDataSettings.plan) {
    case Plan.FREE:
      return '30';
    case Plan.BASIC:
      return '500';
    case Plan.PRO:
      return '6,000';
    case Plan.PREMIUM:
      return '25,000';
    case Plan.UNLIMITED:
      return 'Unlimited';
    default:
      return '30';
  }
};

const overQuotaMarkup = (
  dataSummary: IResponseApi.IGetDataProcessedResponse,
  fetchDataSettings?: IResponseApi.IGetDataSettingsResponse,
) => {
  if (fetchDataSettings) {
    return (
      <RegularText>
        Your plan is on <b>{fetchDataSettings.plan}</b>. This plan supports <b>{getLimit(fetchDataSettings)}</b> trackings/month.
        <br />
        This month your store has <b>{dataSummary?.allOrderFulfilled}</b> fulfilled orders. Therefore, our app sucessfully synced{' '}
        <b>{dataSummary?.orderSubmitted}</b> for you while <b>{dataSummary?.orderOverPlan}</b> orders are not supported because of
        over quota for this subscription.
      </RegularText>
    );
  }
};

const QuickHelpMarkup = (item: Props, dataSummary: IResponseApi.IGetDataProcessedResponse): JSX.Element => {
  const navigate = useNavigate();
  const isSkip = useSelector(isSkipApiSelector);

  const fetchDataSettings = apiCaller.useFetchDataSettingQuery(config.shop, { skip: isSkip }).data;
  const handleClick = () => {
    if (item.keyValue === 'overQuota') {
      navigate(PATH.ACCOUNT, {
        state: {
          prePathName: 'quickHelp',
        },
      });
    } else {
      if ($crisp) {
        $crisp.push(['do', 'chat:open']);
      }
    }
  };
  return (
    <div className="quick-help-inner">
      {item.keyValue === 'overQuota'
        ? overQuotaMarkup(dataSummary, fetchDataSettings)
        : item.content.map((text, index) => <div key={index}>{text}</div>)}
      {item.solution && (
        <>
          <div className="quick-help-heading-solution">
            <div className="solution-title">
              <Icon source={CircleTickOutlineMinor} tone="success" />
              <BoldText>Solution</BoldText>
            </div>
          </div>
          {item.keyValue === 'invalidAccount' ? (
            <>
              <p>
                Make sure you are connecting PayPal account: <b>{fetchDataSettings?.listAccountMissing} </b>
              </p>
            </>
          ) : (
            item.solution.map((txt: React.ReactNode, index: number) => <div key={index}>{txt}</div>)
          )}
        </>
      )}

      {item.solution2?.map((txt2: React.ReactNode, index: number) => (
        <div key={index}>
          <div className="quick-help-heading-solution">
            <div className="solution-title">
              <Icon source={CircleTickOutlineMinor} tone="success" />
              <BoldText>Solution</BoldText>
            </div>
          </div>
          {txt2}
        </div>
      ))}
      <Button icon={FlagMajor} onClick={handleClick}>
        {item.button}
      </Button>
    </div>
  );
};

export const QuickHelpItem = ({ ...props }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.dataAnalys > 0);

  return (
    <QuickHelpItemStyled>
      <Card>
        <div className="quick-help-content">
          <div className="quick-help-heading" onClick={() => setIsOpen(!isOpen)}>
            <div className="heading-title">
              <Icon
                source={props.dataAnalys > 0 ? AlertMinor : ListMajor}
                tone={`${props.dataAnalys > 0 ? 'critical' : 'interactive'}`}
              />
              <BoldText>{props.title}</BoldText>
            </div>
            <div
              style={{
                maxHeight: '20px',
                maxWidth: '20px',
                transition: '.5s',
                transform: isOpen ? undefined : 'rotate(-90deg)',
              }}
            >
              <Icon source={ChevronDownMinor} />
            </div>
          </div>
          {isOpen && (
            <div
              style={{
                transition: '.5s',
              }}
            >
              <QuickHelpMarkup {...props} />
            </div>
          )}
        </div>
      </Card>
    </QuickHelpItemStyled>
  );
};
