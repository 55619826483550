import { IHomePage } from '@/types/components';
import { Button, Scrollable } from '@shopify/polaris';
import { memo, useCallback } from 'react';
import { configQuickHelp } from './ConfigQuickHelp';
import { QuickHelpItem } from './QuickHelpItem';
import { QuickHelpStyled } from './styled';

const QuickHelp = ({ ...props }: IHomePage.IQuickHelpProps) => {
  const calculatorPercent = useCallback(
    (numerator: number): number => {
      if (props.dataSummary) {
        return Math.round((numerator / props.dataSummary.orderPaypalStripe) * 100);
      }
      return 0;
    },
    [props.dataSummary],
  );
  const dataAnalys: any = {
    success: calculatorPercent(props.dataSummary?.orderSubmitted || 0),
    orderPending: calculatorPercent(props.dataSummary?.orderPending || 0),
    noTracking: calculatorPercent(props.dataSummary?.orderNoTracking || 0),
    noTransaction: calculatorPercent(props.dataSummary?.orderNoTransactionId || 0),
    unconfirmAcc: calculatorPercent(props.dataSummary?.orderUnconfirmAccount || 0),
    invalidTrackingNumber: calculatorPercent(props.dataSummary?.orderInvalidTrackingNumber || 0),
    invalidAccount: calculatorPercent(props.dataSummary?.orderInvalidAccount || 0),
    overQuota: calculatorPercent(props.dataSummary?.orderOverPlan || 0),
  };

  configQuickHelp.sort((a, b) => dataAnalys[b.keyValue] - dataAnalys[a.keyValue]);

  return (
    <QuickHelpStyled title="Quick Help - Why your orders sync error?" open={props.isOpen} onClose={props.handleClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Scrollable style={{ padding: '10px', height: '100%' }}>
          <div className="quick-help-container">
            {configQuickHelp.map((item) => {
              return (
                <QuickHelpItem
                  dataSummary={props.dataSummary}
                  dataAnalys={dataAnalys[item.keyValue]}
                  {...item}
                  key={item.keyValue}
                />
              );
            })}
          </div>
        </Scrollable>
        <div
          style={{
            alignItems: 'center',
            borderTop: '1px solid #DFE3E8',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
            width: '100%',
          }}
        >
          <Button onClick={props.handleClose}>Close</Button>
        </div>
      </div>
    </QuickHelpStyled>
  );
};

export default memo(QuickHelp);
