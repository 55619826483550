import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IResponseApi } from '@/types/api/response.api';
import config from '@/config';

export const apiAdminCaller = createApi({
  reducerPath: 'apiAdminCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_ENDPOINT,
    prepareHeaders: (headers) => {
      if (config.source) {
        headers.set('authorization', config.token || '');
      }
      return headers;
    },
  }),
  // get data settings
  endpoints: (builder) => ({
    fetchProfile: builder.query<IResponseApi.IGetUserProfile, void>({
      query: () => {
        return {
          url: 'user/profile',
          method: 'GET',
        };
      },
    }),
  }),
});
