import { IHelperCenterComponent } from '@/types/components';
import { Button, Modal, Scrollable } from '@shopify/polaris';
import FAQs from '../FAQs';

const HelpCenter = ({ ...props }: IHelperCenterComponent.IProps) => {
  return (
    <div className="faq-button">
      <div className="group-btn">
        <div className="otGroupButton">
          <div
            group-id={5305}
            data-scroll={0}
            style={{ bottom: '35%' }}
            className="cta_group_preview  display_everywhere vertical position_right medium style2"
          ></div>
        </div>
      </div>
      <Modal open={props.isOpenFaqs} onClose={props.handleOpenFaqs} title="Help Center">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Scrollable style={{ padding: '0px 16px', height: '100%' }}>
            <FAQs />
          </Scrollable>
          <div
            style={{
              alignItems: 'center',
              borderTop: '1px solid #DFE3E8',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px',
              width: '100%',
            }}
          >
            <Button onClick={props.handleOpenFaqs}>Close</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HelpCenter;
