import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, listStoreSelector } from '@/redux/slice/masterData.slice';
import { IOldOrderBanner } from '@/types/components';
import { Card, InlineGrid, Modal, Select, TextField } from '@shopify/polaris';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BoldText from '../BoldText';
import CustomButton from '../CustomButton';
import RegularText from '../RegularText';
import { OldOrderBannerStyled } from './styled';

const OldOrderBanner = () => {
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const { pathname } = useLocation();
  const [state, setState] = useState<IOldOrderBanner.IState>({
    storeSelected: '',
    isOpenModal: false,
    oldOrderRange: '30',
    validateMessage: '',
  });

  const clearState = () => {
    setState({
      storeSelected: '',
      isOpenModal: false,
      oldOrderRange: '30',
      validateMessage: '',
    });
  };
  const listStore = useSelector(listStoreSelector);
  const [fetchOldOrder, oldOrderStatus] = apiCaller.useLazyFetchProcessGetOldOrdersQuery();

  const optionsStore = useMemo(() => {
    const result = listStore?.map((item) => {
      return {
        label: item.shop,
        value: item.shop,
      };
    });
    return result;
  }, [listStore]);

  const handleGetOldOrder = () => {
    fetchOldOrder({ oldOrderRange: Number(state.oldOrderRange), store: state.storeSelected, path: pathname }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        clearState();
      } else {
        setState({
          ...state,
          validateMessage: condition.msg,
        });
      }
    });
  };

  const handleMaxOldOrderRange = (value: string) => {
    let result = +value;
    if (result > 365) {
      result = 365;
    }
    setState({ ...state, oldOrderRange: result.toString(), validateMessage: '' });
  };

  const after3Days = useMemo(() => {
    if (dataSettings) {
      const now = new Date();
      const result = (now.getTime() - dataSettings?.installedAt * 1000) / 86400000;
      return result > 3;
    }
  }, [dataSettings]);
  return !after3Days ? (
    <OldOrderBannerStyled className="w-100 ml-3 mr-3">
      <Card>
        <InlineGrid columns={['twoThirds', 'oneThird']}>
          <div>
            <BoldText>Sync your previous orders</BoldText>
            <div className="mt-8">
              <RegularText>
                Orders will be <strong>auto-synced</strong> to PayPal as soon as you mark the order as <strong>fulfilled </strong>{' '}
                and add the tracking number.
              </RegularText>
            </div>
          </div>

          <div className="old-orders-btn">
            <CustomButton
              onClick={() => {
                setState({ ...state, isOpenModal: true });
              }}
              variant="primary"
            >
              Process Old orders
            </CustomButton>
          </div>
        </InlineGrid>
      </Card>

      <Modal
        open={state.isOpenModal}
        onClose={() =>
          setState({
            ...state,
            isOpenModal: false,
          })
        }
        title="Would you like to process the old orders?"
        primaryAction={{
          disabled: state.oldOrderRange === '',
          content: 'OK',
          onAction: handleGetOldOrder,
          loading: oldOrderStatus.isFetching,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: clearState,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>The range for getting old orders (max 365 days)</RegularText>
          <div className="mt-8">
            <TextField
              label=""
              autoComplete="off"
              type="number"
              value={state.oldOrderRange}
              onChange={(value) => handleMaxOldOrderRange(value)}
              onBlur={() => {
                if (!state.oldOrderRange) {
                  setState({
                    ...state,
                    validateMessage: 'Date range is required',
                  });
                }
              }}
              error={state.validateMessage}
              min={0}
              max={365}
            />
          </div>
          {optionsStore && optionsStore.length > 1 ? (
            <Select
              label="Get old orders for store:"
              options={optionsStore}
              onChange={(value) =>
                setState({
                  ...state,
                  storeSelected: value,
                })
              }
              value={state.storeSelected}
            />
          ) : null}
        </Modal.Section>
      </Modal>
    </OldOrderBannerStyled>
  ) : null;
};

export default memo(OldOrderBanner);
