import { IndexFiltersProps } from '@shopify/polaris';

export const sortOptions: IndexFiltersProps['sortOptions'] = [
  { label: 'Order', value: 'name asc', directionLabel: 'Ascending' },
  { label: 'Order', value: 'name desc', directionLabel: 'Descending' },
  { label: 'Date', value: 'createdAt asc', directionLabel: 'Ascending' },
  { label: 'Date', value: 'createdAt desc', directionLabel: 'Descending' },
  { label: 'Synced at', value: 'addTrackingAt asc', directionLabel: 'Ascending' },
  { label: 'Synced at', value: 'addTrackingAt desc', directionLabel: 'Descending' },
];
