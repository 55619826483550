export const COLORS = {
  primary: '#198754',
  bg_success: '#f1f8f5',
  bg_critical: '#fff4f4',
  bg_warning: '#fff5ea',
  bg_info: 'rgba(222, 245, 247, 1)',
  text_warning: 'rgba(145, 106, 0, 1)',
  bgHover: '#f1f1f180',
  backgroundColor: '#f1f1f1',
};
