/* eslint-disable jsx-a11y/iframe-has-title */
import BoldText from '@/components/BoldText';
import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import Card from '@/components/card';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/masterData.slice';
import { RangeSlider } from '@shopify/polaris';
import { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  highlighted: boolean;
}

const BuyMultiStores = ({ highlighted }: IProps) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(2);
  const [mutationActivePlan, { isLoading }] = apiCaller.useActivePlanMutation();
  const dataSettings = useSelector(dataSettingsSelector);
  const changeExtraStore = useCallback((store: number) => {
    let cost = 0;
    const addStore = store - 2;

    if (addStore < 6) {
      cost = 30 * addStore;
    }
    if (5 < addStore && addStore < 11) {
      cost = 30 * 5 + 26 * (addStore - 5);
    }
    if (addStore > 10) {
      cost = 30 * 5 + 26 * 5 + 20 * (addStore - 10);
    }
    let result = 0;
    result = Number((cost + 39.99).toFixed(2));

    return result;
  }, []);

  const buyExtraStores = () => {
    if (dataSettings) {
      mutationActivePlan({
        type: dataSettings.plan,
        subscription: dataSettings.subscription,
        dayProcess: 0,
        extraStores: state - 2,
        extraCost: changeExtraStore(state) - 39.99,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        }
        if ('data' in res) {
          if (res.data.confirmationUrl) {
            window.top?.location.replace(res.data.confirmationUrl);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (dataSettings) {
      setState(dataSettings?.slotMultiStores);
    }
  }, [dataSettings]);

  return (
    <div className={highlighted ? 'integration-page mt-16 multiple-store-highlight' : 'integration-page mt-16'}>
      <Card title="Buy more slots to integrate for unlimited">
        <table width="100%">
          <thead className="text-center">
            <tr>
              <th>
                <BoldText>From 1 to 5 stores</BoldText>
              </th>
              <th>
                <BoldText>From 6 to 10 stores</BoldText>
              </th>
              <th>
                <BoldText>10+ stores</BoldText>
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td>
                <RegularText>
                  <b>$30</b>/store monthly
                </RegularText>
              </td>
              <td>
                <RegularText>
                  <b>$26</b>/store monthly
                </RegularText>
              </td>
              <td>
                <RegularText>
                  <b>$20</b>/store monthly
                </RegularText>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div className="mt-16">
            {dataSettings?.slotMultiStores ? (
              <RegularText>
                Current: <b>{dataSettings?.slotMultiStores} slots</b> -{' '}
                {state >= dataSettings?.slotMultiStores ? 'Buy more: ' : 'Reduce: '}
                <b>{Math.abs(state - dataSettings?.slotMultiStores)} slots</b> - Total: <b>{state} slots</b> - Final cost:{' '}
                <b>${changeExtraStore(state)}</b>
              </RegularText>
            ) : null}
          </div>
          <div className="d-flex mt-16">
            <div className="align-center">
              <RangeSlider
                value={state}
                min={2}
                max={100}
                onChange={(value) => setState(Number(value))}
                output
                label={undefined}
              />
            </div>
            <div className="w-25 text-left ml-10">
              <CustomButton
                disabled={state === dataSettings?.slotMultiStores}
                loading={isLoading}
                onClick={() => buyExtraStores()}
                tone="success"
              >
                Buy Now
              </CustomButton>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default memo(BuyMultiStores);
