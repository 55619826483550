import styled from 'styled-components';

export const IntergrationStoreStyled = styled.div`
  font-size: 14px;
  td,
  th {
    padding: 15px;
  }
  .table {
    .Polaris-Link {
      font-weight: 700 !important;
    }
  }

  .store-content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .store-content-top-btn {
      display: flex;
      align-items: center;
    }
  }

  .integration-page-layout {
    padding: 0.75rem;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .connection-key-layout {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    .Polaris-Text--headingSm {
      padding: 0.5rem 0;
    }
    .key {
      display: inline-block;
      line-height: 1;
      vertical-align: baseline;
      margin-left: 10px;
      background-color: #e8e8e8;
      background-image: none;
      padding: 0.5rem;
      color: rgba(0, 0, 0, 0.6);
      text-transform: none;
      border: 0 solid transparent;
      border-radius: 0.28571429rem;
    }
  }

  .integration-layout {
    border: 1px solid #ddd;
    padding: 20px;
    cursor: pointer;
  }

  .integration-layout:hover {
    border: 1px solid rgb(0, 126, 73);
    padding: 20px;
  }

  .statistical-layout-icon {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    .Polaris-Icon {
      max-width: 60px;
      min-width: 60px;
      max-height: 60px;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Polaris-Icon__Svg {
      max-width: 30px;
      min-width: 30px;
      max-height: 30px;
      min-height: 30px;
    }
  }
  table {
    border-collapse: collapse;
  }
  table tr,
  th,
  td {
    border: 1px solid #dee2e6;
  }

  .alertText {
    justify-content: center;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
  .mt-30 {
    margin-top: 30px !important;
  }
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .align-center {
    width: 75%;
    margin-top: 10px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .lh-20 {
    .Polaris-Text--root {
      line-height: 1.25rem;
    }
  }
  /* multiple-store-highlight */
  .multiple-store-highlight {
    border: 2px solid #0070f3; /* Màu xanh của border */
    border-radius: 16px; /* Bo góc */
    outline: none; /* Bỏ mặc định viền outline khi focus */
  }

  .multiple-store-highlight:focus {
    outline: 2px solid #0070f3; /* Viền outline khi focus */
    outline-offset: 0; /* Khoảng cách của outline với viền */
  }

  .multiple-store-highlight::-webkit-inner-spin-button,
  .multiple-store-highlight::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Bỏ nút tăng giảm số lượng */
    margin: 0; /* Loại bỏ khoảng cách của spin button */
  }
`;
