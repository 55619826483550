import { Badge, Button, Card, SkeletonBodyText, SkeletonDisplayText, Text } from '@shopify/polaris';
import { ISettingToggle } from '@/types/components';
import { memo } from 'react';
import { CustomSettingToggleStyled } from './styled';
import { useSelector } from 'react-redux';
import { loadingSelector } from '@/redux/slice/masterData.slice';
import RegularText from '../RegularText';

const CustomSettingToggle = ({ ...props }: ISettingToggle.IProps) => {
  const isLoading = useSelector(loadingSelector);
  return (
    <CustomSettingToggleStyled>
      <Card>
        <div className="toggle-settings-container">
          <div>
            <div className="toggle-settings-title">
              <Text as="h5" variant="headingMd">
                {props.title}
              </Text>
              <div className="ml-4">
                {isLoading ? (
                  <SkeletonBodyText lines={1} />
                ) : (
                  <Badge tone={props.settingToggleProps.enabled ? 'success' : 'critical'}>
                    {props.settingToggleProps.enabled ? 'On' : 'Off'}
                  </Badge>
                )}
              </div>
            </div>

            <div className="mt-8">
              <RegularText>{props.children}</RegularText>
            </div>
          </div>

          <div className="toggle-btn">
            {isLoading ? (
              <SkeletonDisplayText size="small" />
            ) : (
              <Button
                disabled={props.disableStatus}
                onClick={props.settingToggleProps.action?.onAction}
                loading={props.settingToggleProps.action?.loading}
                variant={props.settingToggleProps.enabled ? undefined : 'primary'}
              >
                {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </div>
      </Card>
    </CustomSettingToggleStyled>
  );
};

export default memo(CustomSettingToggle);
