import styled from 'styled-components';

export const UploadFileStyled = styled.div`
  .Polaris-LegacyStack {
    position: relative;
  }
  .remove-icon {
    position: absolute;
    right: 0;
    z-index: 2;
    background-color: white;
  }
  img {
    height: 100%;
    width: 100%;
  }
  .Polaris-LegacyStack__Item {
    margin-top: 24px;
  }
`;
