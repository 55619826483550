export enum Plan {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
  PREMIUM = 'premium',
  UNLIMITED = 'unlimited',
}

export enum OfferType {
  REFUND = 'REFUND',
  REFUND_WITH_RETURN = 'REFUND_WITH_RETURN',
  REFUND_WITH_REPLACEMENT = 'REFUND_WITH_REPLACEMENT',
  REPLACEMENT_WITHOUT_REFUND = 'REPLACEMENT_WITHOUT_REFUND',
}

export enum CountryCodePaypalRequired {
  REQUIRED = 1,
  OPTIONAL = 2,
  NOT_USE = 3,
}

export enum EvidenceType {
  FULFILLMENT = 'PROOF_OF_FULFILLMENT',
  REFUND = 'PROOF_OF_REFUND',
  OTHER = 'OTHER',
}

export enum BREAKPOINT {
  XS = 490,
  SM = 768,
  MD = 1040,
  LG = 1800,
  XL = 1920,
}

export enum DisputeStage {
  INQUIRY = 'INQUIRY',
  CHARGEBACK = 'CHARGEBACK',
  PRE_ARBITRATION = 'PRE_ARBITRATION',
  ARBITRATION = 'ARBITRATION',
}
export enum DisputeStateEnum {
  OPEN_INQUIRIES = 'OPEN_INQUIRIES',
  REQUIRED_ACTION = 'REQUIRED_ACTION',
  REQUIRED_OTHER_PARTY_ACTION = 'REQUIRED_OTHER_PARTY_ACTION',
  UNDER_PAYPAL_REVIEW = 'UNDER_PAYPAL_REVIEW',
  APPEALABLE = 'APPEALABLE',
  RESOLVED = 'RESOLVED',
}
export enum OptionsOfferType {
  OPEN_INQUIRIES = 'OPEN_INQUIRIES',
  REQUIRED_ACTION = 'REQUIRED_ACTION',
  REQUIRED_OTHER_PARTY_ACTION = 'REQUIRED_OTHER_PARTY_ACTION',
  UNDER_PAYPAL_REVIEW = 'UNDER_PAYPAL_REVIEW',
  APPEALABLE = 'APPEALABLE',
  RESOLVED = 'RESOLVED',
}

export enum NotificationType {
  CUSTOM = 0,
  DISCOUNT_EXPIRE = 1,
  DISPUTE_UPDATE = 2,
  SYNC_ERROR = 3,
  NEW_VERSION = 4,
  NEW_FEATURE = 5,
}

export enum NotificationPriority {
  INFORMATION = 1,
  ATTENTION = 2,
  CRITICAL = 3,
}

export enum CourierRule {
  NAME = 'name',
  START_WITH = 'start',
  END_WITH = 'end',
}

export enum CourierIgnoreRule {
  COURIER = 'courier',
  COURIER_START = 'courier_start',
  TRACKING = 'tracking',
  TRACKING_START = 'tracking_start',
}

export enum EmailConsentValue {
  ST_UPDATE = 'st_update',
  REPORT = 'report',
  PROMO = 'promo',
  PAYMENT_TIPS = 'payment_tips',
}

export enum EmailConsentPoisition {
  WELCOME = 'consent_welcome',
  OLD_ORDER = 'consent_old_order',
  SYNCED_ORDER = 'consent_synced_order',
}

export enum Subscription {
  Monthly = 'monthly',
  Annualy = 'annually',
}

export enum Payment {
  Paypal = 'paypal',
  Stripe = 'stripe',
  PaypalAndStripe = 'paypal_stripe',
  Other = 'other',
  All = 'all',
}

export enum OrderStatus {
  NoTrackingNumber = '-1',
  AccountInvalid = '3',
  InQueue = '0',
  OverQuota = '2',
  Synced = '1',
  All = 'all',
  NoTransactionId = '4',
  MissingPaypalAccount = '6',
  TrackingNumberInvalid = '5',
  StorePickup = '7',
  AllError = 'total_err',
}

export enum UserPermission {
  View = 'view',
  ViewSensitive = 'view_sensitive',
  Edit = 'edit',
  FullAccess = 'full_access',
}

export enum UserRole {
  Admin = 'admin',
  Guest = 'guest',
  Support = 'supporter',
}

export enum App {
  Synctrack = 'synctrack',
  Blockify = 'blockify',
  Floatify = 'floatify',
  OT = 'ordertracking',
  Return = 'returnsdrive',
}

export enum Social {
  Slack = 'slack',
  Zalo = 'zalo',
  Email = 'email',
  WhatsApp = 'whatsapp',
}

export enum PixelBanner {
  VariantsA = 'pixel_a',
  VariantsB = 'pixel_b',
}

export enum Ga4Event {
  CheckoutRulesImpression = 'impress_checkouts',
  EstimatedImpression = 'impress_est',
  OTImpression = 'impress_ot',
  ReturnImpression = 'impress_returns',
  CheckoutRulesClick = 'click_checkouts',
  EstimatedClick = 'click_est',
  OTClick = 'click_ot',
  ReturnClick = 'click_returns',
  paypalSetting = 'paypal_settings_impression',
  stripeSetting = 'stripe_settings_impression',
  choosePlan = 'choose_plan',
  pricingPlan = 'pricing_plan_impression',
  addDiscountCode = 'add_discount_code',
}
