import styled from 'styled-components';
export const OldOrderBannerStyled = styled.div`
  margin-top: 1rem;
  .mr-20 {
    margin-right: 20px;
  }
  .old-orders-btn {
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;
