import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { listStoreSelector } from '@/redux/slice/masterData.slice';
import { Modal, Select, TextField } from '@shopify/polaris';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  handleIsOpen: (status: boolean) => void;
}

const ProcessOldOrders = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    storeSelected: '',
    oldOrderRange: '30',
    validateMessage: '',
  });
  const listStore = useSelector(listStoreSelector);
  const [fetchOldOrder, oldOrderStatus] = apiCaller.useLazyFetchProcessGetOldOrdersQuery();
  const clearState = () => {
    props.handleIsOpen(false);
    setState({
      storeSelected: '',
      oldOrderRange: '30',
      validateMessage: '',
    });
  };
  const optionsStore = useMemo(() => {
    const result = listStore?.map((item) => {
      return {
        label: item.shop,
        value: item.shop,
      };
    });
    return result;
  }, [listStore]);

  const handleGetOldOrder = () => {
    fetchOldOrder({ oldOrderRange: Number(state.oldOrderRange), store: state.storeSelected, path: pathname }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        clearState();
      } else {
        setState({
          ...state,
          validateMessage: condition.msg,
        });
      }
    });
  };

  const handleMaxOldOrderRange = (value: string) => {
    let result = +value;
    if (result > 365) {
      result = 365;
    }
    setState({ ...state, oldOrderRange: result.toString(), validateMessage: '' });
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={clearState}
      title="Process old orders"
      primaryAction={{
        disabled: state.oldOrderRange === '',
        content: 'OK',
        onAction: handleGetOldOrder,
        loading: oldOrderStatus.isFetching,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: clearState,
        },
      ]}
    >
      <Modal.Section>
        <div>
          The range for getting old orders (max 365 days)
          <div className="mt-8">
            <TextField
              label=""
              autoComplete="off"
              type="number"
              value={state.oldOrderRange}
              onChange={(value) => handleMaxOldOrderRange(value)}
              onBlur={() => {
                if (!state.oldOrderRange) {
                  setState({
                    ...state,
                    validateMessage: 'Date range is required',
                  });
                }
              }}
              error={state.validateMessage}
              min={0}
              max={365}
            />
          </div>
          {optionsStore && optionsStore.length > 1 ? (
            <Select
              label="Get old orders for store:"
              options={optionsStore}
              onChange={(value) =>
                setState({
                  ...state,
                  storeSelected: value,
                })
              }
              value={state.storeSelected}
            />
          ) : null}
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default memo(ProcessOldOrders);
