import { COLORS } from '@/constants/colors';
import styled from 'styled-components';

export const IgnoreSyncStyled = styled.div`
  .Polaris-InlineGrid {
    padding: 0 0.5rem;
  }
  .courier-mapping-content {
    .Polaris-InlineGrid {
      border-bottom: 1px solid ${COLORS.backgroundColor};
      padding: 0.5rem;
      border-radius: 0.5rem;
      .Polaris-Text--bodySm {
        line-height: 2rem;
      }
      .btn-group {
        display: none;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        background-color: ${COLORS.bgHover};
        .btn-group {
          display: flex;
        }
      }
    }
  }
`;
