import images from '@/assets/images.';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import PaypalButton from '@/components/paypalButton';
import config from '@/config';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, listPaypalSelector } from '@/redux/slice/masterData.slice';
import { Banner, Button, Collapsible, LegacyCard, LegacyStack, ProgressBar, Text, Tooltip } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReviewStepStyled } from './styled';
import Dismiss from './Dismiss';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

function ReviewStep() {
  const isSkip = useSelector(isSkipApiSelector);

  const dataSettings = useSelector(dataSettingsSelector);
  const listPayPalAccount = useSelector(listPaypalSelector);
  const fetchListStripeToken = apiCaller.useFetchListStripeTokenQuery(config.shop, { skip: isSkip });
  const [trigger] = apiCaller.useStoreReviewDisplayMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState({
    step1: true,
    step2: false,
    step3: false,
  });

  const [stepFinished, setStepFinished] = useState<Array<number>>([]);

  const handleCollapse = (key: keyof typeof collapse, value?: boolean) => () => {
    const result = { ...collapse };
    Object.keys(result).forEach((k) => {
      result[k as keyof typeof result] = false;
    });
    if (value === undefined) {
      setCollapse({
        ...result,
        [key]: !collapse[key],
      });
    } else {
      setCollapse({
        ...result,
        [key]: value,
      });
    }
  };

  const checkStepFinished = useCallback(
    (step: number) => {
      return !!stepFinished.find((item) => item === step);
    },
    [stepFinished],
  );

  const handleApplyReview = () => {
    const sendText = `I would like to know, how can I avoid PayPal holds & reduce disputes, and could you please review my store? Here is the link to my online store: ${config.shop}`;
    try {
      $crisp.push(['set', 'session:event', ['ReviewStorePayal']]);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
      trigger();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const listStepFinished: Array<number> = [];
    if (listPayPalAccount && fetchListStripeToken.data) {
      if (listPayPalAccount && listPayPalAccount?.length > 0) {
        listStepFinished.push(1);
      }
      if (fetchListStripeToken.data?.listStripeToken?.length) {
        listStepFinished.push(2);
      }
      const stepProcessing = Math.min(
        ...[1, 2, 3].filter((itemProcessing) => !listStepFinished.find((itemFinished) => itemFinished === itemProcessing)),
      );

      handleCollapse(`step${stepProcessing}` as keyof typeof collapse, true)();
      setStepFinished(listStepFinished);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListStripeToken.data, listPayPalAccount]);

  return dataSettings?.storeReviewDisplay ? (
    <ReviewStepStyled>
      <div>
        <div>
          <div className="dismiss-container">
            <div className="dismiss-btn">
              <Dismiss />
            </div>
            <Banner tone="warning" title="Store Review: Check your store to ensure compliance with PayPal standards">
              <RegularText>
                We're uncertain if your store complies with all PayPal guidelines and standards. Inadequate store setup can lead
                to risks facing your PayPal account such as <b>Money on hold, Account restrictions, Disputes, etc.</b>
                <br />
                In addition, ensuring your store is <b>full of necessary settings</b> and information will help you{' '}
                <b>reduce the rate of disputes and increase your chances of winning</b> when they do occur.
                <br />
                Apply now and we will help you do it
              </RegularText>

              <div className="mt-8">
                <Button onClick={handleApplyReview}>Apply now</Button>
              </div>
            </Banner>
          </div>
        </div>
        <div className="dismiss-container mt-16">
          <div className="dismiss-btn">
            <Dismiss />
          </div>
          <LegacyCard title={<BoldText>PayPal & Stripe Best Practices</BoldText>}>
            <div className="progress-container">
              <Text variant="bodyMd" as="p">
                Follow our user guideline to enhance security for your store
              </Text>

              <div className="progress">
                <div className="current-step">
                  <Text variant="bodyMd" as="p">{`${stepFinished.length} / 3 completed`}</Text>
                </div>
                <ProgressBar tone="primary" progress={(stepFinished.length / 3) * 100} size="small" />
              </div>
            </div>
            <div className={collapse.step1 ? 'thing-to-do-step-open' : 'thing-to-do-step-close'}>
              <div
                className="thing-to-do-title"
                onClick={handleCollapse('step1')}
                aria-expanded={collapse.step1}
                aria-controls="basic-collapsible-1"
              >
                <LegacyStack spacing="tight">
                  <Tooltip content={checkStepFinished(1) ? 'Mark as done' : 'Mark as not done'}>
                    <Button
                      id="circle"
                      variant="plain"
                      icon={checkStepFinished(1) ? <img src={images.tickIcon} alt="circle-tick" /> : <div className="circle" />}
                    />
                  </Tooltip>
                  {collapse.step1 ? (
                    <Text variant="headingSm" as="h6">
                      1. Connect PayPal account
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="p">
                      1. Connect PayPal account
                    </Text>
                  )}
                </LegacyStack>
              </div>
              <div className="step">
                <Collapsible
                  open={collapse.step1}
                  id="basic-collapsible-1"
                  transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                  expandOnPrint
                >
                  <div>
                    <Text variant="bodyMd" as="p">
                      Connect all the PayPal accounts you are using on the store to get the app working and solve the problem of
                      PayPal holding money
                    </Text>
                    <div className="mt-8">
                      <PaypalButton title="Connect now" />
                    </div>
                  </div>
                </Collapsible>
              </div>
            </div>
            <div className={collapse.step2 ? 'thing-to-do-step-open' : 'thing-to-do-step-close'}>
              <div
                aria-expanded={collapse.step2}
                aria-controls="basic-collapsible-2"
                className={'thing-to-do-title'}
                onClick={handleCollapse('step2')}
              >
                <LegacyStack spacing="tight">
                  <Tooltip content={checkStepFinished(2) ? 'Mark as done' : 'Mark as not done'}>
                    <Button
                      id="circle"
                      variant="plain"
                      icon={checkStepFinished(2) ? <img src={images.tickIcon} alt="circle-tick" /> : <div className="circle" />}
                    />
                  </Tooltip>
                  {collapse.step2 ? (
                    <Text variant="headingSm" as="h6">
                      2. Connect Stripe account
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="p">
                      2. Connect Stripe account
                    </Text>
                  )}
                </LegacyStack>
              </div>
              <div className="step">
                <Collapsible
                  open={collapse.step2}
                  id="basic-collapsible-2"
                  transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                  expandOnPrint
                >
                  <div>
                    <Text variant="bodyMd" as="p">
                      Please connect your Stripe account so that the application synchronizes order tracking information to Stripe
                      (if you use it). This will help protect your Stripe account and serve as evidence in the event of a dispute.
                    </Text>
                  </div>
                  <div className="mb-8 d-flex mt-8">
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(PATH.SETTINGS);
                        dispatch(slice.settingsPageSlice.actions.handleActiveKey('stripe'));
                      }}
                    >
                      Connect now
                    </Button>
                    <div className="ml-8">
                      <Button
                        onClick={() => {
                          if (!checkStepFinished(2)) {
                            const step = [...stepFinished];
                            step.push(2);
                            setStepFinished(step);
                            handleCollapse('step3')();
                          }
                        }}
                      >
                        I don't use Stripe
                      </Button>
                    </div>
                  </div>
                </Collapsible>
              </div>
            </div>
            <div className={collapse.step3 ? 'thing-to-do-step-open' : 'thing-to-do-step-close'}>
              <div
                className={'thing-to-do-title'}
                onClick={handleCollapse('step3')}
                aria-expanded={collapse.step3}
                aria-controls="basic-collapsible-3"
              >
                <LegacyStack spacing="tight">
                  <Tooltip content={checkStepFinished(3) ? 'Mark as done' : 'Mark as not done'}>
                    <Button
                      id="circle"
                      variant="plain"
                      icon={checkStepFinished(3) ? <img src={images.tickIcon} alt="circle-tick" /> : <div className="circle" />}
                    />
                  </Tooltip>
                  {collapse.step3 ? (
                    <Text variant="headingSm" as="h6">
                      3. Review Store
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="p">
                      3. Review Store
                    </Text>
                  )}
                </LegacyStack>
              </div>
              <div className="step">
                <Collapsible
                  open={collapse.step3}
                  id="basic-collapsible-3"
                  transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                  expandOnPrint
                >
                  <div>
                    <Text variant="bodyMd" as="p">
                      We'll help you audit your store to ensure compliance with PayPal standards as well as reduce disputes and
                      increase your dispute win rate.
                    </Text>
                  </div>
                  <div className="mt-8 mb-8">
                    <Button variant="primary" onClick={handleApplyReview}>
                      Apply review now
                    </Button>
                  </div>
                </Collapsible>
              </div>
            </div>
          </LegacyCard>
        </div>
      </div>
    </ReviewStepStyled>
  ) : null;
}

export default ReviewStep;
