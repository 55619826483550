import blockify_analytics from './blockify_analytics.png';
import blockifyBanner from './dashboard/blockify_banner.png';
import coockies_banner from './dashboard/cookies_banner.png';
import dashboard from './dashboard/dashboard.png';
import pixel from './dashboard/pixel.png';
import estimated_banner from './estimated_banner.png';
import email from './social/email.png';
import slack from './social/slack.png';
import whatsApp from './social/whatsapp.png';
import zalo from './social/zalo.png';
import tickIcon from './tick-circle.png';
import est_dashboard from './dashboard/estimated_dashboard.png';
import checkout_rules_dashboard from './dashboard/checkoutRules_dashboard.png';

export default {
  zalo,
  slack,
  whatsApp,
  email,
  pixel,
  blockifyBanner,
  dashboard,
  blockify_analytics,
  estimated_banner,
  coockies_banner,
  tickIcon,
  est_dashboard,
  checkout_rules_dashboard,
};
