import StepThreeImg from '@/assets/welcome/welcome_3.png';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { EmailConsentPoisition, EmailConsentValue } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/masterData.slice';
import { Button, Checkbox, InlineGrid, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const StepThree = () => {
  const [now] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataSettings = useSelector(dataSettingsSelector);
  const [emailConsent] = apiCaller.useEmailConsentMutation();
  const [emailConsentValue, setEmailConsentValue] = useState<Array<string>>([]);
  const [newEmail, setNewEmail] = useState('');
  const [isChecked, setIschecked] = useState({
    synctrackUpdate: true,
    monthlyReports: true,
    hotDeals: true,
    paymentAndDisputeTips: true,
    defaultEmail: true,
    newEmail: false,
  });
  const [updateWelcomeStep, stepLoading] = apiCaller.useUpdateWelcomStepMutation();
  const [updateWelcomeStatus, statusLoading] = apiCaller.useUpdateWelcomStatusMutation();

  const handleNext = () => {
    updateWelcomeStep({ step: 4 }).then((res) => {
      handleEmailConsent();
      dispatch(slice.WelcomeSlice.actions.handleStep(4));
    });
  };
  const handleChange = (key: string, value: string) => (checked: boolean) => {
    setIschecked({
      ...isChecked,
      [key]: checked,
    });
    const emailValue = [...emailConsentValue];
    const index = emailValue.indexOf(value);

    if (index === -1) {
      emailValue.push(value);
    } else {
      emailValue.slice(index, 1);
    }
    setEmailConsentValue(emailValue);
  };

  const handleSkip = () => {
    updateWelcomeStatus().then((res) => {
      navigate(PATH.DEFAULT);
    });
  };

  const handleEmailConsent = () => {
    const last = new Date();
    emailConsent({
      email: newEmail || undefined,
      position: EmailConsentPoisition.WELCOME,
      value: emailConsentValue.length > 0 ? emailConsentValue : undefined,
      displayTime: last.getTime() - now.getTime(),
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };
  return (
    <BannerTemplate src={StepThreeImg}>
      <BoldText>Subcribe to receive more insight on Dispute & Payment issue</BoldText>
      <div className="mt-16">
        <div>
          <div className="step-three-controller">
            <RegularText>Choose your favorite content</RegularText>
          </div>
          <div className="mt-8">
            <InlineGrid gap="400" columns={2}>
              <Checkbox
                label="Synctrack updates"
                checked={isChecked.synctrackUpdate}
                onChange={handleChange('synctrackUpdate', EmailConsentValue.ST_UPDATE)}
              />
              <Checkbox
                label="Monthly reports"
                checked={isChecked.monthlyReports}
                onChange={handleChange('monthlyReports', EmailConsentValue.REPORT)}
              />
              <Checkbox
                label="Hot deals"
                checked={isChecked.hotDeals}
                onChange={handleChange('hotDeals', EmailConsentValue.PROMO)}
              />
              <Checkbox
                label="Payment & Dispute tips"
                checked={isChecked.paymentAndDisputeTips}
                onChange={handleChange('paymentAndDisputeTips', EmailConsentValue.PAYMENT_TIPS)}
              />
            </InlineGrid>
          </div>
          <div style={{ marginTop: '16px' }}>
            <RegularText>By subscribing, you allow us to send emails to your inbox</RegularText>
          </div>

          <div className="mt-4px">
            <Checkbox
              label={
                <div className="d-flex">
                  <RegularText>Use&nbsp;</RegularText>
                  <BoldText>{dataSettings?.subscriptionEmailMarketing}</BoldText>
                </div>
              }
              checked={isChecked.defaultEmail}
              onChange={(checked: boolean) => {
                setIschecked({
                  ...isChecked,
                  newEmail: false,
                  defaultEmail: checked,
                });
              }}
            />
            <div
              className="d-flex mt-8 pointer"
              onClick={() => {
                if (!isChecked.newEmail) {
                  setIschecked({
                    ...isChecked,
                    newEmail: true,
                    defaultEmail: false,
                  });
                }
              }}
            >
              <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  label=""
                  labelHidden
                  checked={isChecked.newEmail}
                  onChange={(checked: boolean) => {
                    setIschecked({
                      ...isChecked,
                      newEmail: checked,
                      defaultEmail: false,
                    });
                  }}
                />
              </div>
              <div style={{ width: '100%' }}>
                <TextField
                  label=""
                  labelHidden
                  disabled={!isChecked.newEmail}
                  placeholder="Or enter your new email"
                  autoFocus
                  value={newEmail}
                  onChange={(value) => {
                    setNewEmail(value);
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 d-flex">
        <Button loading={statusLoading.isLoading} onClick={handleSkip}>
          Skip & Finish
        </Button>
        <div className="ml-8">
          <Button loading={stepLoading.isLoading} onClick={handleNext} variant="primary">
            Next step
          </Button>
        </div>
      </div>
    </BannerTemplate>
  );
};

export default memo(StepThree);
