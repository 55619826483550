import config from '@/config';
import { useEffect } from 'react';

const PermissionDenied = () => {
  useEffect(() => {
    if (window?.opener?.postMessage) {
      try {
        window.opener.postMessage({
          type: 'paypalConnected',
          success: true,
          code: config.code,
        });
        window.close();
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log('here error');
    }
  }, []);
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        fontSize: '4rem',
        textAlign: 'center',
        alignContent: 'center',
        lineHeight: '100vh',
      }}
    >
      Permission denied
    </div>
  );
};

export default PermissionDenied;
