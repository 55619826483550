import { AlertInline } from '@/components/alertInline';
import CustomCard from '@/components/card';
import OldOrderBanner from '@/components/oldOrderbanner';
import SettingToggle from '@/components/settingToggle';
import config from '@/config';
import { Ga4Event, Plan } from '@/constants/enum';
import { LINK } from '@/constants/link';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { orderTableOrderPageSelector } from '@/redux/slice/orderPage.slice';
import { isVisibleBannerStripeSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import { Badge, Button, Card, Icon, Link, Modal, SkeletonBodyText, Text, TextField, Tooltip } from '@shopify/polaris';
import { DeleteMinor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StripeSettingStyled } from './styled';
import RegularText from '@/components/RegularText';
import CustomButton from '@/components/CustomButton';
import useScope from '@/hooks/Scope';
import InformationBanner from '@/components/InformationBanner';
import bannerSlice, { CrossSellSelector } from '@/redux/slice/banner.slice';
import { useGa4 } from '@/hooks/useGa4';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
const StripeSetting = () => {
  const crossSell = useSelector(CrossSellSelector);
  const { handleGa4 } = useGa4();
  const scope = useScope();
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState({ status: false, id: -1 });
  const dataSettings = useSelector(dataSettingsSelector);
  const isVisibleBanner = useSelector(isVisibleBannerStripeSelector);
  const orderTable = useSelector(orderTableOrderPageSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const navigate = useNavigate();
  const [fetchLazySyncStripe, syncStripeStatus] = apiCaller.useUpdateSyncStripeMutation();
  const fetchListStripeToken = apiCaller.useFetchListStripeTokenQuery(config.shop, { skip: isSkip });
  const [saveStripeAccount, saveStripeStatus] = apiCaller.useSaveStripeAccountMutation();
  const [fetchLazyDeleteStripeAccount, deleteStripeStatus] = apiCaller.useDeleteStripeAccountMutation();
  const [state, setState] = useState<ISettingsPage.IStateStripeSetting>({
    id: undefined,
    token: '',
    isOpen: false,
    errStripeToken: '',
    type: 'add',
    isDisableSyncBtn: false,
  });

  const clearState = () => {
    setState({ id: undefined, token: '', isOpen: false, errStripeToken: '', type: 'add', isDisableSyncBtn: false });
  };

  const handleToggle = () => {
    const syncStripe = dataSettings && dataSettings.syncStripe === 0 ? 1 : 0;
    fetchLazySyncStripe({ syncStripe }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        dispatch(
          slice.orderPageSlice.actions.handleFiltersOrderTable({
            ...orderTable,
            paymentSelected: syncStripe === 1 ? ['paypal_stripe'] : ['paypal'],
          }),
        );
      }
    });
  };

  const handleSaveStripeAccount = () => {
    saveStripeAccount({
      id: state.id,
      stripeToken: state.token,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setState({ ...state, errStripeToken: condition.msg });
      } else {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        clearState();
      }
    });
  };

  const handleModalAddStripeToken = () => {
    setState({ ...state, type: 'add', isOpen: true, id: undefined });
  };

  const editStripeToken = (id: number) => () => {
    const stripeSelected = fetchListStripeToken.data && fetchListStripeToken.data.listStripeToken.find((item) => item.id === id);
    if (stripeSelected) {
      setState({ ...state, isOpen: true, token: stripeSelected?.token, id, type: 'edit' });
    }
  };

  const confirmDeleteStripeToken = (id: number) => () => {
    fetchLazyDeleteStripeAccount({ id }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        setIsOpenDeleteAccountModal({
          id: -1,
          status: false,
        });
      }
    });
  };

  const changeStripeToken = (value: string) => {
    setState({ ...state, token: value, errStripeToken: '' });
  };

  if (!crossSell.isShowStripeSetting) {
    dispatch(
      bannerSlice.actions.handleBanner({
        key: 'isShowStripeSetting',
        value: true,
      }),
    );
    handleGa4(Ga4Event.stripeSetting);
  }

  const contentStatus = dataSettings?.syncStripe === 1 ? 'Turn off' : 'Turn on';
  return (
    <StripeSettingStyled>
      <InformationBanner
        onDismiss={() => dispatch(slice.settingsPageSlice.actions.handleVisibleBannerStripe(false))}
        title="Your account is protected"
        isVisible={isVisibleBanner}
      >
        We only require <b>minimal access </b> from your account to add tracking info to Stripe. Rest assured that your account
        will <b>always be safe</b>
      </InformationBanner>
      {isLoading ? (
        <Card>
          <SkeletonBodyText />
        </Card>
      ) : (
        <SettingToggle
          disableStatus={(disablePlan([Plan.FREE]) && dataSettings?.syncStripe === 0) || scope.isViewOnly}
          settingToggleProps={{
            action: {
              content: contentStatus,
              onAction: handleToggle,
              disabled: dataSettings?.plan === Plan.FREE && dataSettings?.syncStripe === 0,
              loading: syncStripeStatus.isLoading,
            },
            enabled: dataSettings?.syncStripe === 0 ? false : true,
          }}
          title="Sync tracking information to Stripe:"
        >
          <div>
            <div className="mt-8">
              <RegularText>
                Manage Shopify orders on Stripe easily, protect your Stripe account, and minimize disputes.
              </RegularText>
            </div>
            {dataSettings && dataSettings.plan === Plan.FREE && dataSettings.syncStripe === 0 ? (
              <div className="mt-8">
                <AlertInline
                  content="You need use this feature?"
                  link="Upgrade to plan Basic or higher"
                  navigate={() => {
                    navigate(PATH.ACCOUNT, {
                      state: {
                        prePathName: 'stripe',
                      },
                    });
                  }}
                />
              </div>
            ) : null}
          </div>
        </SettingToggle>
      )}
      {isLoading ? (
        <div className="mt-16">
          <Card>
            <SkeletonBodyText lines={8} />
          </Card>
        </div>
      ) : dataSettings && dataSettings.syncStripe === 1 ? (
        <div className="mt-16">
          <CustomCard title="Stripe Settings">
            <div>
              {fetchListStripeToken.data && fetchListStripeToken.data.listStripeToken.length > 0 ? (
                <div className="w-100 stripe-account-container">
                  {fetchListStripeToken.data &&
                    fetchListStripeToken.data.listStripeToken.map((item) => {
                      return (
                        <Card key={item.token}>
                          <div className="stripe-account-content">
                            <div className="stripe-account-content-left">
                              <div className="d-flex stripe-token-container">
                                <Text as="p" variant="bodySm" truncate>
                                  <b>Stripe token: </b>
                                  {item.token}
                                </Text>
                              </div>
                              <div className="mt-8">
                                <RegularText>
                                  <b>Status :</b>
                                  <Badge progress="complete" tone={item.confirmed === 1 ? 'success' : 'critical'}>
                                    {item.confirmed === 1 ? 'Confirmed' : 'Invalid Stripe Token'}
                                  </Badge>
                                </RegularText>
                              </div>
                            </div>
                            <div className="d-flex btn-group">
                              <Tooltip content="Edit stripe token">
                                <CustomButton onClick={editStripeToken(item.id)} icon={EditMinor} />
                              </Tooltip>
                              <div className="delete-button ml-8">
                                <Tooltip content="Delete stripe token">
                                  <CustomButton
                                    tone="critical"
                                    onClick={() =>
                                      setIsOpenDeleteAccountModal({
                                        id: item.id,
                                        status: true,
                                      })
                                    }
                                    icon={() => <Icon source={DeleteMinor} tone="base" />}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                </div>
              ) : (
                <RegularText>
                  <b>Step 1:</b> Give us Stripe token to start processing Stripe integration. This is{' '}
                  <Link external url={LINK.linkStripe} removeUnderline>
                    the instruction
                  </Link>{' '}
                  on how to get an Stripe token.
                  <br />
                  <b>Step 2:</b> Choose <b>"Add Stripe token"</b> and enter the token into the text field.
                </RegularText>
              )}
              <div className="mt-16">
                <Button
                  variant="primary"
                  onClick={handleModalAddStripeToken}
                  icon={PlusMinor}
                  disabled={
                    (dataSettings &&
                      (dataSettings.plan === Plan.FREE || dataSettings.plan === Plan.BASIC) &&
                      fetchListStripeToken.data &&
                      fetchListStripeToken.data.listStripeToken.length === 1) ||
                    scope.isViewOnly
                  }
                >
                  Add Stripe Token
                </Button>
              </div>
              {dataSettings &&
              (dataSettings.plan === Plan.FREE || dataSettings.plan === Plan.BASIC) &&
              fetchListStripeToken.data &&
              fetchListStripeToken.data.listStripeToken.length === 1 ? (
                <div className="mt-8">
                  <AlertInline
                    content="If you want to connect more Stripe accounts."
                    link="Upgrade to plan Pro or higher"
                    navigate={() => {
                      navigate(PATH.ACCOUNT, {
                        state: {
                          prePathName: 'stripe',
                        },
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </CustomCard>
          <OldOrderBanner />
        </div>
      ) : null}

      <Modal
        open={state.isOpen}
        onClose={clearState}
        title={state.type === 'add' ? 'Add stripe token' : 'Edit stripe token'}
        primaryAction={{
          content: 'Save',
          disabled: state.token === '' ? true : false,
          onAction: handleSaveStripeAccount,
          loading: saveStripeStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: clearState,
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Stripe token:"
            value={state.token}
            onChange={(value) => changeStripeToken(value)}
            onBlur={() => {
              if (!state.token) {
                setState({ ...state, errStripeToken: 'Stripe token is required!' });
              }
            }}
            error={state.errStripeToken}
            type="text"
            autoComplete="off"
          />
          <div className="mt-8"></div>
          <Link url={LINK.linkStripe} external>
            How to get Stripe token?
          </Link>
        </Modal.Section>
      </Modal>

      <Modal
        open={isOpenDeleteAccountModal.status}
        title={'Are you sure?'}
        onClose={() => {
          setIsOpenDeleteAccountModal({ id: -1, status: false });
        }}
        sectioned
        footer={
          <div className="modal-footer">
            <Button
              onClick={() => {
                setIsOpenDeleteAccountModal({ id: -1, status: false });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                onClick={confirmDeleteStripeToken(isOpenDeleteAccountModal.id)}
                variant="primary"
                tone="critical"
                loading={deleteStripeStatus.isLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>Stripe orders paid to this account will not be synced</RegularText>
      </Modal>
    </StripeSettingStyled>
  );
};

export default StripeSetting;
