import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IToast } from '@/types/components';

const toast: IToast = {
  hasAction: undefined,
  error: false,
  content: '',
  isOpen: false,
  contentAction: 'undo',
  onAction: () => {},
};

// Define the initial state using that type
const initialState = {
  toast: toast,
  isVisibleBannerActiveDispute: true,
  isVisibleBannerDispute: true,
  isVisibleBannerOnePaypal: true,
  isVisibleBannerDisputeSync: true,
  isVisibleRecommendApps: true,
  isVisibleBannerNewInterface: true,
  isVisibleBannerProcessOldOrdersUpdatePlan: true,
  isVisibleTetHoliday: true,
  isVisibleBlockify: true,
  isVisibleCompanyTrip: true,
};

export const commonSettingSlice = createSlice({
  name: 'commonSetting',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleToast: (state, action: PayloadAction<IToast>) => {
      state.toast = action.payload;
    },
    handleVisibleBannerActiveDispute: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerActiveDispute = action.payload;
    },
    handleVisibleBannerOnePaypal: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerOnePaypal = action.payload;
    },
    handleVisibleBannerDisputeSync: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerDisputeSync = action.payload;
    },
    handleVisibleRecommendApps: (state, action: PayloadAction<boolean>) => {
      state.isVisibleRecommendApps = action.payload;
    },
    handleVisibleNewInterface: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerNewInterface = action.payload;
    },
    handleVisibleProcessOldOrdersUpdatePlan: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBannerProcessOldOrdersUpdatePlan = action.payload;
    },
    handleVisibleBannerTetHoliday: (state, action: PayloadAction<boolean>) => {
      state.isVisibleTetHoliday = action.payload;
    },
    handleVisibleBannerBlockify: (state, action: PayloadAction<boolean>) => {
      state.isVisibleBlockify = action.payload;
    },
    handleVisibleBannerCompanyTrip: (state, action: PayloadAction<boolean>) => {
      state.isVisibleCompanyTrip = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const isVisibleBannerActiveDisputeSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBannerActiveDispute,
);

export const toastSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.toast,
);

export const isVisibleBannerOnePaypalSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBannerOnePaypal,
);

export const isVisibleBannerDisputeSyncSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBannerDisputeSync,
);
export const isVisibleRecommendAppsSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleRecommendApps,
);

export const isVisibleNewInterfaceSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBannerNewInterface,
);

export const isVisibleProcessOldOrdersUpdatePlanSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBannerProcessOldOrdersUpdatePlan,
);
export const isVisibleTetHolidaySelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleTetHoliday,
);
export const blockifyBannerSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleBlockify,
);
export const companyTripBannerSelector = createSelector(
  (state: RootState) => state.commonSetting,
  (state) => state.isVisibleCompanyTrip,
);
export default commonSettingSlice;
