import { Plan } from '@/constants/enum';
import store from '@/redux/store';
import { IToast } from '@/types/components';
import currencyFormatter from 'currency-formatter';
import dayjs from 'dayjs';

export const checkShowErrorInline = (
  res: any,
): {
  status: boolean;
  msg: string;
} => {
  try {
    if ('data' in res) {
      return {
        status: res.data.state !== 1,
        msg: res.data.msg,
      };
    } else {
      return {
        status: true,
        msg: res.error.data.message.join(',') || 'something happened',
      };
    }
  } catch (err) {
    return {
      status: true,
      msg: 'something happened',
    };
  }
};

export const handleToastMutation = (res: any): IToast => {
  try {
    if ('data' in res) {
      if (res.data.state !== 1) {
        return {
          isOpen: true,
          content: res.data.msg || 'Failed',
          error: true,
        };
      } else {
        return {
          isOpen: true,
          content: res.data.msg || 'Saved',
          error: false,
        };
      }
    } else {
      return {
        isOpen: true,
        content: res.data.msg || 'something happened',
        error: false,
      };
    }
  } catch (err) {
    return {
      isOpen: true,
      content: 'something happened',
      error: true,
    };
  }
};

export const convertUrlOrder = (shop: string, orderId: string) => {
  const shopName = shop.replace('.myshopify.com', '');
  return `https://admin.shopify.com/store/${shopName}/orders/${orderId}`;
};

export const convertAmount = (amount: string, disputeCurrencyCode: string) => {
  const value = currencyFormatter.format(Number(amount), { code: disputeCurrencyCode });
  return value;
};
export const convertCurrency = (disputeCurrencyCode: string) => {
  const value = currencyFormatter.findCurrency(disputeCurrencyCode)?.symbol;
  return value;
};

export const validateEmail = (email: string) => {
  const reg =
    //eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email.toLowerCase());
};

export const validatePhoneNumber = (phoneNumber: string) => {
  //eslint-disable-next-line
  const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return reg.test(phoneNumber.toLowerCase());
};

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};
export const getLastMonth = () => {
  const firtDate = new Date();
  firtDate.setHours(0, 0, 0);

  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - 1);
  const lastDate = new Date();
  firtDate.setHours(23, 59, 59, 99);
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const getThisMonth = () => {
  const now = new Date();
  const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const firtDate = new Date();
  firtDate.setDate(1);
  return {
    start: firtDate,
    end: endDate,
  };
};

export const getLastSomeMonths = (months: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() - months);
  return date;
};

export const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
export const camelToTitle = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (date: string | Date, format?: string) => {
  return dayjs(date).format(format ? format : 'D MMM YYYY, hh:mm a');
};

export const disablePlan = (plans: Plan[]) => {
  const plan = store.getState().masterData.dataSettings?.plan;
  const index = plans.findIndex((item) => item === plan);
  return index !== -1;
};

export const dateToTimeStamp = (date: Date) => {
  return dayjs(date).unix() * 1000;
};
