import config from '@/config';

const convertPathname = (pathname: string) => {
  return {
    pathname: config.pathName + `${pathname ? '/' + pathname : ''}`,
    search: '?' + config.urlParamsValue,
  };
};

export const PATH = {
  DEFAULT: convertPathname('home'),
  ANALYTICS: convertPathname('dashboard'),
  ORDERS: convertPathname('orders'),
  DISPUTE: convertPathname('dispute'),
  SETTINGS: convertPathname('settings'),
  ACCOUNT: convertPathname('account'),
  WELCOME: convertPathname('welcome'),
  HANDLE_REQUEST: convertPathname('handle_request'),
};
