import RenderRouter from '@/routes';
import { NavMenu } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import config from './config';
import { CONSTANS } from './constants/const';
import { Payment } from './constants/enum';
import { PATH } from './constants/path';
import { apiCaller } from './redux/query';
import slice from './redux/slice';
import { orderTableOrderPageSelector } from './redux/slice/orderPage.slice';
import useScript from '@/hooks/Script';
import useScope from './hooks/Scope';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import authSlice, { isSkipApiSelector } from './redux/slice/auth.slice';

const navigationLinks: Array<{
  label: string;
  pathname: string;
  url: {
    pathname: string;
    search: string;
  };
}> = [
  {
    label: 'Analytics',
    pathname: PATH.ANALYTICS.pathname,
    url: PATH.ANALYTICS,
  },
  {
    label: 'Orders',
    pathname: PATH.ORDERS.pathname,
    url: PATH.ORDERS,
  },
  {
    label: 'Dispute',
    pathname: PATH.DISPUTE.pathname,
    url: PATH.DISPUTE,
  },
  {
    label: 'Settings',
    pathname: PATH.SETTINGS.pathname,
    url: PATH.SETTINGS,
  },
  {
    label: 'Subcription Plans',
    pathname: PATH.ACCOUNT.pathname,
    url: PATH.ACCOUNT,
  },
];

function App() {
  useScript();
  const isSkip = useSelector(isSkipApiSelector);
  const location = useLocation();
  const scope = useScope();
  apiCaller.useCheckLoggingQuery({ type: 'scope', loggingData: config.urlParams }, { skip: !scope.isViewOnly || isSkip });
  const checkReturnCodePaypal = location.pathname === 'apps/handle_request';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderTable = useSelector(orderTableOrderPageSelector);
  const fetchAccountPaypal = apiCaller.useFetchDataPaypayAccountQuery(config.shop, {
    skip: checkReturnCodePaypal || isSkip,
  });
  const fetchDataSettings = apiCaller.useFetchDataSettingQuery(config?.shop, {
    skip: checkReturnCodePaypal || isSkip,
  });
  const fetchListStore = apiCaller.useFetchListStoreIntegrateQuery(config?.shop, {
    skip: checkReturnCodePaypal || isSkip,
  });
  const configApp = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    host: new URLSearchParams(location.search).get('host') || '',
    forceRedirect: process.env.REACT_APP_MODE === 'live',
  };

  if (isSkip) {
    const getToken = async () => {
      try {
        dispatch(authSlice.actions.handleLoading(true));
        const app = createApp(configApp);
        const sessionToken = await getSessionToken(app);
        dispatch(
          authSlice.actions.handleToken({
            token: sessionToken,
            expiredTime: Date.now() + 60 * 1000,
            isLoading: false,
          }),
        );
      } catch (err) {
        dispatch(authSlice.actions.handleLoading(false));
      }
    };
    getToken();
  }

  useEffect(() => {
    if (fetchDataSettings.data && fetchDataSettings.data.syncStripe) {
      dispatch(
        slice.orderPageSlice.actions.handleFiltersOrderTable({ ...orderTable, paymentSelected: [Payment.PaypalAndStripe] }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.data?.syncStripe]);

  useEffect(() => {
    if (fetchDataSettings.data) {
      dispatch(slice.masterDataSlice.actions.handleDataSettings(fetchDataSettings.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.data]);

  useEffect(() => {
    if (fetchDataSettings.data) {
      dispatch(slice.WelcomeSlice.actions.handleStep(fetchDataSettings.data.welcomeStep));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!fetchDataSettings.data]);

  useEffect(() => {
    dispatch(slice.masterDataSlice.actions.handleLoading(fetchDataSettings.isFetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.isFetching]);

  useEffect(() => {
    if (fetchListStore.data) {
      dispatch(slice.masterDataSlice.actions.handleListStore(fetchListStore.data?.listStore));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListStore.data]);

  useEffect(() => {
    if (fetchAccountPaypal.data) {
      dispatch(slice.masterDataSlice.actions.handleListPaypal(fetchAccountPaypal.data?.dataPaypalAccount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAccountPaypal.data]);

  return (
    <AppProvider i18n={enTranslations}>
      {location.pathname !== PATH.HANDLE_REQUEST.pathname && process.env.REACT_APP_MODE !== 'local' && CONSTANS.isEmbedded ? (
        <>
          <NavMenu>
            <a
              href={PATH.DEFAULT.pathname}
              rel="home"
              onClick={(e) => {
                e.preventDefault();
                navigate(PATH.DEFAULT);
              }}
            >
              Home
            </a>
            {navigationLinks.map((item) => {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  id={item.pathname}
                  key={item.pathname}
                  href={item.pathname}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(item.url);
                  }}
                >
                  {item.label}
                </a>
              );
            })}
          </NavMenu>
          <RenderRouter />
        </>
      ) : (
        <RenderRouter />
      )}
    </AppProvider>
  );
}

export default App;
