import commonSettingSlice from './commonSetting.slice';
import orderPageSlice from './orderPage.slice';
import settingsPageSlice from './settings.slice';
import disputePageSlice from './dispute.slice';
import analyticsSlice from './analytics.slice';
import masterDataSlice from './masterData.slice';
import WelcomeSlice from './welcome.slice';

export default {
  commonSettingSlice,
  orderPageSlice,
  settingsPageSlice,
  disputePageSlice,
  masterDataSlice,
  analyticsSlice,
  WelcomeSlice,
};
