import styled from 'styled-components';

export const StripeSettingStyled = styled.div`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .Polaris-LegacyCard__Section {
    padding: 20px 8px;
  }
  .row {
    margin: 0;
  }
  .Polaris-Badge {
    margin-left: 4px;
  }
  .stripe-account-layout {
    padding: 10px 15px;
    border: 2px solid #eee;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
  }
  .stripe-token {
    min-width: fit-content;
  }
  .stripe-token-value {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 20px);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .Polaris-Banner {
    font-size: 14px;
  }
  .stripe-account-container {
    .Polaris-ShadowBevel {
      margin-bottom: 1rem;
      .btn-group {
        display: none;
      }
      &:hover {
        .btn-group {
          display: flex;
        }
      }
    }
  }

  .stripe-account-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .stripe-account-content-left {
      max-width: calc(100% - 82px);
      text-overflow: ellipsis;
      display: webkit-box;
      .stripe-token-container {
        max-width: calc(100% - 82px);
      }
    }
    p {
      margin-bottom: 0;
    }
    .lh-34 {
      line-height: 34px;
    }
  }
  .pd-0 {
    padding: 0;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
`;
