import { IndexFilters, IndexFiltersMode, Card, Button, Icon, Popover, ActionList } from '@shopify/polaris';
import { OrdersStyled } from './styled';
import { useState, useCallback, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOrderTable } from '@/types/components';
import { orderTableOrderPageSelector, searchInputSelector, titleBtnDatePickerSelector } from '@/redux/slice/orderPage.slice';
import slice from '@/redux/slice';
import _debounce from 'lodash/debounce';
import CustomDatePicker from '@/components/datePicker';
import Filters from './components/Filters';
import Table from './components/Table';
import Layout from '@/components/layout';
import ExportOrders from './components/ExportOrders';
import { ExportMinor, OrdersMajor, ImportMinor } from '@shopify/polaris-icons';
import ProcessOldOrders from './components/OldOrders';
import UploadCSV from './csv';
import { sortOptions } from './sortConfig';
import { useMediaQuery } from 'react-responsive';
import CustomButton from '@/components/CustomButton';
import useScope from '@/hooks/Scope';
import { OrderStatus } from '@/constants/enum';
const Orders = () => {
  const scope = useScope();
  const isMobile = useMediaQuery({ maxWidth: 530 });
  const [isOpenSecondary, setIsOpenSecondary] = useState(false);
  const orderTable = useSelector(orderTableOrderPageSelector);
  const searchInput = useSelector(searchInputSelector);
  const [isOpenModalExport, setIsOpenModalExport] = useState(false);
  const [isOpenModalProcessOldOrders, setIsOpenModalProcessOldOrders] = useState(false);
  const [isOpenModalUploadCsv, setIsOpenModalUploadCsv] = useState(false);
  const [mode] = useState(IndexFiltersMode.Filtering);
  const dispatch = useDispatch();
  const titleBtn = useSelector(titleBtnDatePickerSelector);

  const onSaveDatePicker = useCallback(
    (fromDate: Date, toDate: Date) => {
      dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable({ ...orderTable, fromDate, toDate, currentPage: 1 }));
    },
    [dispatch, orderTable],
  );
  const onSaveTitleBtnDatePicker = useCallback(
    (title: string) => {
      dispatch(slice.orderPageSlice.actions.handleTitleBtnDatePicker(title));
    },
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearch = useCallback(
    _debounce((value: IOrderTable.IState) => {
      return dispatch(slice.orderPageSlice.actions.handleFiltersOrderTable(value));
    }, 1000),
    [],
  );

  const handleChangeInput = (value: string) => {
    dispatch(slice.orderPageSlice.actions.handleSearchInput(value));
    debounceSetSearch({
      ...orderTable,
      search: value,
      currentPage: 1,
    });
  };

  const activator = useMemo(() => {
    return (
      <Button
        disabled={scope.isViewOnly}
        onClick={() => {
          setIsOpenSecondary(!isOpenSecondary);
        }}
        disclosure
      >
        More actions
      </Button>
    );
  }, [isOpenSecondary, scope.isViewOnly]);

  const SecondaryActions = useMemo(() => {
    return (
      <div className="orders-secondary">
        <div className="orders-date-picker">
          <CustomDatePicker
            titleBtn={titleBtn}
            setTitleBtn={onSaveTitleBtnDatePicker}
            onSave={onSaveDatePicker}
            start={orderTable.fromDate}
            end={orderTable.toDate}
            variant={isMobile ? undefined : 'tertiary'}
          />
        </div>
        {isMobile ? (
          <div className="ml-8">
            <Popover
              active={isOpenSecondary}
              activator={activator}
              autofocusTarget="first-node"
              onClose={() => {
                setIsOpenSecondary(false);
              }}
            >
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    content: 'Export',
                    onAction: () => {
                      setIsOpenModalExport(true);
                    },
                  },
                  {
                    content: 'Import',
                    onAction: () => {
                      setIsOpenModalUploadCsv(true);
                    },
                  },
                  {
                    content: 'Process old orders',
                    onAction: () => {
                      setIsOpenModalProcessOldOrders(true);
                    },
                  },
                ]}
              />
            </Popover>
          </div>
        ) : (
          <div className="d-flex">
            <div className="ml-8">
              <CustomButton
                variant="tertiary"
                onClick={() => {
                  setIsOpenModalExport(true);
                }}
                icon={<Icon source={ExportMinor} tone="base" />}
              >
                Export
              </CustomButton>
            </div>
            <div className="ml-8">
              <CustomButton
                icon={ImportMinor}
                variant="tertiary"
                onClick={() => {
                  setIsOpenModalUploadCsv(true);
                }}
              >
                Import
              </CustomButton>
            </div>
            <div className="ml-8">
              <CustomButton
                onClick={() => {
                  setIsOpenModalProcessOldOrders(true);
                }}
                variant="tertiary"
                icon={<Icon source={OrdersMajor} tone="base" />}
              >
                Process old orders
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    activator,
    isMobile,
    isOpenSecondary,
    onSaveDatePicker,
    onSaveTitleBtnDatePicker,
    orderTable.fromDate,
    orderTable.toDate,
    titleBtn,
  ]);

  const handleOpenModalExport = (status: boolean) => {
    setIsOpenModalExport(status);
  };
  const handleOpenModalProcessOldOrders = (status: boolean) => {
    setIsOpenModalProcessOldOrders(status);
  };
  const handleSort = (value: string[]) => {
    const data = value[0].split(' ');
    dispatch(
      slice.orderPageSlice.actions.handleFiltersOrderTable({
        ...orderTable,
        sortBy: data[0],
        sort: data[1],
      }),
    );
  };
  return (
    <OrdersStyled>
      <UploadCSV
        handleClose={() => {
          setIsOpenModalUploadCsv(false);
        }}
        isOpen={isOpenModalUploadCsv}
      />
      <ProcessOldOrders isOpen={isOpenModalProcessOldOrders} handleIsOpen={handleOpenModalProcessOldOrders} />
      <ExportOrders
        isOpen={isOpenModalExport}
        handleOpen={handleOpenModalExport}
        fromDate={orderTable.fromDate}
        payment={orderTable.paymentSelected}
        search={orderTable.search}
        sort={orderTable.sort}
        sortBy={orderTable.sortBy}
        status={orderTable.statusSelected}
        store={orderTable.storeSelected}
        toDate={orderTable.toDate}
      />
      <Layout title="Orders" fullWidth secondaryActions={SecondaryActions}>
        <Card>
          <IndexFilters
            sortOptions={sortOptions}
            sortSelected={[`${orderTable.sortBy} ${orderTable.sort}`]}
            onSort={handleSort}
            queryValue={searchInput}
            queryPlaceholder="Search orders by name, tracking number, transaction id"
            onQueryChange={handleChangeInput}
            onQueryClear={() => {
              handleChangeInput('');
            }}
            tabs={[]}
            selected={0}
            onSelect={() => {}}
            canCreateNewView={false}
            filters={Filters().filters}
            appliedFilters={Filters().appliedFilters.filter(
              (item) =>
                orderTable[item.key as keyof typeof orderTable].toString() !== 'all' &&
                orderTable[item.key as keyof typeof orderTable].toString() !== '10',
            )}
            onClearAll={() => {
              dispatch(
                slice.orderPageSlice.actions.handleFiltersOrderTable({
                  ...orderTable,
                  statusSelected: [OrderStatus.All],
                  perPage: ['10'],
                  currentPage: 1,
                  storeSelected: ['all'],
                  paymentSelected: ['all'],
                }),
              );
            }}
            mode={mode}
            setMode={() => {}}
            cancelAction={{ onAction: () => {} }}
            disableStickyMode
          />
          <Table />
        </Card>
      </Layout>
    </OrdersStyled>
  );
};

export default memo(Orders);
