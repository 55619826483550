/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';

import { Spinner } from '@shopify/polaris';
import config from '@/config';

const HandleRequest = (): JSX.Element => {
  useEffect(() => {
    if (window?.opener?.postMessage) {
      try {
        window.opener.postMessage({
          type: 'paypalConnected',
          success: true,
          code: config.code,
        });
        window.close();
      } catch (err) {
        console.log(err);
      }
    } else {
      if (config.code) window.localStorage.setItem('paypalConnected', config.code);
    }
  }, []);

  return (
    <div id="i_loading_page_2">
      <Spinner size="large" />
    </div>
  );
};

export default HandleRequest;
