import styled from 'styled-components';

export const SettingsPageStyled = styled.div`
  .settings-title-container {
    .Polaris-OptionList-Option__Icon {
      display: none;
    }
    .Polaris-BlockStack {
      background-color: white;
      padding: 1rem;
      border-radius: 0.5rem;
    }
    .settings-title {
      display: flex;
      .Polaris-Icon {
        margin-right: 0.5rem;
      }
    }
  }

  .mt-20 {
    margin-top: 20px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .mt-6 {
    margin-top: 6px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .break-line {
    white-space: pre-line;
    line-break: auto;
    overflow-wrap: break-word;
  }
  .Polaris-Link {
    color: rgba(0, 91, 211, 1);
  }
`;
