import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { IParamsApi } from '@/types/api/params.api';
import { SkeletonBodyText } from '@shopify/polaris';
import { LineChart, PolarisVizProvider } from '@shopify/polaris-viz';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

const Chart = ({ ...props }: IParamsApi.IGetDataChartParams) => {
  const isSkip = useSelector(isSkipApiSelector);

  const { data, isFetching } = apiCaller.useFetchDataChartQuery(
    {
      ...props,
      store: props.store !== 'all' ? props.store : undefined,
    },
    { skip: isSkip },
  );

  const dataChart = useMemo(() => {
    if (data) {
      const colors = ['#008170', '#75C2F6', '#FFB07F', '#F6635C'];
      const result = data?.data.series.map((value, index) => {
        return {
          name: value.name,
          color: colors[index],
          data: value.data.map((item, i) => {
            return {
              value: item,
              key: data.data.categories[i],
            };
          }),
        };
      });
      return result;
    }
    return [];
  }, [data]);

  return (
    <div id="chart" className="mixed-chart w-100">
      {isFetching ? (
        <div className="mt-16">
          <SkeletonBodyText lines={16} />
        </div>
      ) : (
        <PolarisVizProvider>
          <LineChart data={dataChart} theme="Light" />
        </PolarisVizProvider>
      )}
    </div>
  );
};

export default memo(Chart);
