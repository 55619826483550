/* eslint-disable no-lone-blocks */
import BoldText from '@/components/BoldText';
import CriticalBanner from '@/components/CriticalBanner';
import RegularText from '@/components/RegularText';
import config from '@/config';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { ICsv } from '@/types/components';
import { Button, Card, DropZone, Icon, List, Modal, Select } from '@shopify/polaris';
import { AttachmentFilledMajor } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
const options = [
  { value: 'orders', label: 'Orders' },
  { value: 'tracking', label: 'Tracking number' },
];

interface State {
  type: string;
  file: File | null;
}

const UploadCSV = ({ ...props }: ICsv.IProps) => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState({ value: '', isImported: false });
  const [importOrders, importOrdersStatus] = apiCaller.useImportOrdersMutation();
  const [importTrackingNumber, importTrackingNumberStatus] = apiCaller.useImportTrackingNumberMutation();
  const [state, setState] = useState<State>({
    type: 'orders',
    file: null,
  });
  const handleType = (type: string) => {
    setState({
      file: null,
      type,
    });
    setMsg({ value: '', isImported: false });
  };

  const clearState = () => {
    setMsg({ value: '', isImported: false });
    setState({
      type: 'orders',
      file: null,
    });
    props.handleClose();
  };
  const handleImportCsv = () => {
    const data = new FormData();
    data.append('shop', config.shop);
    data.append('urlParams', config.urlParams);
    if (state.type === 'orders' && state.file) {
      data.append('file', state.file);
      importOrders(data).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setMsg({
            value: condition.msg,
            isImported: true,
          });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
        }
      });
    }
    if (state.type === 'tracking' && state.file) {
      data.append('file', state.file);
      importTrackingNumber(data).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setMsg({
            value: condition.msg,
            isImported: true,
          });
        } else {
          dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
          clearState();
        }
      });
    }
  };

  const templateLink = useMemo(() => {
    const endPoint = process.env.REACT_APP_API_END_POINT;
    if (state.type === 'orders') return `${endPoint}order/download/orders-template`;
    return `${endPoint}order/download/trackings-template`;
  }, [state.type]);

  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      setState({ ...state, file: acceptedFiles[0] });
      setMsg({ value: '', isImported: false });
    },
    [state],
  );
  const fileUpload = !state.file && <DropZone.FileUpload actionHint="Accepts only .csv, .xlsx and max file size 2MB" />;
  return (
    <Modal
      onClose={clearState}
      open={props.isOpen}
      title={`Import ${state.type}`}
      sectioned
      footer={
        <div className="modal-footer" style={{ justifyContent: 'space-between' }}>
          <Button url={templateLink} target="_blank" variant="plain">
            Download sample
          </Button>

          <div className="d-flex">
            <Button onClick={clearState}>Cancel</Button>
            <div className="ml-8">
              <Button
                disabled={!state.file}
                onClick={handleImportCsv}
                variant="primary"
                loading={importOrdersStatus.isLoading || importTrackingNumberStatus.isLoading}
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <CriticalBanner isVisible={msg.isImported}>
        <RegularText>
          There was an error importing your file. After you fix the error, try importing the file again.
          <br />
          <List type="bullet">
            <List.Item>
              <RegularText>{msg.value}</RegularText>
            </List.Item>
          </List>
        </RegularText>
      </CriticalBanner>

      <div>
        <Select label="Type upload" options={options} onChange={handleType} value={state.type} />
        <div className="mt-16">
          {state.file ? (
            <Card>
              <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                <div className="d-flex">
                  <Icon source={AttachmentFilledMajor} tone="base" />
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
                    <BoldText>{state.file?.name}</BoldText>
                  </div>
                </div>
                <DropZone onDrop={handleDropZoneDrop} outline={false}>
                  <Button>Replace file</Button>
                </DropZone>
              </div>
            </Card>
          ) : (
            <DropZone
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
              id="upload-csv"
              onDrop={handleDropZoneDrop}
            >
              {fileUpload}
            </DropZone>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(UploadCSV);
