import RegularText from '@/components/RegularText';
import CustomCard from '@/components/card';
import PaypalButton from '@/components/paypalButton';
import SettingToggle from '@/components/settingToggle';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isVisibleBannerOnePaypalSelector } from '@/redux/slice/commonSetting.slice';
import { dataSettingsSelector, listPaypalSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { isVisibleBannerPaypalSelector } from '@/redux/slice/settings.slice';
import { ISettingsPage } from '@/types/components';
import { Badge, Banner, Button, Card, Icon, Link, Modal, SkeletonBodyText, TextField, Tooltip } from '@shopify/polaris';
import { DeleteMinor, EditMinor, FavoriteMajor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Guide from './components/Guide';
import { PaypalSettingStyled } from './styled';
import config from '@/config';
import CriticalBanner from '@/components/CriticalBanner';
import CustomButton from '@/components/CustomButton';
import useScope from '@/hooks/Scope';
import InformationBanner from '@/components/InformationBanner';
import { useGa4 } from '@/hooks/useGa4';
import bannerSlice, { CrossSellSelector } from '@/redux/slice/banner.slice';
import { Ga4Event } from '@/constants/enum';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const PaypalSetting = () => {
  const scope = useScope();
  const dispatch = useDispatch();
  const isVisibleBannerOnePaypal = useSelector(isVisibleBannerOnePaypalSelector);
  const listPaypal = useSelector(listPaypalSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const isLoading = useSelector(loadingSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const isLoadingPayPalAccount = apiCaller.useFetchDataPaypayAccountQuery(config.shop, { skip: isSkip }).isLoading;
  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '', type: '' });
  const crossSell = useSelector(CrossSellSelector);
  const { handleGa4 } = useGa4();
  const [idSelected, setIdSelected] = useState(-1);
  const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState({ status: false, id: -1 });
  const isVisibleBanner = useSelector(isVisibleBannerPaypalSelector);
  const [mutationNotifyBuyerUpdate, updateNotifyBuyerStatus] = apiCaller.useUpdateNotifyBuyerMutation();
  const [mutationDeletePaypalAccount, deletePaypalAccountStatus] = apiCaller.useDetelePaypalAccountMutation();
  const [mutationSetPrimaryAccountPaypal, setPrimaryAccountPaypalStatus] = apiCaller.useSetPrimaryAccountPaypalMutation();
  const [mutationUpdateAccountPaypal, updateAccoutPaypalStatus] = apiCaller.useUpdatePaypalAccountMutation();
  const [state, setState] = useState<ISettingsPage.IStatePaypalSetting>({
    modalStatus: false,
    modalTitle: 'New Paypal Account',
    modalType: 'add',
    clientId: '',
    secrect: '',
    email: '',
    errClientId: '',
    errSecret: '',
    errEmail: '',
    idSelected: 0,
    isOpenModalUserTour: false,
  });

  const setPrimaryPaypalAccount = (id: number) => () => {
    setIdSelected(id);
    mutationSetPrimaryAccountPaypal({ id }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setIsShowErrorInline({
          ...condition,
          type: 'connect',
        });
      } else {
        if (isShowErrorInline.type === 'connect') {
          setIsShowErrorInline({
            status: false,
            msg: '',
            type: '',
          });
        }
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };
  const openModalEditPaypalAccount = (id: number) => () => {
    const paypalAccount = listPaypal?.find((item) => item.id === id);
    if (paypalAccount) {
      setState({
        ...state,
        idSelected: id,
        email: paypalAccount.email,
        clientId: paypalAccount.clientId,
        secrect: paypalAccount.secret,
        errEmail: '',
        errClientId: '',
        errSecret: '',
        modalStatus: true,
        modalType: 'edit',
      });
    }
  };

  const confirmDeletePaypalAccount = (id: number) => () => {
    mutationDeletePaypalAccount({ id }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setIsShowErrorInline({
          ...condition,
          type: 'connect',
        });
      } else {
        if (isShowErrorInline.type === 'connect') {
          setIsShowErrorInline({
            status: false,
            msg: '',
            type: '',
          });
        }
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
      setIsOpenDeleteAccountModal({
        status: false,
        id: -1,
      });
    });
  };
  const notifyBuyerActionStatus = dataSettings?.notifyBuyer === 1 ? 'Turn off' : 'Turn on';

  const savePaypalAccount = () => {
    mutationUpdateAccountPaypal({
      id: state.idSelected,
      clientId: state.clientId,
      secrect: state.secrect,
      email: state.email,
    }).then((res) => {
      setState({ ...state, modalStatus: false });
      dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
    });
  };

  const handleNotifyBuyer = () => {
    mutationNotifyBuyerUpdate({ notifyBuyer: dataSettings?.notifyBuyer === 0 ? 1 : 0 }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (condition.status) {
        setIsShowErrorInline({
          ...condition,
          type: 'notify',
        });
      } else {
        if (isShowErrorInline.type === 'notify') {
          setIsShowErrorInline({
            status: false,
            msg: '',
            type: '',
          });
        }
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  if (!crossSell.isShowPaypalSetting) {
    dispatch(
      bannerSlice.actions.handleBanner({
        key: 'isShowPaypalSetting',
        value: true,
      }),
    );
    handleGa4(Ga4Event.paypalSetting);
  }

  return (
    <PaypalSettingStyled>
      <div className="paypal-settings-left">
        <InformationBanner
          isVisible={isVisibleBanner}
          onDismiss={() => {
            dispatch(slice.settingsPageSlice.actions.handleVisibleBannerPaypal(false));
          }}
          title="Your account is protected"
        >
          We only require <b>minimal access </b> from your account to add tracking info to Paypal. Rest assured that your account
          will <b>always be safe</b>
        </InformationBanner>
        <CustomCard title="Connecting app with Paypal">
          {listPaypal && listPaypal.length === 0 ? (
            <Banner title="Need to connect your PayPal account." tone="warning">
              <RegularText>
                In order to make the app work properly, please click "Connect via PayPal" below. <br />
                Disable pop-up blocking on your browser so that the connecting pop-up can appear or try with incognito browser
                <br />
                <Link external url="https://docs.synctrack.io/#1.-paypal-account-setup">
                  How to get it?
                </Link>
              </RegularText>
            </Banner>
          ) : (
            <div className="pl-2">
              <CriticalBanner isVisible={isShowErrorInline.status && isShowErrorInline.type === 'connect'}>
                {isShowErrorInline.msg}
              </CriticalBanner>
              <div className={isShowErrorInline.status && isShowErrorInline.type === 'connect' ? 'mt-16' : ''}>
                <RegularText>
                  Paypal account has been connected, Synctrack will automatically synchronize order tracking information to your
                  Paypal account automatically.
                </RegularText>
              </div>

              {isLoadingPayPalAccount ? (
                <div className="mt-16">
                  <SkeletonBodyText />
                </div>
              ) : (
                listPaypal?.map((item, index) => {
                  return (
                    <div key={index + 1} className="mt-16 paypal-account-container">
                      <Card>
                        {item.primaryAccount === 1 ? (
                          <div className="badge-container">
                            <div className="strong">
                              <Badge tone="success">Primary Account</Badge>{' '}
                            </div>
                            <div className="btn-group">
                              {item.primaryAccount !== 1 && (
                                <div className="other-button">
                                  <Tooltip content="Mark as primary account">
                                    <CustomButton
                                      loading={setPrimaryAccountPaypalStatus.isLoading && idSelected === item.id}
                                      onClick={setPrimaryPaypalAccount(item.id)}
                                      icon={() => <Icon source={FavoriteMajor} tone="base" />}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                              {item.payerId === null ? (
                                <div className="other-button ml-8">
                                  <Tooltip content="Edit account">
                                    <CustomButton
                                      onClick={openModalEditPaypalAccount(item.id)}
                                      icon={() => <Icon source={EditMinor} tone="base" />}
                                    />
                                  </Tooltip>
                                </div>
                              ) : null}
                              <div className="delete-button ml-8">
                                <Tooltip content="Delete account">
                                  <CustomButton
                                    tone="critical"
                                    onClick={() => {
                                      setIsOpenDeleteAccountModal({ id: item.id, status: true });
                                    }}
                                    icon={() => <Icon source={DeleteMinor} />}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="badge-container">
                            <div className="strong">
                              <Badge>Second account</Badge>
                            </div>
                            <div className="btn-group">
                              {item.primaryAccount !== 1 && (
                                <div className="other-button">
                                  <Tooltip content="Mark as primary Account">
                                    <CustomButton
                                      tone="success"
                                      loading={setPrimaryAccountPaypalStatus.isLoading && idSelected === item.id}
                                      onClick={setPrimaryPaypalAccount(item.id)}
                                      icon={() => <Icon source={FavoriteMajor} />}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                              {item.payerId === null ? (
                                <div className="other-button ml-8">
                                  <Tooltip content="Edit Account">
                                    <CustomButton
                                      onClick={openModalEditPaypalAccount(item.id)}
                                      icon={() => <Icon source={EditMinor} tone="base" />}
                                    />
                                  </Tooltip>
                                </div>
                              ) : null}
                              <div className="delete-button ml-8">
                                <Tooltip content="Delete Account">
                                  <CustomButton
                                    tone="critical"
                                    loading={deletePaypalAccountStatus.isLoading}
                                    onClick={() => {
                                      setIsOpenDeleteAccountModal({ id: item.id, status: true });
                                    }}
                                    icon={() => <Icon source={DeleteMinor} />}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.email !== null && item.email !== '' ? (
                          <div>
                            <RegularText>
                              <b>Account: </b> {scope.isViewOnly ? '**********' : item.email}
                            </RegularText>
                          </div>
                        ) : null}
                        <div className="mt-8">
                          <RegularText>
                            <b>{item.payerId === null ? 'Client Id: ' : 'PayPal merchant ID: '}</b>{' '}
                            {item.payerId === null ? item.clientId : item.payerId}
                          </RegularText>
                        </div>
                      </Card>
                    </div>
                  );
                })
              )}
            </div>
          )}
          <div className="mt-16">
            <PaypalButton />
            <div className="mt-16">
              <InformationBanner
                title="Are you using multiple PayPal accounts to minimize risk?"
                isVisible={listPaypal?.length === 1 && isVisibleBannerOnePaypal}
                onDismiss={() => {
                  dispatch(slice.commonSettingSlice.actions.handleVisibleBannerOnePaypal(false));
                }}
              >
                Please connect to all respective PayPal accounts in the app so that tracking info is uploaded to the correct
                PayPal account
              </InformationBanner>
            </div>
          </div>

          <Modal
            open={state.modalStatus}
            onClose={() => {
              setState({ ...state, modalStatus: false });
            }}
            title={state.modalType === 'add' ? 'Add new account' : 'Edit Account'}
            primaryAction={{
              content: 'Save',
              onAction: savePaypalAccount,
              loading: updateAccoutPaypalStatus.isLoading,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setState({ ...state, modalStatus: false }),
              },
            ]}
          >
            <Modal.Section>
              <TextField
                autoComplete="off"
                label="Account Email*:"
                value={state.email}
                onChange={(value) => {
                  setState({ ...state, email: value });
                }}
                error={state.errEmail}
                type="email"
              />
              <TextField
                autoComplete="off"
                label="Client ID*:"
                value={state.clientId}
                onChange={(value) => {
                  setState({ ...state, clientId: value });
                }}
                error={state.errClientId}
              />
              <TextField
                autoComplete="off"
                label="Secret* :"
                type="password"
                value={state.secrect}
                onChange={(value) => {
                  setState({ ...state, secrect: value });
                }}
                error={state.errSecret}
              />
              <Guide />
            </Modal.Section>
          </Modal>
        </CustomCard>
        <div className="mt-16" />

        <SettingToggle
          disableStatus={scope.isViewOnly}
          settingToggleProps={{
            action: {
              content: notifyBuyerActionStatus,
              onAction: handleNotifyBuyer,
              loading: updateNotifyBuyerStatus.isLoading || isLoading,
            },
            enabled: dataSettings?.notifyBuyer === 0 ? false : true,
          }}
          title="Notify buyer:"
        >
          <div>
            <CriticalBanner isVisible={isShowErrorInline.status && isShowErrorInline.type === 'notify'}>
              {isShowErrorInline.msg}
            </CriticalBanner>
            <div className={isShowErrorInline.status && isShowErrorInline.type === 'notify' ? 'mt-16' : ''}>
              <RegularText>
                Sends an email notification to the buyer via PayPal support email. The email contains the tracking information
                uploaded
              </RegularText>
            </div>
          </div>
        </SettingToggle>
      </div>

      <Modal
        open={isOpenDeleteAccountModal.status}
        title={'Are you sure?'}
        onClose={() => {
          setIsOpenDeleteAccountModal({ id: -1, status: false });
        }}
        sectioned
        footer={
          <div className="modal-footer">
            <Button
              onClick={() => {
                setIsOpenDeleteAccountModal({ id: -1, status: false });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                onClick={confirmDeletePaypalAccount(isOpenDeleteAccountModal.id)}
                variant="primary"
                tone="critical"
                loading={deletePaypalAccountStatus.isLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>This Paypal account will be permanently removed from our system until you add it again</RegularText>
      </Modal>
    </PaypalSettingStyled>
  );
};

export default memo(PaypalSetting);
