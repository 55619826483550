import styled from 'styled-components';

export const OrdersStyled = styled.div`
  .desktop-nav {
    .Polaris-Button--primary {
      .Polaris-Button__Text {
        color: white;
      }
    }
  }

  .orders-secondary {
    display: flex;
    align-items: center;
  }
  .orders-date-picker {
    display: flex;
    justify-content: flex-end;
  }
  .pagination-orders {
    margin-top: 1rem;
    justify-content: center;
    display: flex;
  }
  .orders-row {
    padding: 0.5rem 0.25rem;
    min-width: 1150px;
  }
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .order-table-title {
    font-weight: bold;
    padding: 12px 20px 7px;
    border-bottom: 1px solid #dfe3e8;
    font-size: 10px;
  }

  .Polaris-IndexFilters__ButtonWrap {
    .Polaris-Button--sizeMicro {
      display: none;
    }
  }

  .vertical-center {
    line-height: 24px;
    align-items: center;
    padding: 0 0.5rem;
    .Polaris-Text--root {
      padding-right: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text-truncate {
      font-size: 14px;
      margin: 0;
      a {
        text-decoration: none;
      }
    }
    .Polaris-Icon {
      margin: 0;
    }
    .break-line {
      white-space: pre-line;
      line-break: auto;
    }
  }
  .font-weight-bold {
    font-weight: bold;
  }
  .p-16 {
    padding: 16px;
  }
  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Polaris-Icon {
      width: 150px;
      height: 150px;
    }
  }
  a {
    text-decoration: none;
  }
`;
