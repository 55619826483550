import { COLORS } from '@/constants/colors';
import styled from 'styled-components';

export const AnalyticsStyled = styled.div`
  .custom-date-picker {
    min-height: 2rem;
    .Polaris-Button {
      min-height: 2rem;
    }
  }
  .control-group {
    display: flex;
    justify-content: end;
  }
  .ml-4 {
    margin-left: 0.25rem;
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.5rem;
    }
    &:hover {
      cursor: pointer;
      color: ${COLORS.primary};
    }
  }
  .analytics-title {
    display: flex;
    justify-content: space-between;
  }
  .analytics-content {
    background-color: ${COLORS.backgroundColor};
    padding: 1rem;
  }
  .analytics-support-right {
    padding: 2rem 1.5rem;
    &-title {
      display: flex;
      .Polaris-Icon {
        margin: 0;
        margin-right: 0.5rem;
      }
    }
  }
  .notification-container {
    position: relative;
    .notification-content {
      position: absolute;
      right: 0px;
      top: 28px;
      z-index: 1;
      width: 350px;
    }
  }
  #chart {
    margin-top: 1rem;
    height: 325px;
  }
  .mt-8 {
    margin-top: 0.5rem;
  }
  .ml-4 {
    margin-left: 0.25rem;
  }
  .blockify-banner {
    .banner-template-right {
      justify-content: space-between;
    }
  }
`;
