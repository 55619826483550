import styled from 'styled-components';

export const FacebookAndInstagramStyled = styled.div`
  .fb-ig-account-container {
    .Polaris-ShadowBevel {
      margin-bottom: 1rem;
      .btn-group {
        opacity: 0;
      }
      &:hover {
        .btn-group {
          opacity: 1;
        }
      }
    }
  }
  .Polaris-List__Item {
    font-size: 12px;
  }

  .fb-ig-account-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fb-ig-account-content-left {
      max-width: calc(100% - 82px);
      text-overflow: ellipsis;
      display: webkit-box;
      .fb-ig-token-container {
        max-width: calc(100% - 82px);
      }
    }
    p {
      margin-bottom: 0;
    }
    .lh-34 {
      line-height: 34px;
    }
  }
`;
