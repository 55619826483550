import { Plan } from '@/constants/enum';
const pricingTableData = [
  {
    name: Plan.BASIC,
    prePlan: 'Free',
    display_name: 'BASIC',
    description: 'Best for small business',
    price: '$6.99',
    priceYearly: '$5.59',
    quota: 500,
    content: ['Connect 1 Stripe account', 'Sync digital product', '2 custom courier matching rules'],
  },
  {
    name: Plan.PRO,
    prePlan: 'Basic',
    display_name: 'PRO',
    description: 'Best for medium business',
    price: '$15.99',
    priceYearly: '$11.19',
    quota: 6000,
    content: ['Unlimited Stripe account', '10 custom courier matching rules', 'Premium processing data'],
  },

  {
    name: Plan.PREMIUM,
    prePlan: 'Pro',
    display_name: 'PREMIUM',
    description: 'Best for large business',
    price: '$29.99',
    priceYearly: '$20.99',
    quota: 25000,
    content: ['Unlimited custom courier matching rules', 'Premium priority sync', '<p>Multi-store : <b>2 stores</b></p>'],
  },
  {
    name: Plan.UNLIMITED,
    prePlan: 'Premium',
    display_name: 'UNLIMITED',
    description: 'Best for Shopify Plus',
    price: '$39.99',
    priceYearly: '$27.99',
    quota: 'Unlimited',
    content: ['PCI & SOC2 compliance', 'Dedicated sync cluster', '<p>Multi-store : <b>2+ stores</b></p>'],
  },
];
export default pricingTableData;
