import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { AlertInline } from '@/components/alertInline';
import CustomCard from '@/components/card';
import config from '@/config';
import { CourierIgnoreRule, Plan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/masterData.slice';
import { ISettingsPage } from '@/types/components';
import {
  Autocomplete,
  Button,
  EmptyState,
  Icon,
  InlineGrid,
  Modal,
  Select,
  SkeletonBodyText,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IgnoreSyncStyled } from './styled';
import CustomButton from '@/components/CustomButton';
import useScope from '@/hooks/Scope';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const optionsRule = [
  { label: 'Courier', value: CourierIgnoreRule.COURIER, helpText: 'Example: Yunexpress' },
  { label: 'Courier start', value: CourierIgnoreRule.COURIER_START, helpText: 'Yun' },
  { label: 'Tracking number', value: CourierIgnoreRule.TRACKING, helpText: '1ZA' },
  { label: 'Tracking number start', value: CourierIgnoreRule.TRACKING_START, helpText: 'HK' },
];

const SyncFilter = () => {
  const scope = useScope();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState({ status: false, id: -1 });
  const dataSettings = useSelector(dataSettingsSelector);
  const isLoading = useSelector(loadingSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const [removeCourierIgnore, removeCourierIgnoreStatus] = apiCaller.useRemoveCourierIgnoreMutation();
  const fetchListCourierIgnore = apiCaller.useFetchListCourierIgnoreQuery(config.shop, { skip: isSkip });
  const fetchListPayPalCourier = apiCaller.useFetchListPayPalCourierQuery(config.shop, { skip: isSkip });
  const [saveCourierIgnore, saveCourierIgnoreStatus] = apiCaller.useSaveCourierIgnoreMutation();
  const [state, setState] = useState<ISettingsPage.IStateIgnorSyncSetting>({
    listMapping: [],
    id: undefined,
    isOpenModal: false,
    rule: CourierIgnoreRule.COURIER,
    yourCourier: '',
    errText: {
      yourCourier: '',
      paypalCourier: '',
    },
    listCourier: [],
  });
  const clearStateModal = () => {
    setState({
      ...state,
      id: undefined,
      isOpenModal: false,
      rule: CourierIgnoreRule.COURIER,
      yourCourier: '',
      errText: {
        yourCourier: '',
        paypalCourier: '',
      },
    });
  };
  useEffect(() => {
    setState({
      ...state,
      listMapping: fetchListCourierIgnore.data?.listCourier || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListCourierIgnore]);

  const handleAddMappingMessage = () => {
    if (dataSettings && fetchListCourierIgnore.data) {
      if (dataSettings.plan === Plan.FREE) {
        return 'This function is only available when using Basic plan or higher. ';
      }
      if (dataSettings.plan === Plan.BASIC && fetchListCourierIgnore.data.listCourier.length > 1) {
        return 'Basic plan can only add up to 2 rules. If you want to add more rules.';
      }
      if (dataSettings.plan === Plan.PRO && fetchListCourierIgnore.data.listCourier.length > 9) {
        return 'Pro plan can only add up to 10 rules.  If you want to add more rules.';
      }
    }
  };

  const addMapping = () => {
    if (fetchListPayPalCourier.data) {
      setState({ ...state, isOpenModal: true, listCourier: fetchListPayPalCourier.data.listCourier });
    }
  };

  const editMapping = (id: number) => {
    const courierSelected = state.listMapping.find((item) => item.id === id);
    if (courierSelected) {
      setState({
        ...state,
        isOpenModal: true,
        yourCourier: courierSelected.courier,
        rule: courierSelected.type,
        id,
      });
    }
  };

  const deleteMapping = (id: number) => () => {
    removeCourierIgnore(id).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
        setIsOpenDeleteAccountModal({ status: false, id: -1 });
      }
    });
  };

  const handleCourier = useCallback(
    (type: 'yourCourier' | 'paypalCourier') => (value: string) => {
      const errText = {
        ...state.errText,
        yourCourier: type === 'yourCourier' ? '' : state.errText.yourCourier,
      };
      const resultOptions = fetchListPayPalCourier.data?.listCourier?.filter((option) =>
        option.label.toUpperCase().includes(value.toUpperCase()),
      );
      setState({
        ...state,
        [type]: value,
        errText,
        listCourier: resultOptions,
      });
    },
    [fetchListPayPalCourier.data?.listCourier, state],
  );

  const updateSelection = useCallback(
    (type: 'yourCourier' | 'paypalCourier') => (selected: string[]) => {
      setState({
        ...state,
        [type]: selected[0],
      });
    },
    [state],
  );
  const yourCourierTextField = (
    <Autocomplete.TextField
      onChange={handleCourier('yourCourier')}
      value={state.yourCourier}
      label="Courier name"
      error={state.errText.yourCourier}
      placeholder={optionsRule.find((option) => option.value === state.rule)?.helpText}
      autoComplete="off"
      maxLength={255}
    />
  );

  const checkCondition = useMemo(() => {
    return (
      dataSettings?.plan === Plan.FREE ||
      (dataSettings?.plan === Plan.BASIC && fetchListCourierIgnore.data && fetchListCourierIgnore.data.listCourier.length > 1) ||
      (dataSettings?.plan === Plan.PRO && fetchListCourierIgnore.data && fetchListCourierIgnore.data.listCourier.length > 9)
    );
  }, [dataSettings?.plan, fetchListCourierIgnore.data]);

  return (
    <IgnoreSyncStyled>
      <CustomCard
        title="Sync ignore rules"
        actions={
          state.listMapping.length > 0
            ? {
                content: 'Add ignore rules',
                action: addMapping,
                buttonProps: {
                  variant: 'primary',
                  disabled: checkCondition || scope.isViewOnly,
                },
              }
            : undefined
        }
      >
        {checkCondition ? (
          <AlertInline
            content={handleAddMappingMessage() || ''}
            link="Upgrade higher plan"
            navigate={() => {
              navigate(PATH.ACCOUNT, {
                state: {
                  prePathName: 'mappingCourier',
                },
              });
            }}
          />
        ) : null}
        <div>
          <div className={`${checkCondition ? 'w-100 mt-16' : 'w-100'} `}>
            {state.listMapping.length > 0 ? (
              <InlineGrid columns={3} gap={'200'}>
                <BoldText>Type</BoldText>
                <BoldText>Value</BoldText>
              </InlineGrid>
            ) : null}
          </div>
          {fetchListCourierIgnore.isLoading || isLoading ? (
            <div className="mt-16">
              <SkeletonBodyText />
            </div>
          ) : (
            <div className="courier-mapping-content">
              {state.listMapping.length > 0 ? (
                state.listMapping.map((item, index) => {
                  return (
                    <InlineGrid columns={3} key={index} gap={'200'}>
                      <RegularText as="p" variant="bodySm">
                        {optionsRule.find((option) => option.value === item.type)?.label}
                      </RegularText>
                      <Text as="p" variant="bodySm" breakWord truncate={item.courier.length > 100}>
                        {item.courier}
                      </Text>
                      <div className="btn-group">
                        <Tooltip content="Edit">
                          <CustomButton
                            onClick={() => editMapping(item.id)}
                            icon={() => {
                              return <Icon source={EditMinor} />;
                            }}
                          />
                        </Tooltip>

                        <div className="ml-8">
                          <Tooltip content="Delete">
                            <CustomButton
                              tone="critical"
                              onClick={() => setIsOpenDeleteAccountModal({ status: true, id: item.id })}
                              icon={() => {
                                return <Icon source={DeleteMinor} />;
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </InlineGrid>
                  );
                })
              ) : (
                <EmptyState
                  heading="Need to skip syncing specific tracking information?"
                  action={{ content: 'Add ignore rules', onAction: addMapping, disabled: checkCondition || scope.isViewOnly }}
                  secondaryAction={{
                    content: 'Learn more',
                    external: true,
                    url: 'https://docs.synctrack.io/feature-guides/how-to-add-an-ignore-sync-rule',
                  }}
                  image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                  <RegularText>
                    Synctrack will automatically skip syncing the tracking information that matches the rules you have set up for
                    future orders.
                  </RegularText>
                </EmptyState>
              )}
            </div>
          )}
        </div>
      </CustomCard>
      <Modal
        open={state.isOpenModal}
        onClose={clearStateModal}
        title={`${state.id ? 'Edit ' : 'Add'} sync ignore rule`}
        primaryAction={{
          content: 'Save',
          onAction: () => {
            saveCourierIgnore({
              id: state.id,
              courier: state.yourCourier,
              type: state.rule,
            }).then((res) => {
              const condition = checkShowErrorInline(res);
              if (condition.status) {
                setState({
                  ...state,
                  errText: {
                    ...state.errText,
                    yourCourier: condition.msg,
                  },
                });
              } else {
                dispatch(slice.commonSettingSlice.actions.handleToast(handleToastMutation(res)));
                clearStateModal();
              }
            });
          },
          loading: saveCourierIgnoreStatus.isLoading,
          disabled: !state.yourCourier,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: clearStateModal,
          },
        ]}
      >
        <Modal.Section>
          <div>
            <Select
              label="Ignore rule type"
              options={optionsRule}
              onChange={(value: CourierIgnoreRule) =>
                setState({
                  ...state,
                  rule: value,
                  errText: {
                    paypalCourier: '',
                    yourCourier: '',
                  },
                })
              }
              value={state.rule}
            />
          </div>

          <div className="mt-8">
            {state.rule === CourierIgnoreRule.COURIER ? (
              <Autocomplete
                options={state.listCourier || []}
                selected={[state.yourCourier]}
                onSelect={updateSelection('yourCourier')}
                textField={yourCourierTextField}
              />
            ) : (
              <TextField
                label={optionsRule.find((option) => option.value === state.rule)?.label}
                value={state.yourCourier}
                onChange={(value) => {
                  setState({ ...state, yourCourier: value });
                }}
                placeholder={`Example : ${optionsRule.find((option) => option.value === state.rule)?.helpText}`}
                error={state.errText.yourCourier}
                autoComplete="off"
                maxLength={255}
              />
            )}
          </div>
        </Modal.Section>
      </Modal>
      <Modal
        open={isOpenDeleteAccountModal.status}
        title={'Are you sure?'}
        onClose={() => {
          setIsOpenDeleteAccountModal({ id: -1, status: false });
        }}
        sectioned
        footer={
          <div className="modal-footer">
            <Button
              onClick={() => {
                setIsOpenDeleteAccountModal({ id: -1, status: false });
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                onClick={deleteMapping(isOpenDeleteAccountModal.id)}
                variant="primary"
                tone="critical"
                loading={removeCourierIgnoreStatus.isLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>You can add this setting again when you need it again</RegularText>
      </Modal>
    </IgnoreSyncStyled>
  );
};

export default SyncFilter;
